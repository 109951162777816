import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import dataHandeler from '../../../services/server';

export default function Package({
	packageInfo, orderRate, setOrderRate, orderRateList, pointExtDeduction
}) {

    const { t } = useTranslation();
    const [selectedOrderRate, setSelectedOrderRate] = useState(orderRate);
    const [loading, setLoading] = useState(false);
    
    const totalPO = (amount) => {
    	let total = amount * selectedOrderRate;
    	return total.toFixed(2);
    }
    
    const extraPO = (amount) => {
    	let extra = totalPO(amount) - amount;
    	return extra.toFixed(2);
    }
    
    const onOrderRateChanged = (e) => {
    	setSelectedOrderRate(e.target.value);
    }
    
    const saveOrderRate = () => {
    	setLoading(true);
    	
    	let data = {};
    	data.orderRate = selectedOrderRate;
    	data.gameCode = packageInfo.gameCode;
    	
    	dataHandeler.updateOrderRate(data).then((result) => {
    		if (result.data.status === 0) setOrderRate(selectedOrderRate);
    		else toast.error(result.data.message);
    		setLoading(false);
    		
    	}).catch((error) => {
            //console.log(error);
    		toast.error(error);
    		setLoading(false);
        });
    }
    
	return (
        <div className="mobileContent my-2 withoutKeyboard">
	        <div className="col-12">
				<div className="card mb-3">
					<div className="card-body shadow">
						<div className="row">
							<div className="col-md-6 mt-3">
								<div className="form-group" style={{marginBottom:'0', paddingBottom:'0'}}>
									<label htmlFor="lookingfor" className="floating-label">Select Bet</label>
									<div>
										<select className="form-control" value={selectedOrderRate} onChange={(e) => onOrderRateChanged(e)} disabled={pointExtDeduction > 1}>
											{orderRateList.map((item, key) => (
												<option value={item.value} key={key}>{item.description}</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="col-md-6 mx-auto mt-3">
								<div className="form-group text-align-end mb-0 pb-0">
									<a className="btn btn-primary w-100 px-4" onClick={() => saveOrderRate()}>
										{ loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.save')}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card mb-3">
					<div className="card-body shadow">
						<h4 className="fw-bold">4D</h4>
						<hr />
						<div className="col-12 px-2">
							<table className="table custom_table text-center whitespace w-100">
								<thead>
									<tr>
										<th className="text-end" />
										<th className="text-end">BIG</th>
										<th className="text-end">SMALL</th>
										<th className="text-end">A</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1ST</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4b1}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4b1)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4b1)}</b>
											</small>
										</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4s1}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4s1)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4s1)}</b>
											</small>
										</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4a1}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4a1)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4a1)}</b>
											</small>
										</td>
									</tr>
									<tr>
										<td>2ND</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4b2}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4b2)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4b2)}</b>
											</small>
										</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4s2}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4s2)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4s2)}</b>
											</small>
										</td>
										<td className="text-end" />
									</tr>
									<tr>
										<td>3RD</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4b3}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4b3)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4b3)}</b>
											</small>
										</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4s3}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4s3)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4s3)}</b>
											</small>
										</td>
										<td className="text-end" />
									</tr>
									<tr>
										<td>SPECIAL</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4bs}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4bs)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4bs)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>CONSOL</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po4bc}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po4bc)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po4bc)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="card mb-3">
					<div className="card-body shadow">
						<h4 className="fw-bold">3D</h4>
						<hr />
						<div className="col-12 px-2">
							<table className="table custom_table text-center whitespace w-100">
								<tbody>
									<tr>
										<td>A</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po3a1}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po3a1)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po3a1)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>ABC</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po3c1}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po3c1)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po3c1)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="card mb-3">
					<div className="card-body shadow">
						<h4 className="fw-bold">5D</h4>
						<hr />
						<div className="col-12 px-2">
							<table className="table custom_table text-center whitespace w-100">
								<tbody>
									<tr>
										<td>1ST</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po5a1}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po5a1)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po5a1)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>2ND</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po5a2}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po5a2)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po5a2)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>3RD</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po5a3}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po5a3)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po5a3)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>4TH</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po5a4}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po5a4)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po5a4)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>5TH</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po5a5}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po5a5)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po5a5)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>6TH</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po5a6}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po5a6)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po5a6)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="card mb-3">
					<div className="card-body shadow">
						<h4 className="fw-bold">6D</h4>
						<hr />
						<div className="col-12 px-2">
							<table className="table custom_table text-center whitespace w-100">
								<tbody>
									<tr>
										<td>1ST</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po6a1}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po6a1)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po6a1)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>2ND</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po6a2}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po6a2)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po6a2)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>3RD</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po6a3}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po6a3)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po6a3)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>4TH</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po6a4}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po6a4)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po6a4)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
									<tr>
										<td>5TH</td>
										<td className="text-end">
											<small>
												<span className="text-muted">{packageInfo.po6a5}</span><br />+
												<span className="text-danger">{extraPO(packageInfo.po6a5)}</span><br />
												{/*<sup className="text-success">RM</sup>*/}
												<b className="text-success fw-bold">{totalPO(packageInfo.po6a5)}</b>
											</small>
										</td>
										<td className="text-end" />
										<td className="text-end" />
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import dataHandeler from '../../services/server';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

export function EditDownline(props) {
	let { id } = useParams();
    const { t } = useTranslation();
	const [downlineDetails, setDownlineDetails] = useState(props.location.downlineDetails);
	const [commRate, setCommRate] = useState(props.location.downlineDetails.ownComm);
	const [bonusRate, setBonusRate] = useState(props.location.downlineDetails.ownBonus);
	const [packageList, setPackageList] = useState([]);
	
	useEffect(() => {
        fetch();
    }, []);
	
	useEffect(() => {
        let availCheckboxes = document.getElementsByName('availCheckbox');
        let defaultCheckboxes = document.getElementsByName('defaultCheckbox');
        
        for (let i = 0; i < availCheckboxes.length; i++) {
        	if (packageList[i].availChecked) availCheckboxes[i].checked = true;
        	if (packageList[i].defaultChecked) defaultCheckboxes[i].checked = true;
        }
		
    }, [packageList]);
	
	const fetch = () => {
		dataHandeler.getMemberDownlinePackages(id).then(result => {
        	if (result.data.status === 0) {
        		setPackageList(result.data.packageList);
        	}
        }).catch(error => console.log('error came after it ', error));
	}
	
	const handleChangeCommRate = value => {
        setCommRate(value);
    }
    
    const handleChangeBonusRate = value => {
        setBonusRate(value);
    }
    
    const handleCheckAll = (e) => {
    	let availCheckboxes = document.getElementsByName('availCheckbox');
    	let defaultCheckboxes = document.getElementsByName('defaultCheckbox');
    	
    	for (let i = 0; i < availCheckboxes.length; i++) {
    		availCheckboxes[i].checked = e.target.checked;
    		packageList[i].availChecked = e.target.checked;
    		
    		if (defaultCheckboxes[i].checked) {
    			defaultCheckboxes[i].checked = e.target.checked;
    			packageList[i].defaultChecked = e.target.checked;
    		}
    	}
    }
    
    const handleCheck = (e, key, type) => {
    	if (type === 'A') {
    		packageList[key].availChecked = e.target.checked;
    		
    		if (!e.target.checked && packageList[key].defaultChecked) {
    			let defaultCheckboxes = document.getElementsByName('defaultCheckbox');
    			
    			packageList[key].defaultChecked = e.target.checked;
    			defaultCheckboxes[key].checked = e.target.checked;
    		}
    	} else {
    		let availCheckboxes = document.getElementsByName('availCheckbox');
    		availCheckboxes[key].checked = e.target.checked;
    		
    		packageList[key].availChecked = e.target.checked;
    		packageList[key].defaultChecked = e.target.checked;
    	}
    }
    
    const editDownlineDetailsEvent = () => {
    	let availCount = 0;
		let defaultCount = 0;
		let duplicatedGame = 0;
		let prevGame = '';
		
    	for (let i = 0; i < packageList.length; i++) {
    		if (packageList[i].availChecked) availCount++;
    		if (packageList[i].defaultChecked) defaultCount++;
    		
    		if (i === 0) {
    			prevGame = packageList[i].gameCode;
    			if (packageList[i].defaultChecked) duplicatedGame++;
    		} else {
    			if (prevGame === packageList[i].gameCode) {
    				if (packageList[i].defaultChecked) duplicatedGame++;
    				if (duplicatedGame > 1) {
	    				toast.error('Cannot select duplicated default games!');
	    				return;
	    			}
    			} else {
    				duplicatedGame = 0;
    				if (packageList[i].defaultChecked) duplicatedGame++;
    			}
				prevGame = packageList[i].gameCode;
    		}
    	}
    	
    	var userData = {ownCommission:commRate, ownBonus:bonusRate, uuid:downlineDetails.uuid, memberGameList: packageList}
    	
    	dataHandeler.updateMemberDownlineRate(userData).then(result => {
    		if (result.data.status === 0) {
				props.history.push('/view-downline/' + downlineDetails.uuid)
				toast.success('Downline ' + downlineDetails.name + ' update succesfully')
			} else {
				toast.error('Downline update failed: ' + result.data.message)
			}
        }).catch(error => console.log('error came after it ', error))
    }
	
	return (
		<div className="container">
			<div className="row">
				<div className="col-12 web-nav headingSection d-flex align-items-center">
					<h1 className="flex-grow-1">
						<span className="pageheading">Edit Downline Details</span>
					</h1>
					<Link className="btn btn-danger respBtn" to={{pathname:"/view-downline/" + downlineDetails.uuid}}>Cancel</Link>
				</div>
				<div className="col-12 mobileContent mx-auto my-2">
	                <div className="card mb-3">
	                    <div className="card-body shadow">
	                        <div className="col-12 px-2">
	                            <div className="table-responsive">
	                                <table className="table custom_table text-center whitespace w-100">
	                                    <tbody>
	                                        <tr>
	                                            <td width={150} className="text-start fw-bold">Name</td>
	                                            <td width={40}>-</td>
	                                            <td className="text-start">{downlineDetails.name}</td>
	                                        </tr>
	                                        <tr><td></td></tr>
	                                    </tbody>
	                                </table>
	                            </div>
	                            {/*<div className="row mt-3">
	                            	<div className="col-md-3 fw-bold">Commission Rate (%)</div>
	                                <div className="col-md-7">
		                                <Slider min={0} max={downlineDetails.upComm}
		                                    value={commRate}
		                                    onChange={(e) => handleChangeCommRate(e)} />
	                                </div>
	                                <div className="col-md-2">
	                                	<input type="text" placeholder="Commission Rate" style={{ textAlign: 'center' }} className="form-control" value={commRate} readOnlye="true" />
	                                </div>
	                             </div>*/}
	                             <div className="row mt-3">
                                	<div className="col-md-3 fw-bold">Bonus Rate (%)</div>
	                                <div className="col-md-7">
		                                <Slider min={0} max={downlineDetails.upBonus}
		                                    value={bonusRate}
		                                    onChange={(e) => handleChangeBonusRate(e)} />
	                                </div>
	                                <div className="col-md-2">
	                                	<input type="text" placeholder="Bonus Rate" style={{ textAlign: 'center' }} className="form-control" value={bonusRate} readOnlye="true" />
	                                </div>
	                             </div>
	                             <div className="row mt-3">
		                             <div className="table-responsive mb-2">
		                            	{packageList ?
		                            	<table className="table custom_table">
			                            	<thead>
				                                <tr>
				                                	<th><input type="checkbox" onChange={(e) => handleCheckAll(e)}/></th>
				                                    <th>Default</th>
				                                    <th>Game</th>
				                                    <th>Package</th>
				                                </tr>
				                            </thead>
				                            <tbody>
		                            		{packageList.length > 0 ? packageList.map((element, key) =>(
			                        			<tr className="fw-bold" key={key}>
			                        				<td><input type="checkbox" name="availCheckbox" onChange={(e) => handleCheck(e, key, 'A')}/></td>
			                        				<td style={{textAlign:'center'}}><input type="checkbox" name="defaultCheckbox" onChange={(e) => handleCheck(e, key, 'D')}/></td>
			                                        <td>
			                                            <p className="mb-0 text-muted small">{element.name}</p>
			                                        </td>
			                                        <td>
			                                            <p className="mb-0 text-danger small">{element.packageDesc}</p>
			                                        </td>
			                                    </tr>
		                                    )) : <tr className="fw-bold"><td>{t('msg.no.record.found.on.date')}</td></tr> }
		                            		</tbody>
		                            	</table>
		                            	: 'No game found!'}
		                            </div>
	                             </div>
	                             <div className="row mt-3">
	                            	<div className="col-md-12">
	                            		<a className="btn btn-primary w-100" onClick={() => { editDownlineDetailsEvent() }}>Submit</a>
	                            	</div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
			</div>
		</div>
	)
}
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import  dataHandeler from '../../services/server'
import {Storage} from '../../Storage/Storage'
import { useTranslation } from 'react-i18next';
const { REACT_APP_NAME } = process.env

export function Header(props) {

    const { t } = useTranslation();
    const [data, setData] = useState([]);

    useEffect(() => {
     let userData = Storage.get('user')
     setData(userData)
    }, []);

    const handelLogout =()=>{
        localStorage.clear()
        dataHandeler.postUserLogout().then(result=>{
          //  props.history.push('/login')         
        }).catch(error=>console.log(error))
    }
    
    return (
        <header>
            <nav className="navbar fixed-top navbar-expand-lg web-nav">
                <div className="container-fluid">
                    <Link className="navbar-brand fw-bold text-white" to="/home">{REACT_APP_NAME}</Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                            <li className="nav-item">
                                <Link className="nav-link active text-white p-3" aria-current="page" to="/home">{t('menu.home')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/point-statements">{t('menu.points')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/cash-statements">{t('menu.cash')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/point-transfer">{t('menu.transfer.points')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/cash-transfer">{t('menu.cash.withdraw')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/game-list">{t('menu.games')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/sales-order">{t('menu.ticket')}</Link>
                            </li>
                            {/*<li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/package">Package</Link>
                            </li>*/}
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/result">{t('menu.result')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/downline">{t('menu.downline')}</Link>
                            </li>
                            {/*<li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/sales">{t('menu.sales')}</Link>
                            </li>*/}
                            <li className="nav-item">
                                <Link className="nav-link text-white p-3" to="/settings">{t('menu.settings')}</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link text-white fw-bold dropdown-toggle img-rounded" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="https://www.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg" />
                                    <span className="ps-2 d-inline-block">{data.name}</span>
                                </Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <Link className="dropdown-item" onClick={()=>handelLogout()} to="/login">Action</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="/">Another action</Link>
                                    </li>
                                    <li>
                                    <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" onClick={()=>handelLogout()} to="#">Log Out</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <nav className="navbar fixed-top navbar-expand-lg mobile-nav">
            	{ (window.location.pathname === '/' || window.location.pathname === '/home') &&
            	<div className="d-flex align-items-center w-100">
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Home</span>
			        </h1>
		            <Link onClick={()=>handelLogout()} to="#" className="d-inline-block righticon">
		            	<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
				        	<path d="M7.5 1v7h1V1h-1z"/>
				            <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
				        </svg>
		            </Link>&nbsp;&nbsp;
			    </div>
            	}
            	{ window.location.pathname.indexOf('/point-statements') !== -1 &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/" className="d-inline-block leftIcon text-white">
		                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Point Statements</span>
			        </h1>
			        <Link to="/point-transfer" className="d-inline-block righticon">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
							<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
							<path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
						</svg>
		            </Link>&nbsp;&nbsp;
		        </div>
            	}
            	{ window.location.pathname.indexOf('/point-transfer') !== -1 &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/point-statements" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
		                &nbsp;&nbsp;Cancel
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Point Transfer</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/qr-code') !== -1 &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/" className="d-inline-block leftIcon text-white">
		                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">QR Code</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/edit-qr-code') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/qr-code" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
		                &nbsp;&nbsp;Cancel
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Edit QR Code</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/downline') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Downline</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/view-downline') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/downline" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Downline Details</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/edit-downline') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to={{pathname:"/view-downline" + window.location.pathname.substring(window.location.pathname.lastIndexOf('/'))}} className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
		            	&nbsp;&nbsp;Cancel
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Edit Downline</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/result') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Result</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/sales-order') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Ticket</span>
			        </h1>
			        <Link to="/game-list" className="d-inline-block righticon text-white" style={{position: 'absolute', right:'10px'}}>
			        	<img src="assets/images/enter-games.svg" height={25} />
			        </Link>
		        </div>
            	}
		        { window.location.pathname.indexOf('/game-list') !== -1 ?
            	<div className="d-flex align-items-center w-100">
		            <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Games</span>
			        </h1>
			        <div></div>
		        </div> : window.location.pathname.indexOf('/game') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/game-list" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Bet</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/cash-reload') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/point-statements" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Cash Reload</span>
			        </h1>
			        <div></div>
		        </div>
            	}
            	{ window.location.pathname.indexOf('/cash-statements') !== -1 &&
            	<div className="d-flex align-items-center w-100">
		            <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
    		            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
		                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
		                </svg>
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Cash Statements</span>
			        </h1>
			        <Link to="/cash-transfer" className="d-inline-block righticon">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
							<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
							<path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
						</svg>
		            </Link>&nbsp;&nbsp;
		        </div>
            	}
            	{ window.location.pathname.indexOf('/cash-transfer') !== -1 &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/cash-statements" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
		                &nbsp;&nbsp;Cancel
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Cash Transfer</span>
			        </h1>
			        <div></div>
		        </div>
            	}
                {
                window.location.pathname === '/report' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Report</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname === '/report-sales' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/report" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Sales Report</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname === '/report-bonus' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/report" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Bonus Report</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname === '/report-commission' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/report" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Commission Report</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname === '/settings' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Settings</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname === '/languages' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/settings" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Languages</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname === '/create-downline' &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
		                &nbsp;&nbsp;Cancel
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Create Downline</span>
			        </h1>
			        <div></div>
		        </div>
            	}
                { window.location.pathname === '/merchant-statements' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/settings" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Merchant Statements</span>
                    </h1>
                    <Link to="/merchant-transfer" className="d-inline-block righticon">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
							<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
							<path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
						</svg>
		            </Link>&nbsp;&nbsp;
                </div>
            	}
                { window.location.pathname === '/merchant-transfer' &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/merchant-statements" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
		                &nbsp;&nbsp;Cancel
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Merchant Transfer</span>
			        </h1>
			        <div></div>
		        </div>
            	}
                { window.location.pathname.indexOf('/merchant-statement-details') !== -1 &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/merchant-statements" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">M Statement Details</span>
			        </h1>
                    <div></div>
		        </div>
            	}
                { window.location.pathname === '/change-password' &&
            	<div className="d-flex align-items-center w-100">
	            	<Link to="/settings" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
		                &nbsp;&nbsp;Cancel
		            </Link>
	            	<h1 className="flex-grow-1">
			            <span className="pageheading">Change Password</span>
			        </h1>
			        <div></div>
		        </div>
            	}
                { window.location.pathname === '/scan-merchant' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Cash Out</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname === '/debtor-statements' &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/report" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">{t('debtor.statement.title')}</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname.indexOf('/message-box') != -1 &&
            	<div className="d-flex align-items-center w-100">
                    <Link to="/game-list" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </Link>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">{t('msgbox.title')}</span>
                    </h1>
                    <div></div>
                </div>
            	}
                { window.location.pathname.indexOf('/cash-payout-details') != -1 &&
                	<div className="d-flex align-items-center w-100">
                        <Link to="/cash-statements" className="d-inline-block leftIcon text-white" style={{textDecoration:'none', position: 'absolute', fontSize:15}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </Link>
                        <h1 className="flex-grow-1">
                            <span className="pageheading">{t('cash.payout.title')}</span>
                        </h1>
                        <div></div>
                    </div>
                }
            </nav>
      </header>
    )
}

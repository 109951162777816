import React, { useState, useEffect } from 'react'
import 'react-rangeslider/lib/index.css'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import dataHandeler from '../../services/server'
import { useTranslation } from 'react-i18next'

export function ChangePassword() {
    const history = useHistory()
    const [user, setUser] = useState({currentPassword:'', newPassword:'', confirmPassword:''})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
    }

    const handelChange = (e) => {
    	setUser({ ...user, [e.target.name]: e.target.value })
    }

    const changePasswordEvent = () => {
        if (!user.currentPassword || !user.newPassword || !user.confirmPassword) {
			toast.error(t('msg.fill.all.fields'))
            return
		} else {
			if (user.newPassword !== user.confirmPassword) {
				toast.error(t('msg.pin.confirm.not.match'))
                return
			} else {
				var userData = {
                    currentPassword: user.currentPassword,
                    newPassword: user.newPassword,
                    confirmPassword: user.confirmPassword
				}
				setLoading(true)
				dataHandeler.changePassword(userData).then(result => {
                    // console.log('###############result: ' + JSON.stringify(result))
			        if (result.data.status === 0) {
                        toast.success(t('msg.password.change.success'))
                        setUser({currentPassword:'', newPassword:'', confirmPassword:''})
			        } else {
			        	toast.error(result.data.message)
			        }
                    setLoading(false)
			    }).catch(error => {
			    	toast.error(t('msg.password.change.failed') + ': ' + error)
                    setLoading(false)
			    })
			}
		}
	}

    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('change.password.title')}</span>
		            </h1>
		            <Link className="btn btn-danger respBtn" to="/settings">{t('button.cancel')}</Link>
	            </div>
            </div>
            <div className="row">
                <div className="col-md-12 mx-auto mt-3">
                    <div className="card shadow mb-4">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="col-ms-8 col-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder={t('change.password.current.pwd')} className="form-control" name='currentPassword'
                                                value={user.currentPassword} onChange={(e) => handelChange(e)} />
                                            <label className="form-label">{t('change.password.current.pwd')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder={t('change.password.new.pwd')} className="form-control" name='newPassword'
                                                value={user.newPassword} onChange={(e) => handelChange(e)} />
                                            <label className="form-label">{t('change.password.new.pwd')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder={t('change.password.confirm.pwd')} className="form-control" name='confirmPassword'
                                                value={user.confirmPassword} onChange={(e) => handelChange(e)} />
                                            <label className="form-label">{t('change.password.confirm.pwd')}</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <button type="button" className="btn btn-primary w-100 fw-bold py-3" onClick={() => { changePasswordEvent() }} disabled={loading}>
                                                {loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.save') }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

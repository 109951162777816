import React, { useState, useEffect } from 'react'
import dataHandeler from '../services/server'



export function Sales(props) {

    const [data, setData] = useState([])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        dataHandeler.fetchSales().then(result => {
            setData(result)
        }).catch(error => console.log(error))
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 headingSection mobileNav d-flex align-items-center">
                    <a onClick={() => props.history.goBack()} className="d-inline-block leftIcon text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                    </a>
                    <h1 className="flex-grow-1">
                        <span className="pageheading">KPI</span>
                    </h1>
                </div>
                {data.data ?
                    <div className="col-12 mobileContent mb-3">
                        <div className="card mb-3">
                            <div className="card-body shadow">
                                <div className="col-12 px-2">
                                    <div className="table-responsive">
                                        <table className="table custom_table text-center whitespace w-100">
                                            <tbody className="fw-bold">
                                                <tr>
                                                    <td width={200} className="text-start">Name</td>
                                                    <td width={40}>-</td>
                                                    <td className="text-start">{data.data.name}</td>
                                                </tr>
                                                <tr>
                                                    <td width={200} className="text-start">Phone</td>
                                                    <td width={40}>-</td>
                                                    <td className="text-start">{data.data.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td width={200} className="text-start">Email</td>
                                                    <td width={40}>-</td>
                                                    <td className="text-start" >{data.data.email} </td>
                                                </tr>
                                                <tr>
                                                    <td width={200} className="text-start">Comm. Rate</td>
                                                    <td width={40}>-</td>
                                                    <td className="text-danger text-start">{data.data.commRate}%</td>
                                                </tr>
                                                <tr>
                                                    <td width={200} className="text-start">Points</td>
                                                    <td width={40}>-</td>
                                                    <td className="text-danger fw-bold text-start">{data.data.point}</td>
                                                </tr>
                                                <tr>
                                                    <td width={200} className="text-start">Downlines</td>
                                                    <td width={40}>-</td>
                                                    <td className="text-danger fw-bold text-start">{data.data.downlines}</td>
                                                </tr>
                                                <tr>
                                                    <td width={200} className="text-start">Level</td>
                                                    <td width={40}>-</td>
                                                    <td className="text-danger text-start"><span className="badge bg-primary">{data.data.level}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row px-2">
                            <div className="col-md-6 px-2">
                                <div className="h5 fw-bold mt-4">Total Monthly Points</div>
                                <div className="points_section text-center">
                                    <h5 className="fw-bold mb-2">{data.data.month}</h5>
                                    <p className="mb-0">{data.data.totalPoint}</p>
                                </div>
                            </div>
                            <div className="col-md-6 px-2">
                                <div className="h5 fw-bold mt-4">Total Monthly Bonus</div>
                                <div className="points_section text-center">
                                    <h5 className="fw-bold mb-2">{data.data.month}</h5>
                                    <p className="mb-0">{data.data.totalBonus} </p>
                                </div>
                            </div>
                        </div>
                        <h5 className="text-center text-danger my-4 fw-bold">Estimated next month Level: 10%</h5>
                        <div className="row px-2">
                            <div className="col-md-6 px-2">
                                <div className="d-flex points_section align-items-center justify-content-center text-center">
                                    <div className="px-2 px-md-3">
                                        <h6 className="text-white mb-2">Own Sales</h6>
                                        <div className="h5 fw-bold mb-0">{data.data.sales}</div>
                                    </div>
                                    <div className="px-2 px-md-3 h4">+</div>
                                    <div className="px-2 px-md-3">
                                        <h6 className="text-white h6 mb-2">Downline</h6>
                                        <div className="h5 fw-bold mb-0">{data.data.downlines}</div>
                                    </div>
                                    <div className="px-2 px-md-3 h4">=</div>
                                    <div className="px-2 px-md-3">
                                        <h6 className="text-white h6 mb-2">Total sales</h6>
                                        <div className="h5 fw-bold mb-0">{data.data.sales + data.data.downlines}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 px-2">
                                <div className="d-flex points_section align-items-center justify-content-center text-center">
                                    <div className="px-2 px-md-3">
                                        <h6 className="text-white mb-2">Bonus</h6>
                                        <div className="h5 fw-bold mb-0">{data.data.bonus}</div>
                                    </div>
                                    <div className="px-2 px-md-3 h4">+</div>
                                    <div className="px-2 px-md-3">
                                        <h6 className="text-white h6 mb-2">Downline</h6>
                                        <div className="h5 fw-bold mb-0">{data.data.downlines}</div>
                                    </div>
                                    <div className="px-2 px-md-3 h4">=</div>
                                    <div className="px-2 px-md-3">
                                        <h6 className="text-white h6 mb-2">Own</h6>
                                        <div className="h5 fw-bold mb-0">{data.data.downlines + data.data.bonus}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card my-3">
                            <div className="card-body shadow">
                                <div className="col-12 px-2">
                                    <div className="table-responsive">
                                        <table className="table custom_table text-center whitespace w-100">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Sales</th>
                                                    <th>Bonus</th>
                                                    <th>Downline</th>
                                                    <th>Yours</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.data.salesList && data.data.salesList.map(element => (
                                                        <tr>
                                                            <td className="text-danger">{element.date}</td>
                                                            <td className="text-success">{element.sales}</td>
                                                            <td className="text-success">{element.bonus}</td>
                                                            <td className="text-primary">{element.downline}</td>
                                                            <td className="text-primary">{element.yours}</td>
                                                        </tr>
                                                    ))
                                                }

                                                {/* <tr>
                                                <td className="text-danger">13</td>
                                                <td className="text-success">1.00</td>
                                                <td className="text-success">0.00</td>
                                                <td className="text-primary">0.00</td>
                                                <td className="text-primary">0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-danger">10</td>
                                                <td className="text-success">1.00</td>
                                                <td className="text-success">0.00</td>
                                                <td className="text-primary">0.00</td>
                                                <td className="text-primary">0.00</td>
                                            </tr>
                                        */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div class="loader">
                        <div class="triple-spinner"></div>
                    </div>
                }
            </div>
        </div>
    )
}

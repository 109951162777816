import React, { useState, useEffect } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import dataHandeler from '../../services/server'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Button } from 'react-bootstrap'

export function CashPayoutDetails(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [payoutStatement, setPayoutStatement] = useState([]);

    useEffect(() => {
    	setLoading(true);
    	let query = new URLSearchParams(props.location.search);
    	dataHandeler.getCashPayoutDetails(query.get('date')).then(result => {
    		setPayoutStatement(result.data);
            setLoading(false);
        }).catch(error => console.log('error came after it ', error));
    }, []);

    return (
        <div className="container">
	        <div className="row ">
		        <div className="col-12 web-nav headingSection d-flex align-items-center">
		            <h1 className="flex-grow-1">
		                <span className="pageheading">{t('cash.payout.title')}</span>
		            </h1>
		            <Link className="btn btn-danger respBtn" to="/cash-statements">{t('button.back')}</Link>
		        </div>
		        { !loading ?
		        <div className="col-md-12 mx-auto my-2 mobileContent ">
			        <div className="card shadow">
	                	<div className="card-body">
					        <table className="table custom_table text-center whitespace w-100">
				                <thead>
				                    <tr>
				                        <th>Inv No.</th>
				                        <th>Number</th>
				                        <th>Prize</th>
				                        <th>Amount</th>
				                    </tr>
				                </thead>
				                <tbody>
			                	{ payoutStatement.poList && payoutStatement.poList.length > 0 ? payoutStatement.poList.map((element, key) => (
			                		<tr key={key}>
		                                <td>{element.invoiceNo}</td>
		                                <td> {element.number} </td>
		                                <td> {element.prize} </td>
		                                <td className="text-success" style={{textAlign:'right'}}>{element.payoutAmt}</td>
		                            </tr>
			                    )) : <tr className="fw-bold"><td>{t('msg.no.record.found.on.date')}</td></tr> }
				                </tbody>
				            </table>
			            </div>
		            </div>
		        </div>
		        : <div className="loader"><div className="triple-spinner"></div></div>
		        }
	        </div>
        </div>
    )
}

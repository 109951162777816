import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ConvertNumberToCounter } from './util/BetUtils';

const { REACT_APP_API_IMAGE_URL } = process.env;
let permArr = [], usedChars = [];

export default function OrderSimple({
	userPoint, orderPoint, setOrderPoint, pointExtDeduction, counterList, loading, counterLoading,
	activeBetCounter, setActiveBetCounter, betList, setBetList, onBetEvent, dayOptions, selectedDay, onSelectDay,
	orderRate, isTransactionClosed, orderMsg
}) {
	
	const [betRow, setBetRow] = useState(0);
	const [isOrderMsg, setIsOrderMsg] = useState(false);
	
	useEffect(() => {
		if (orderMsg && orderMsg !== '') {
			importBetList(orderMsg);
			setIsOrderMsg(true);
		} else activeBetRow(0);
	}, []);
	
	useEffect(() => {
		if (isOrderMsg) {
			activeBetRow(0);
			setIsOrderMsg(false);
		}
		calculateOrder();
    }, [betList]);
	
	const importBetList = (message) => {
		let digitPattern = /^[0-9]+$/;
		let msg_arr = message.split(',');
		let day = parseInt(msg_arr[0]);
		
		for (let i = 0; i < dayOptions.length; i++) {
			if (dayOptions[i].value === day) {
				onSelectDay(dayOptions[i]);
				break;
			}
		}
		
		let list = [];
		let obj = {};
		let type = '';
		
		for (let i = 1; i < msg_arr.length; i++) {
			if (digitPattern.test(msg_arr[i])) {
				if (type === 'number') {
					list.push(obj);
					obj = {};
				}
				obj.counter = ConvertNumberToCounter(msg_arr[i].split(''), counterList);
				type = 'counter';
			} else {
				let order_arr = [];
				
				if (msg_arr[i].startsWith('#')) { // bao head
					order_arr = msg_arr[i].substring(1).split('#');
					obj.number = '#' + order_arr[0];
					
				} else if (msg_arr[i].includes('##')) { // bao tail
					let idx = msg_arr[i].indexOf('#');
					obj.number = msg_arr[i].substring(0, idx + 1);
					
					order_arr = msg_arr[i].substring(idx + 1).split('#');
					
				} else {
					order_arr = msg_arr[i].split('#');
					obj.number = order_arr[0];
				}
				
				for (let i = 1; i < order_arr.length; i++) {
					if (i === 1 && order_arr[i] !== '0') obj.big = order_arr[i];
					else if (i === 2 && order_arr[i] !== '0') obj.small = order_arr[i];
					else if (i === 3 && order_arr[i] !== '0') obj.foura = order_arr[i];
					else if (i === 4 && order_arr[i] !== '0') obj.threea = order_arr[i];
					else if (i === 5 && order_arr[i] !== '0') obj.threeabc = order_arr[i];
				}
			}
		}
		list.push(obj); // push last item
		setBetList(list);
	}

    const permute = (input) => {
        var i, ch;
        for (i = 0; i < input.length; i++) {
	        ch = input.splice(i, 1)[0];
	        usedChars.push(ch);
	        if (input.length == 0 && !permArr.includes(usedChars.join(''))) {
                permArr.push(usedChars.join(''));
            }
	        permute(input);
	        input.splice(i, 0, ch);
	        usedChars.pop();
	    }
	    return permArr.length;
    }
    
    const calculateOrder = () => {
    	let summession = 0;
    	let multiplicative = 1;
    	let multidate = selectedDay.value;
    	
		// box 1*234 or 123*4
		let patt1 = /\d{3,3}[*]\d{1}/i;
		let patt2 = /\d{1}[*]\d{3,3}/i;
    	
    	for (let index in betList) {
    		let betObj = betList[index];
    		let counter = betObj.counter.length;
    		let number = betObj.number;
    		
			if (number.startsWith('**')) { // ibox
				number = number.substring(2);
				multiplicative = 1;
				
			} else if (number.startsWith('*')) { // box
				number = number.substring(1);
				if (number.endsWith('**')) number = number.substring(0, number.length - 2); // 4DS
				
				let myFunc = num => Number(num);
				let num = Array.from(number, myFunc);
				permArr = [];
				multiplicative = permute(num);
				
			} else if (number.startsWith('#')) { // bao head
				multiplicative = 10; 
				
			} else if (number.endsWith('#')) { // bao tail
				multiplicative = 10;
				
			} else {
				if (number.endsWith('**')) number = number.substring(0, number.length - 2); // 4DS
				
				if (patt1.test(number) || patt2.test(number)) {
					if (patt1.test(number)) number = number.substring(0, 3);
					else number = number.substring(2);
					
					let myFunc = num => Number(num);
					let num = Array.from(number, myFunc);
					permArr = [];
					multiplicative = permute(num);
					
				} else if (number.indexOf('*') === 3 || number.indexOf('*') === 4) { // rev
					multiplicative = 2;
				} else multiplicative = 1;
			}
    		
    		let totalAmt = 0;
    		if (betObj.big) totalAmt += parseFloat(betObj.big.replace('*', '.'));
    		if (betObj.small) totalAmt += parseFloat(betObj.small.replace('*', '.'));
    		if (betObj.foura) totalAmt += parseFloat(betObj.foura.replace('*', '.'));
    		if (betObj.threea) totalAmt += parseFloat(betObj.threea.replace('*', '.'));
    		if (betObj.threeabc) totalAmt += parseFloat(betObj.threeabc.replace('*', '.'));
    		
    		summession += (totalAmt * counter * multidate * multiplicative * pointExtDeduction);
    	}
    	summession = summession * orderRate;
        setOrderPoint(summession.toFixed(2));
    }
	
	const clickCounter = (code) => {
        let betArray = betList;
        let betObj = betArray[betRow];

        let counterArry = [...activeBetCounter];
        let ind = counterArry.indexOf(code);
        
        if (ind === -1) {
        	counterArry.push(code);
        } else {
        	counterArry.splice(ind, 1);
        }
        
        betObj.counter = counterArry;
        betArray.splice(betRow, 1, betObj);
        setActiveBetCounter(counterArry);
        setBetList(betArray);
        calculateOrder();
    }
	
	const clickBetType = (type) => {
		let list = [...betList];
		let betObj = list[betRow];
		let number = betObj.number;
		
		if (type === 'B') {
			if (number.startsWith('**') || number.startsWith('*')) betObj.number = number.substring(1);
			else betObj.number = '*' + number;
			
		} else if (type == 'IB') {
			if (number.startsWith('**')) betObj.number = number.substring(2);
			else if (number.startsWith('*')) betObj.number = '*' + number;
			else betObj.number = '**' + number;
			
		} else if (type === '4DS') {
			if (number.endsWith('**')) betObj.number = number.substring(0, number.length - 2);
			else betObj.number = number + '**';
		}
		
		list.splice(betRow, 1, betObj);
		setBetList(list);
	}
	
	const addBetRow = () => {
		let list = [...betList];
		
		if (list[list.length - 1].number.length === 0 || 
				(list[list.length - 1].big.length === 0 && list[list.length - 1].small.length === 0 &&
				list[list.length - 1].foura.length === 0 && list[list.length - 1].threea.length === 0 &&
				list[list.length - 1].threeabc.length === 0)) {
			toast.warning("Please enter last bet");
			return;
		}
		
		list.push({counter: betList[betList.length - 1].counter, number: '', big: '', small: '', foura: '', threea: '', threeabc: ''});
		setBetList(list);
		setBetRow(list.length - 1);
	}
	
	const removeBetRow = () => {
		let list = [...betList];
		
		if (list.length > 1) {
			list.splice(betRow, 1);
			setBetList(list);
			
			let idx = 0;
			if (betRow > 0) idx = betRow - 1;
			setActiveBetCounter(list[idx].counter);
			setBetRow(idx);
		}
	}
	
	const activeBetRow = (idx) => {
		let obj = betList[idx];
		setActiveBetCounter(obj.counter);
		setBetRow(idx);
	}
	
	const onNumberKeyIn = (type, idx, e) => {
		let list = [...betList];
		let betObj = list[idx];

		switch (type) {
			case 'number':
				if (e.target.value.length <= 8) betObj.number = e.target.value;
				break;
			case 'big':
				if (e.target.value.length <= 6) betObj.big = e.target.value;
				break;
			case 'small':
				if (e.target.value.length <= 6) betObj.small = e.target.value;
				break;
			case 'foura':
				if (e.target.value.length <= 6) betObj.foura = e.target.value;
				break;
			case 'threea':
				if (e.target.value.length <= 6) betObj.threea = e.target.value;
				break;
			case 'threeabc':
				if (e.target.value.length <= 6) betObj.threeabc = e.target.value;
				break;
		}
		list.splice(betRow, 1, betObj);
        setBetList(list);
	}
	
	return (
		<div className="mobileContent my-2 withoutKeyboard">
			<div className="col-12">
				<div className="card shadow">
		            <div className="card-body">
		            	<div className="row">
		                    <div className="col-12 col-md-12 col-lg-3 pt-1 pb-1">
		                    	<Select value={selectedDay} onChange={(e) => onSelectDay(e)} options={dayOptions} />
		                    </div>
		                    <div className="col-12 col-md-12 col-lg-9 pt-1 pb-1 mobile-text-center">
		                        <button type="button" className="btn btn-primary btn-resp" onClick={() => clickBetType('B')}>Box *0000</button>&nbsp;
		                        <button type="button" className="btn btn-primary btn-resp" onClick={() => clickBetType('IB')}>iBox **0000</button>&nbsp;
		                        <button type="button" className="btn btn-primary btn-resp" onClick={() => clickBetType('4DS')}>4DS 0000**</button>
		                    </div>
	                    </div>
	                    <div className="row pt-2" style={{fontSize:'13px'}}>
	                    	<div className="col-6">
	                        	<img src="assets/images/coin.svg" height={25} style={{marginTop:'-6px'}}/>&nbsp;<span style={{fontWeight:'bold',fontSize:'16px'}}>{userPoint}</span> pts
	                    	</div>
	                        <div className="col-6" style={{textAlign:'right', fontSize:'13px'}}>
	                        	<img src="assets/images/coin.svg" height={25} style={{marginTop:'-6px'}}/>&nbsp;Order&nbsp;
	                        		{ orderPoint > userPoint ?
	                        			<span style={{fontWeight:'bold',fontSize:'16px',color:'red'}}>{orderPoint}</span>
	                        			: <span style={{fontWeight:'bold',fontSize:'16px'}}>{orderPoint}</span>
	                        		} pts
	                        </div>
	                    </div>
		            </div>
		        </div>
		        <div className="d-flex align-items-center overflow-auto my-md-4 my-3">
	            	{ !counterLoading ?
	                    (counterList && counterList.length > 0 ? counterList.map((element, i) => (
	                        <div
	                            className={activeBetCounter.filter((item) => item === element.code).length > 0 ? 'logos active btn' : 'logos btn'}
	                            onClick={() => clickCounter(element.code)}
	                            key={i} id={'idCounter' + element.code} style={{padding:'0'}}>
	                            <img src={REACT_APP_API_IMAGE_URL + element.image} />
	                            <h4>{element.displayName}</h4>
	                        </div>
	                    )) : <div>Counter not found!</div>)
	            	: <div style={{width:'100%',height:'50px',textAlign:'center'}}><i className="spinner-border" style={{width:'30px', height:'30px'}}></i></div> }
	            </div>
	            
	            <table className="table">
	            	<thead>
		            	<tr>
				      		<th style={{width:'25%', textAlign:'center', verticalAlign:'middle', padding:'0px 0px'}}>Counter</th>
				      		<th style={{width:'25%', textAlign:'center', verticalAlign:'middle', padding:'0px 0px'}}>Num</th>
				      		<th style={{width:'10%', textAlign:'center', verticalAlign:'middle', padding:'0px 0px'}}>B</th>
				      		<th style={{width:'10%', textAlign:'center', verticalAlign:'middle', padding:'0px 0px'}}>S</th>
				      		<th style={{width:'10%', textAlign:'center', verticalAlign:'middle', padding:'0px 0px'}}>A</th>
				      		<th style={{width:'10%', textAlign:'center', verticalAlign:'middle', padding:'0px 0px'}}>ABC</th>
				      		<th style={{width:'10%', textAlign:'center', verticalAlign:'middle', padding:'0px 0px'}}>4A</th>
				        </tr>
	            	</thead>
	                <tbody>
	                    {betList.map((bet, key) => (
	                        <tr key={key} className={`fw-bold ${betRow === key ? 'activeBetRow' : ''}`} onClick={() => activeBetRow(key)}>
	                            <td height={40} style={{paddingLeft:'1px', paddingRight:0}}>
	                            	<input type="text" value={bet.counter.join('')} style={{width:'100%'}} readOnly={true}/>
	                            </td>
	                            <td style={{paddingLeft:'1px', paddingRight:0}}>
	                            	<input type="tel" value={bet.number} style={{width:'100%'}} onChange={(e) => onNumberKeyIn('number', key, e)}/>
	                            </td>
	                            <td style={{paddingLeft:'1px', paddingRight:0}}>
	                            	<input type="number" value={bet.big} style={{width:'100%'}} onChange={(e) => onNumberKeyIn('big', key, e)}/>
	                            </td>
	                            <td style={{paddingLeft:'1px', paddingRight:0}}>
	                            	<input type="number" value={bet.small} style={{width:'100%'}} onChange={(e) => onNumberKeyIn('small', key, e)}/>
	                            </td>
	                            <td style={{paddingLeft:'1px', paddingRight:0}}>
	                            	<input type="number" value={bet.threea} style={{width:'100%'}} onChange={(e) => onNumberKeyIn('threea', key, e)}/>
	                            </td>
	                            <td style={{paddingLeft:'1px', paddingRight:'1px'}}>
	                            	<input type="number" value={bet.threeabc} style={{width:'100%'}} onChange={(e) => onNumberKeyIn('threeabc', key, e)}/>
	                            </td>
	                            <td style={{paddingLeft:'1px', paddingRight:0}}>
	                            	<input type="number" value={bet.foura} style={{width:'100%'}} onChange={(e) => onNumberKeyIn('foura', key, e)}/>
	                            </td>
	                        </tr>
	                    ))}
	                    <tr className="fw-bold pointer">
	                        <td colSpan={7} className="text-muted h5" style={{textAlign:'center'}}>
	                        	<button type="button" onClick={() => addBetRow()} className='btn btn-primary' style={{width:'30%'}}>+</button>&nbsp;
	                        	<button type="button" onClick={() => removeBetRow()} className='btn btn-danger' style={{width:'30%'}}>-</button>&nbsp;
	                        	<button type="button" onClick={() => onBetEvent()} className='btn btn-primary' style={{width:'30%'}} disabled={loading || isTransactionClosed}>
	                        		{ loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : 'Bet'}
	                        	</button>
	                        </td>
	                    </tr>
	                </tbody>
	            </table>
			</div>
		</div>
	)
}

export const hashing = (str) => {
    let hashString = str.split('#');
    let numberLength = 0;
    var obj = {}
    for (let index = 0; index < hashString.length; index++) {
        if (index > 0) {
        	let amount = hashString[index].replace('*', '.');
        	
        	if (numberLength == 4) {
        		if (index == 1) obj.big = amount;
        		else if (index == 2) obj.small = amount;
        		else if (index == 3) obj.foura = amount;
        		
        	} else if (numberLength == 3) {
        		if (index == 1) obj.threea = amount;
        		else if (index == 2) obj.threeabc = amount;
        	} else obj.big = amount;
        } else {
        	numberLength = hashString[index].replaceAll('*', '').length;
        }
    }
    return obj;
}

export const getBetType = (str) => {
    if (str.startsWith('**')) return 'IB';
    else if (str.startsWith('*')) return 'B';
    else if (str.endsWith('*')) {
    	if (!str.endsWith('**')) return 'RV';
    	else return '4DS';
    } else if (str.startsWith('#')) return 'H';
    else if (str.endsWith('#')) return 'T';
    return 'S';
}

export const getPostBetStr = (postData, counterList, active, orderRate) => {
	let bets = postData.bets;
	let combineBet = []
	
	bets.map((item) => {
		let tmpBet = {};
		let tmpNum = item.number;
		let tmpAmt = '';
		
		if (tmpNum.length > 0) {
			// convert counter to digit
			let tmpCtr = Convertcounter(item.counter, counterList);
			
			if (active !== 0) {
				// set order amount
				if (item.type === 'B') {
					tmpNum = '*' + tmpNum;
				} else if (item.type === 'IB') {
					tmpNum = '**' + tmpNum;
				} else if (item.type === 'RV') {
					tmpNum += '*';
				}
			}
			
			let numberLength = tmpNum.replaceAll('*', '').length;
			
			if (numberLength === 4 || numberLength === 2) {
                if (item.big) tmpAmt += getTmpAmt(item.big, orderRate);
				else tmpAmt += '#0';
				
				if (item.small) tmpAmt += getTmpAmt(item.small, orderRate);
				else tmpAmt += '#0';
				
				if (item.threea) tmpAmt += getTmpAmt(item.threea, orderRate);
				else tmpAmt += '#0';
				
				if (item.threeabc) tmpAmt += getTmpAmt(item.threeabc, orderRate);
				else tmpAmt += '#0';
				
				if (item.foura) tmpAmt += getTmpAmt(item.foura, orderRate);
				
			} else if (numberLength === 3) {
				if (tmpNum.endsWith('**')) { // 3ds
					if (item.big) tmpAmt += getTmpAmt(item.big, orderRate);
					else tmpAmt += '#0';
					
					if (item.small) tmpAmt += getTmpAmt(item.small, orderRate);
					else tmpAmt += '#0';
					
					if (item.threea) tmpAmt += getTmpAmt(item.threea, orderRate);
					else tmpAmt += '#0';
					
					if (item.threeabc) tmpAmt += getTmpAmt(item.threeabc, orderRate);
					else tmpAmt += '#0';
					
					if (item.foura) tmpAmt += getTmpAmt(item.foura, orderRate);
					
				} else {
					if (item.threea) tmpAmt += getTmpAmt(item.threea, orderRate);
					else tmpAmt += '#0';
					
					if (item.threeabc) tmpAmt += getTmpAmt(item.threeabc, orderRate);
					else tmpAmt += '#0';
				}
			} else if (numberLength > 4) {
				if (item.big) tmpAmt += getTmpAmt(item.big, orderRate);
				else tmpAmt += '#0';
			}
			
			tmpAmt = tmpAmt.split('.').join('*');
			tmpNum += tmpAmt;
			
			// combine order string in same counter group
			let isNotSame = true;
			if (combineBet.length > 0) {
				for (let c = 0; c < combineBet.length; c++) {
					if (combineBet[c].counter === tmpCtr) {
						combineBet[c].number += ',' + tmpNum;
						isNotSame = false;
						break;
					}
				}
				if (isNotSame) {
					tmpBet.counter = tmpCtr;
	    			tmpBet.number = tmpNum;
	    			combineBet.push(tmpBet);
				}
			} else {
				tmpBet.counter = tmpCtr;
				tmpBet.number = tmpNum;
				combineBet.push(tmpBet);
			}
		}
	});
	
	let betArry = [];
	betArry.push(postData.days.value);
	
	combineBet.map((item, key) => {
    	betArry.push(item.counter);

    	let numbers = item.number.split(',')
		for (var c = 0; c < numbers.length; c++) {
			betArry.push(numbers[c]);
		}
    });

    let str = JSON.stringify(betArry);
    str = str.replace(/"/g, '');
    str = str.substring(1);
    str = str.slice(0, -1);
    return str;
}

export const getPostBetStrNormal = (postData, counterList, orderRate) => {
	let bets = postData.bets;
	let combineBet = []
	
	bets.map((item) => {
		let tmpBet = {};
		let tmpNum = item.number;
		let tmpAmt = '';
		
		if (tmpNum.length > 0) {
			// convert counter to digit
			let tmpCtr = Convertcounter(item.counter, counterList);
			
            // set order amount
            if (item.type === 'B') {
                tmpNum = '*' + tmpNum;
            } else if (item.type === 'IB') {
                tmpNum = '**' + tmpNum;
            } else if (item.type === 'RV') {
                tmpNum += '*';
            } else if (item.type === 'PH') { // Order Normal Used
                tmpNum = '#' + tmpNum.substring(1);
            } else if (item.type === 'PB') { // Order Normal Used
                tmpNum = tmpNum.substring(0, tmpNum.length - 1) + '#';
            }

            if (item.type4ds && item.type4ds === 1) {
                tmpNum += '**';
            }

            if (item.type3ds && item.type3ds === 1) {
                tmpNum += '**';
            }

            if (item.type2ds && item.type2ds === 1) {
                tmpNum += '**';
            }
			
			let numberLength = tmpNum.replaceAll('*', '').length;
			
			if (numberLength === 4) {
                if (tmpNum.endsWith('**')) { // 4ds
                    if (item.foura) tmpAmt += getTmpAmt(item.foura, orderRate);
                    else tmpAmt += '#0';

                    if (item.foursb) tmpAmt += getTmpAmt(item.foursb, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.foursc) tmpAmt += getTmpAmt(item.foursc, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.fourd) tmpAmt += getTmpAmt(item.fourd, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.foure) tmpAmt += getTmpAmt(item.foure, orderRate);
                    else tmpAmt += '#0';

                    if (item.fourc) tmpAmt += getTmpAmt(item.fourc, orderRate);
                } else {
                    if (item.big) tmpAmt += getTmpAmt(item.big, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.small) tmpAmt += getTmpAmt(item.small, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.threea) tmpAmt += getTmpAmt(item.threea, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.threeabc) tmpAmt += getTmpAmt(item.threeabc, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.foura) tmpAmt += getTmpAmt(item.foura, orderRate);
                }
				
			} else if (numberLength === 3) {
				if (tmpNum.endsWith('**')) { // 3ds
					if (item.threesa) tmpAmt += getTmpAmt(item.threesa, orderRate);
					else tmpAmt += '#0';
					
					if (item.threesb) tmpAmt += getTmpAmt(item.threesb, orderRate);
					else tmpAmt += '#0';
					
					if (item.threesc) tmpAmt += getTmpAmt(item.threesc, orderRate);
					else tmpAmt += '#0';
					
					if (item.threed) tmpAmt += getTmpAmt(item.threed, orderRate);
					else tmpAmt += '#0';
					
					if (item.threee) tmpAmt += getTmpAmt(item.threee, orderRate);
					
				} else {
					if (item.threea) tmpAmt += getTmpAmt(item.threea, orderRate);
					else tmpAmt += '#0';
					
					if (item.threeabc) tmpAmt += getTmpAmt(item.threeabc, orderRate);
					else tmpAmt += '#0';
				}
            } else if (numberLength === 2) {
                if (tmpNum.endsWith('**')) { // 4ds
                    if (item.twosa) tmpAmt += getTmpAmt(item.twosa, orderRate);
                    else tmpAmt += '#0';

                    if (item.twosb) tmpAmt += getTmpAmt(item.twosb, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.twosc) tmpAmt += getTmpAmt(item.twosc, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.twod) tmpAmt += getTmpAmt(item.twod, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.twoa) tmpAmt += getTmpAmt(item.twoa, orderRate);
                } else {
                    if (item.twoa) tmpAmt += getTmpAmt(item.twoa, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.twoabc) tmpAmt += getTmpAmt(item.twoabc, orderRate);
                    else tmpAmt += '#0';
                    
                    if (item.big) tmpAmt += getTmpAmt(item.big, orderRate);
                }
			} else if (numberLength > 4) {
				if (item.big) tmpAmt += getTmpAmt(item.big, orderRate);
				else tmpAmt += '#0';
			}
			
			tmpAmt = tmpAmt.split('.').join('*');
			tmpNum += tmpAmt;
			
			// combine order string in same counter group
			let isNotSame = true;
			if (combineBet.length > 0) {
				for (let c = 0; c < combineBet.length; c++) {
					if (combineBet[c].counter === tmpCtr) {
						combineBet[c].number += ',' + tmpNum;
						isNotSame = false;
						break;
					}
				}
				if (isNotSame) {
					tmpBet.counter = tmpCtr;
	    			tmpBet.number = tmpNum;
	    			combineBet.push(tmpBet);
				}
			} else {
				tmpBet.counter = tmpCtr;
				tmpBet.number = tmpNum;
				combineBet.push(tmpBet);
			}
		}
	});
	
	let betArry = [];
	betArry.push(postData.days.value);
	
	combineBet.map((item, key) => {
    	betArry.push(item.counter);

    	let numbers = item.number.split(',')
		for (var c = 0; c < numbers.length; c++) {
			betArry.push(numbers[c]);
		}
    });

    let str = JSON.stringify(betArry);
    str = str.replace(/"/g, '');
    str = str.substring(1);
    str = str.slice(0, -1);
    return str;
}

export const getTmpAmt = (amount, orderRate) => {
	let tmpAmt = '#' + ((parseFloat(amount.replace('*', '.')) * orderRate).toFixed(2));
	
	if (tmpAmt.endsWith('.00')) tmpAmt = tmpAmt.substring(0, tmpAmt.length - 3);
	else if (tmpAmt.endsWith('.10') || tmpAmt.endsWith('.20') || tmpAmt.endsWith('.30')
			|| tmpAmt.endsWith('.40') || tmpAmt.endsWith('.50') || tmpAmt.endsWith('.60')
			|| tmpAmt.endsWith('.70') || tmpAmt.endsWith('.80') || tmpAmt.endsWith('.90')) {
		tmpAmt = tmpAmt.substring(0, tmpAmt.length - 1);
	}
	return tmpAmt;
}

export const getPostKeyboardBetStr = (keyboardBetList, orderRate) => {
	let combineBet = [];
	
	for (let index in keyboardBetList) {
		let str = keyboardBetList[index].value.replace('D', '');
		
		if (index > 1) {
			if (!str.includes('#')) combineBet.push(str);
			else {
				let number = '';
				let amount = '';
				
				if (str.startsWith('#')) { // bao head
					number = str.substring(0, str.substring(1).indexOf('#') + 1);
					amount = str.substring(1);
					amount = amount.substring(amount.indexOf('#') + 1);
					
				} else if (str.indexOf('##') === 3 ||  str.indexOf('##') === 2) { // bao tail
					number = str.substring(0, str.indexOf('#') + 1);
					amount = str.substring(str.indexOf('##') + 2);
					
				} else { // normal, ibox, box, rev
					number = str.substring(0, str.indexOf('#'));
					amount = str.substring(str.indexOf('#') + 1);
				}
				
				let amount_arr = amount.replaceAll('*', '.').split('#');
				let amount_str = '';
				for (let i = 0; i < amount_arr.length; i++) {
					amount_str += '#' + (parseFloat(amount_arr) * orderRate).toFixed(2);
					if (amount_str.endsWith('.00')) amount_str = amount_str.substring(0, amount_str.length - 3);
					else if (amount_str.endsWith('.10') || amount_str.endsWith('.20') || amount_str.endsWith('.30')
							|| amount_str.endsWith('.40') || amount_str.endsWith('.50') || amount_str.endsWith('.60')
							|| amount_str.endsWith('.70') || amount_str.endsWith('.80') || amount_str.endsWith('.90')) {
						amount_str = amount_str.substring(0, amount_str.length - 1);
					}
				}
				combineBet.push(number + amount_str.replaceAll('.', '*'));
			}
		} else combineBet.push(str);
	}
	return combineBet;
}

export const Convertcounter = (counter, counterList) => {
    let count = [];
    
    counterList.map((ctr, key) => {
		/*if (counter.includes(ctr.code)) {
			count.push(ctr.value);
		}*/
    	if (counter.filter((item) => item === ctr.code).length > 0) {
    		count.push(ctr.value);
    	}
	});
    return count.join("");
}

export const ConvertNumberToCounter = (list, counterList) => {
    let count = [];
    for (let i = 0; i < list.length; i++) {
    	counterList.map((item) => {
    		if (list[i] == item.value) {
    			count.push(item.code);
    		}
    	});
    }
    return count;
}

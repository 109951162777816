import React, { useState, useEffect } from 'react';
import dataHandeler from '../../services/server';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Storage} from '../../Storage/Storage';
import moment from "moment";

const { REACT_APP_API_IMAGE_URL } = process.env;
export function MessageBox(props) {
	let userData = Storage.get('user');
	let { gameCode } = useParams();
	
    const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [messageStatement, setMessageStatement] = useState([]);
	const [data, setData] = useState({fromDate: moment(new Date()).format('YYYY-MM-DD'), toDate: moment(new Date()).format('YYYY-MM-DD')})
	
	useEffect(() => {
		search();
    }, []);
	
	const search = () => {
		if (!userData.msgBox) {
	    	setLoading(true);
			fetchCounters();
		} else {
	    	setLoading(true);
			fetchMessageList();
		}
	}
    	
    const fetchCounters = () => {
    	dataHandeler.getCounters(gameCode).then((result) => {
    		if (result.data.status === 0) {
    			userData.msgBox = result.data.msgBox;
    			Storage.set('user', userData);
    			fetchMessageList();
    		} else setLoading(false);
        	
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }
    
    const fetchMessageList = () => {
    	data.mobileNumber = userData.msgBox.mobileNumber;
    	data.mobileNumber = data.mobileNumber.replace('+', '');
    	
    	dataHandeler.getMessageBox(data).then((result) => {
    		if (result.data.code === 0 && result.data.result) {
    			setMessageStatement(result.data.result.messageList);
    		}
    		setLoading(false);
    		
    	}).catch((error) => {
    		console.log(error);
    		setLoading(false);
    	});
    }
    
    const onDateSelectEvent = (e) => {
    	setData({ ...data, [e.target.name]: e.target.value })
    }
    
    return (
    	<div className="container">
            <div className="row ">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('msgbox.title')}</span>
		            </h1>
                    <Link className="btn btn-danger respBtn" to="/game-list">{t('button.back')}</Link>
                </div>
            </div>
            <div className="col-12 my-2 mobileContent">
	          <div className="card shadow mb-3">
	            <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
	              <form className="mt-md-4 mt-0 col-md-8 col-12 mx-auto">
	                <div className="row px-md-5 px-2">
	                  <div className="col-md col-5 px-md-3 px-0">
	                    <div className="form-group text-align-end mb-0 form-ani">
		                    <input type="date"
		                        className="form-control text-uppercase"
		                        name="fromDate"
		                        defaultValue={moment(new Date()).format('YYYY-MM-DD')}
		                        onChange={(e) => onDateSelectEvent(e)} />
	                    </div>
	                  </div>
	                  <div className=" col-md-1 col-md col-2 text-dark text-center fw-bold px-md-3 px-0">{t('label.to')}
	                    </div>
	                  <div className="col-md col-5 px-md-3 px-0">
	                    <div className="form-group text-align-end mb-0 form-ani">
		                    <input type="date"
		                        className="form-control text-uppercase"
		                        name="toDate"
		                        defaultValue={moment(new Date()).format('YYYY-MM-DD')}
		                    	onChange={(e) => onDateSelectEvent(e)} />
	                    </div>
	                  </div>
	                  <div className="col-md-3 col-12 px-md-3 px-2">
	                    <div className="form-group text-align-end mb-0 pb-0">
	                    	<a className="btn btn-primary w-100 px-4" onClick={() => { search() }}>{t('button.search')}</a>
	                    </div>
	                  </div>
	                </div>
	              </form>
	            </div>
	          </div>
	        </div>
            <div className="col-12 my-2 mobileContent">
            	{loading ? <div className="loader"><div className="triple-spinner"></div></div>
            	: messageStatement && messageStatement.length > 0 ? messageStatement.map((element, key) => (
            		<div className="card shadow mt-3" key={key}>
            			<div className="card-body">
            				<div className="d-flex flex-wrap">
            					<div className="px-3 text-start">
	            					<div className="img-receipt">
		    		                    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="#ffffff" className="bi bi-receipt" viewBox="0 0 16 16">
		    		                      <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z">
		    		                      </path>
		    		                      <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z">
		    		                      </path>
		    		                    </svg>
		    		                </div>
            					</div>
            					<div className="col flex-grow-1 d-flex flex-wrap">
	            					<div className="col-md-6">
		    		                    <p className="text-danger mb-2 fw-bold">{element.date}</p>
		    		                    <p className="text-success mb-2 fw-bold">Ref: {element.id}</p>
		    		                    <p className="fw-bold mb-2">{element.message.split('\n').map(item => (<span>{item}<br/></span>))}</p>
		    		                </div>
            					</div>
            				</div>
            			</div>
            		</div>
            	)) : <div className="card shadow mt-3"><div className="card-body">{t('msg.no.record.found.on.date')}</div></div>
            	}
            </div>
        </div>
    )
}
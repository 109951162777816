import React, { useState, useEffect } from 'react'
import moment from "moment"
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import dataHandeler from '../../services/server'

export function CashReload() {
    const history = useHistory();
	const [bankList, setBankList] = useState([]);
    const [pointsStatement, setPointsStatement] = useState([]);
    const [reloadDetails, setReloadDetails] = useState({point:0, amount:'', minAmt:10, reference:'', isTermCondition:false});
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isTransactionClosed, setIsTransactionClosed] = useState(false);
	
	useEffect(() => {
		let obj = {}
		obj.typeCode = 'POINT'
		dataHandeler.getPointsStatement(obj).then(result => {
            setPointsStatement(result.data);
            setIsTransactionClosed(result.data.isTransactionClosed);
            if (result.data.isTransactionClosed) {
            	toast.error('System temporary close shop, please try again later!');
            }
        }).catch(error => console.log('error came after it ', error))
		
		getBankList()
    }, [])
	
	const getBankList = () => {
		dataHandeler.getBankList().then(result => {
			setBankList(result.data.bankList)
		}).catch(error => console.log('error came after it ', error))
	}
	
	const onBankSelected = (e) => {
        if (e.target.value === '') {
        	setReloadDetails({ ...reloadDetails, bankId: '', bankName: '', bankAccountName: '', bankAccountNo: ''});
        } else {
			for (let i = 0; i < bankList.length; i++) {
				if (bankList[i].uuid === e.target.value) {
	                //reloadDetails.bankId = bankList[i].uuid
	                //reloadDetails.bankName = bankList[i].bankName
	                //reloadDetails.bankAccountName = bankList[i].bankHolderName
	                //reloadDetails.bankAccountNo = bankList[i].bankAccountNo
	                
	                setReloadDetails({ ...reloadDetails, bankId: bankList[i].uuid, bankName: bankList[i].bankName, bankAccountName: bankList[i].bankHolderName, bankAccountNo: bankList[i].bankAccountNo});
					break;
				}
			}
		}
	}

    const handleChange = (e, type) => {
        if (type === 'TNC') {
            setReloadDetails({ ...reloadDetails, [e.target.name]: !reloadDetails.isTermCondition })
        } else
            setReloadDetails({ ...reloadDetails, [e.target.name]: e.target.value })
    };
	
	const addAmount = (e) => {
		let reg = /^\d+$/
        if (reg.test(e.target.value) || e.target.value === '') {
        	setReloadDetails({...reloadDetails, amount: e.target.value,
        			point: (Number(e.target.value * pointsStatement.commission / 100) + Number(e.target.value))})
        }
	}
	
	const handleFileInput = (e) => {
		let file = e.target.files[0]
        setSelectedFile(e.target.files[0])

        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            reloadDetails.uploadFile = reader.result
            reloadDetails.uploadFileName = e.target.files[0].name
        }
	}

    const cashReloadEvent = () => {
        if (!reloadDetails.bankName || reloadDetails.bankName === '') {
            toast.error('Please select bank')
            return
        }
        if (!reloadDetails.reference || reloadDetails.reference === '') {
            toast.error('Please select reference')
            return
        }
        if (reloadDetails.amount === 0 || reloadDetails.amount === '') {
    		toast.error('Please fill your amount')
    		return;
    	}
        if (reloadDetails.amount < reloadDetails.minAmt) {
            toast.error('Minimum amount is RM' + reloadDetails.minAmt)
            return
        }
        if (!reloadDetails.isTermCondition) {
            toast.error('Please agreed the T&C')
            return
        }

        if (selectedFile == null) {
            toast.error('Please select your upload')
            return
        }

        toast.dismiss();
        setLoading(true);
        
        dataHandeler.pointCashReload(reloadDetails).then(res => {
            if (res.data.status === 0) {
                history.push('point-statements')
                toast.success('Cash reload request already sent to company, the process will be made in 24 hours')
            } else toast.error(res.data.message)
            setLoading(false)
        })
    }

	return (
		<div className="container">
			<div className="row ">
				<div className="col-12 web-nav headingSection d-flex align-items-center">
					<h1 className="flex-grow-1"><span className="pageheading">Cash Reload</span></h1>
					<Link className="btn btn-danger respBtn" to="/point-statements">Back</Link>
				</div>
				<div className="col-md-12 mx-auto mt-3 mobileContent">
					<div className="card shadow mb-4">
						<div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
							<form className="col-ms-8 col-10 mx-auto">
								<div className="row">
									<div className="col-md-12 md-3 mt-4">
										<div className="form-group">
											<select className="form-control" onChange={(e) => onBankSelected(e)} required>
												<option value="" selected></option>
												{bankList.map((list, key) => (
													<option key={key} value={list.uuid} >{list.userDefineCodeVO.description}</option>
												))}
											</select>
											<label className="form-label">Bank</label>
											<div style={{color:'#333333', fontSize:'15px', paddingTop:'5px'}}>Account Name: {reloadDetails.bankAccountName}</div>
											<div style={{color:'#333333', fontSize:'15px', paddingTop:'5px'}}>Account No.: {reloadDetails.bankAccountNo}</div>
										</div>
									</div>
									<div className="col-md-12 md-3">
										<div className="form-group">
											<input type="text" id="idReference" placeholder="Reference (e.g. last 4 digits of your phone number)"
												className="form-control" name='reference' onChange={(e) => handleChange(e, 'REFERENCE')} required/>
											<label className="form-label">Reference (e.g Tel : xxx-xxx1234)</label>
										</div>
									</div>
									<div className="col-md-6 md-3">
										<div className="form-group">
											<input type="text" id="idAmount" placeholder="Amount (Min 10)" className="form-control"
												onChange={(e) => { addAmount(e) }} value={reloadDetails.amount} required/>
											<label className="form-label">Amount (Min 10)</label>
										</div>
									</div>
									{/*<div className="col-md-6 md-3">
										<div className="form-group">
											<input type="text" className="form-control" value={pointsStatement.commission + '%'} readonly/>
											<label className="form-label">Commission Rate</label>
										</div>
									</div>*/}
									<div className="col-md-6 md-3">
										<div className="form-group">
											<input type="text" className="form-control" value={reloadDetails.point} readonly/>
											<label className="form-label">Point Total</label>
										</div>
									</div>
									<div className="col-md-6 md-3">
										<div className="form-group">
											<input type="File" className="form-control" onChange={(e) => {handleFileInput(e)}}/>
											<label className="form-label">Upload Receipt</label>
										</div>
									</div>
									<div className="col-md-6 md-3">
                                        {reloadDetails.amount < reloadDetails.minAmt ? 
                                            <button className="btn btn-primary w-100" disabled="true">
                                                {'Min Amount ' + reloadDetails.minAmt}
                                            </button>
                                            :
                                            <button type="button" className="btn btn-primary w-100" onClick={() => { cashReloadEvent() }} disabled={loading || isTransactionClosed}>
                                                {loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : 'Reload'}
                                            </button>
                                        }
									</div>
									<div className="col-md-12 md-3">
										<div className="form-check">
											<input type="checkbox" id="idCheckBox" className="form-check-input" name='isTermCondition' required
                                                onChange={(e) => handleChange(e, 'TNC')} defaultChecked={reloadDetails.isTermCondition}/>
											<label className="form-check-label" for="idCheckBox" style={{color:'#000000'}}>I have read and agreed the T&C</label>
										</div>
									</div>
									<div className="col-md-12 md-3 mt-4" style={{color:'#000000'}}>
										<p><b>T&C:</b></p>
										<p>Our system may change the account anytime.</p>
										<p>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16" style={{color:'red', marginTop:'-5px'}}>
												<path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
											</svg>
											&nbsp;Please confirm and follow the account information given before transfer.
										</p>
										<p>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16" style={{color:'red', marginTop:'-5px'}}>
												<path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
											</svg>
											&nbsp;Our customer service will process within 30 minutes.
										</p>
										<p>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16" style={{color:'red', marginTop:'-5px'}}>
												<path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
											</svg>
											&nbsp;Please put your phone last 4 digits number as bank reference. (eg:012-3456789 REF:6789)
										</p>
										<p>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16" style={{color:'red', marginTop:'-5px'}}>
												<path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
											</svg>
											&nbsp;Our company will not be responsible for any loss and related liabilities caused by this.
										</p>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			 </div>
		</div>
	)
}
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dataHandeler from '../../services/server'
import './Home.css'
import { Storage } from '../../Storage/Storage';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Modal } from 'react-bootstrap';

export function Home(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    let systemData = Storage.get('system')

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        dataHandeler.homePage().then(result => {
            setData(result);
        }).catch(error => console.log(error))
    }

    const handleShowQR = () => {
    	setShowModal(true);
    }
    

    return (
        <div className="container">
            { data.data ?
                <div className="row">
                    <div className="col-12 mobileContent">
                        <div className="card shadow mt-2 mb-3">
                            <div className="card-body py-2 px-2 mb-2">
                                <div className="row px-2 fw-bold lh-base">
                                    <div className="col-5 text-center px-2 border-end">
                                        <button type="button" class="btn3d btn btn-primary w-100"
                                            onClick={() => props.history.push('/create-downline')}>
                                            <Link style={{textDecoration:'none', color:'white'}}>+ Downline</Link>
                                        </button>
                                    </div>
                                    <div className="col-5 text-center px-2">
                                        {/*<button type="button" class="btn3d btn btn-primary w-100" onClick={() => props.history.push('/qr-code')}>
                                            <Link className="col-md py-3 px-2">
                                                <img src="https://miro.medium.com/max/990/1*PNniLVIC_Hc7gNIxjvWPWg.png" width={20} />
                                            </Link>
                                        </button>*/}
                                        <Link class="btn3d btn btn-primary w-100" to="/scan-merchant">
                                        	{t('cash.withdraw.title')}
	                                    </Link>
                                    </div>
                                    <div className="col-2 text-center px-2">
                                        <a href={'https://wa.me/' + systemData.whatsappPhoneNo}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="#25D366">
                                                <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.86 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow bg-primary mb-3">
                            <div className="card-body px-md-3 text-white py-md-4 py-2 px-2">
                                <div className="d-flex">
                                    <div className="user pe-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                        </svg>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h3 className="fw-bold text-white">{data.data.name}</h3>
                                        <p className="text-white mb-0">{data.data.phone}</p>
                                    </div>
                                    {/* <div className="qrcode">
                                        <button type="button" class="btn3d btn btn-primary w-100" onClick={() => props.history.push('/create-downline')}>
                                            <Link style={{textDecoration:'none', color:'white'}}>Create Downline</Link>
                                        </button>
                                        <button type="button" class="btn3d btn btn-primary w-100" onClick={() => props.history.push('/qr-code')}>
                                            <Link className="col-md py-3 px-2">
                                                <img src="https://miro.medium.com/max/990/1*PNniLVIC_Hc7gNIxjvWPWg.png" width={20} />
                                            </Link>
                                        </button>
                                    </div> */}
                                    <div className="qrcode" style={{backgroundColor:'#ffffff', padding:'2px'}}>
                                    	<a onClick={() => setShowModal(true)}><QRCode value={data.data.phone} size={60}/></a>
                                    </div>
                                </div>
                                {/* Web View */}
                                <div className="points_section d-flex justify-content-between text-center align-items-center web-nav">
                                    <div className="px-md-3 text-white py-3 row w-100" style={{ margin: 'auto'}}>
                                    	<div className="col-6 border-end">
                                            <button type="button" class="btn3d btn btn-primary w-100" onClick={() => props.history.push('/point-statements')}>
                                                <Link style={{textDecoration:'none', color:'white'}}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src="assets/images/p-coin.svg" height={35} />
                                                        </div>
                                                        <div className="ps-3 text-start text-uppercase">
                                                            <small>{t('home.points')}</small>
                                                            <h2 className="mb-0">{data.data.point}</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button type="button" class="btn3d btn btn-primary w-100" onClick={() => props.history.push('/cash-statements')}>
                                                <Link style={{textDecoration:'none', color:'white'}}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src="assets/images/c-coin.svg" height={35} />
                                                        </div>
                                                        <div className="ps-3 text-start text-uppercase">
                                                            <small>{t('home.cash')}</small>
                                                            <h2 className="mb-0">{data.data.cash}</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </button>
                                        </div>
                                        { data.data.isMerchant === 'Y' &&
                                        <div className="col-12 mt-2">
                                            <button type="button" class="btn3d btn btn-primary w-100" onClick={() => props.history.push('/merchant-statements')}>
                                                <Link style={{textDecoration:'none', color:'white'}}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src="assets/images/m-coin.svg" height={35} />
                                                        </div>
                                                        <div className="ps-3 text-start text-uppercase">
                                                            <small>{t('home.merchant')}</small>
                                                            <h2 className="mb-0">{data.data.merchantAmt}</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </button>
                                        </div>
                                        }
                                    </div>
                                </div>
                                {/* Mobile View */}
                                <div className="points_section d-flex justify-content-between text-center align-items-center mobile-nav">
                                    <div className="px-md-3 text-white py-3 row w-100" style={{ margin: 'auto'}}>
                                        <div className="col-12">
                                            <button type="button" class="btn3d btn btn-primary w-100"
                                                onClick={() => props.history.push('/point-statements')}>
                                                <Link style={{textDecoration:'none', color:'white'}}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src="assets/images/p-coin.svg" height={35} />
                                                        </div>
                                                        <div className="ps-3 text-start text-uppercase">
                                                            <small>{t('home.points')}</small>
                                                            <h2 className="mb-0">{data.data.point}</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </button>
                                            <button type="button" class="btn3d btn btn-primary w-100" style={{ marginTop: 10}}
                                                onClick={() => props.history.push('/cash-statements')}>
                                                <Link style={{textDecoration:'none', color:'white'}}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src="assets/images/c-coin.svg" height={35} />
                                                        </div>
                                                        <div className="ps-3 text-start text-uppercase">
                                                            <small>{t('home.cash')}</small>
                                                            <h2 className="mb-0">{data.data.cash}</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </button>
                                            { data.data.isMerchant === 'Y' &&
                                            <button type="button" class="btn3d btn btn-primary w-100" style={{ marginTop: 10}}
                                                onClick={() => props.history.push('/merchant-statements')}>
                                                <Link style={{textDecoration:'none', color:'white'}}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src="assets/images/m-coin.svg" height={35} />
                                                        </div>
                                                        <div className="ps-3 text-start text-uppercase">
                                                            <small>{t('home.merchant')}</small>
                                                            <h2 className="mb-0">{data.data.merchantAmt}</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow mb-3 p-3 bottomnav">
                            <div className="w-100 d-flex flex-wrap justify-content-left">
                                { data.data.isMerchant === 'Y' &&
                                <div className="col-4 col-md-3 mb-0 px-1 mb-2">
	                                <Link className="col-md py-3 px-2" to="/merchant-transfer" style={{backgroundColor:'#dc3545'}}>
	                                    <img src="assets/images/merchant.png" height={40} />
	                                    <p className="mb-0 mt-2">{t('menu.merchant')}</p>
	                                </Link>
	                            </div>
                                }
                                <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                    <Link className="col-md py-3 px-2" to="/game-list">
                                        <img src="assets/images/enter-games.svg" height={40} />
                                        <p className="mb-0 mt-2">{t('menu.games')}</p>
                                    </Link>
                                </div>
                                <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                    <Link className="col-md py-3 px-2" to="/report">
                                        <img src="assets/images/result.svg" height={40} />
                                        <p className="mb-0 mt-2">{t('menu.report')}</p>
                                    </Link>
                                </div>
                                <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                    <Link className="col-md py-3 px-2" to="/settings">
                                        <img src="assets/images/settings.svg" height={40} />
                                        <p className="mb-0 mt-2">{t('menu.settings')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showModal} keyboard="false" onHide={() => setShowModal(false)}>
	                	<Modal.Header>
	                		<Modal.Title>Member QR code</Modal.Title>
	                	</Modal.Header>
	                	<Modal.Body>
	                		<div style={{textAlign:'center'}}>
	                			<QRCode value={data.data.phone} size={200}/>
	                		</div>
	                	</Modal.Body>
	                	<Modal.Footer>
	                		<div style={{width:'100%',textAlign:'center'}}>
	                			<h3>{data.data.phone}</h3>
	                		</div>
	                	</Modal.Footer>
	                </Modal>
                </div>
                :
                <div className="loader">
                    <div className="triple-spinner"></div>
                </div>
            }
        </div>
    )
}

import React from 'react';

export default function UiBetList({uiBetList, removeBetRow, index}) {

    const betValue = (element) => {
        let value = '';
        let numLength = element.number.length;
        if (numLength === 15) {
            return 'NO BET';
        } else {
            if (numLength >= 4) {
                if (element.big !== 0 && element.big !== undefined) {
                    value += element.big && element.big + 'B ';
                }
            }
            if (numLength === 4) {
                if (element.small !== 0 && element.small !== undefined) {
                    value += element.small && element.small + 'S ';
                }

                if (element.foura !== 0 && element.foura !== undefined) {
                    value += element.foura && element.foura + 'SA ';
                }

                if (element.threea !== 0 && element.threea !== undefined) {
                    value += element.threea && element.threea + 'A ';
                }

                if ((element.threeabc !== 0) && (element.threeabc !== undefined)) {
                    value += element.threeabc && element.threeabc + 'C ';
                }

                if (element.foursb !== 0 && element.foursb !== undefined) {
                    value += element.foursb && element.foursb + 'SB ';
                }

                if (element.foursc !== 0 && element.foursc !== undefined) {
                    value += element.foursc && element.foursc + 'SC ';
                }

                if (element.fourd !== 0 && element.fourd !== undefined) {
                    value += element.fourd && element.fourd + 'D ';
                }

                if (element.foure !== 0 && element.foure !== undefined) {
                    value += element.foure && element.foure + 'E ';
                }

                if (element.fourc !== 0 && element.fourc !== undefined) {
                    value += element.fourc && element.fourc + 'C ';
                }
            }
            if (numLength === 3) {
                if (element.threea !== 0 && element.threea !== undefined) {
                    value += element.threea && element.threea + 'A ';
                }

                if ((element.threeabc !== 0) && (element.threeabc !== undefined)) {
                    value += element.threeabc && element.threeabc + 'C ';
                }

                if ((element.threesa !== 0) && (element.threesa !== undefined)) {
                    value += element.threesa && element.threesa + 'SA ';
                }

                if ((element.threesb !== 0) && (element.threesb !== undefined)) {
                    value += element.threesb && element.threesb + 'SB ';
                }

                if ((element.threesc !== 0) && (element.threesc !== undefined)) {
                    value += element.threesc && element.threesc + 'SC ';
                }

                if ((element.threed !== 0) && (element.threed !== undefined)) {
                    value += element.threed && element.threed + 'D ';
                }

                if ((element.threee !== 0) && (element.threee !== undefined)) {
                    value += element.threee && element.threee + 'E ';
                }
            }
            if (numLength === 2) {
                if (element.twoa !== 0 && element.twoa !== undefined) {
                    value += element.twoa && element.twoa + 'A ';
                }

                if ((element.twoabc !== 0) && (element.twoabc !== undefined)) {
                    value += element.twoabc && element.twoabc + 'C ';
                }

                if ((element.big !== 0) && (element.big !== undefined)) {
                    value += element.big && element.big + 'B ';
                }

                if ((element.twosa !== 0) && (element.twosa !== undefined)) {
                    value += element.twosa && element.twosa + 'SA ';
                }

                if ((element.twosb !== 0) && (element.twosb !== undefined)) {
                    value += element.twosb && element.twosb + 'SB ';
                }

                if ((element.twosc !== 0) && (element.twosc !== undefined)) {
                    value += element.twosc && element.twosc + 'SC ';
                }

                if ((element.twod !== 0) && (element.twod !== undefined)) {
                    value += element.twod && element.twod + 'D ';
                }

                if ((element.twoe !== 0) && (element.twoe !== undefined)) {
                    value += element.twoe && element.twoe + 'E ';
                }
            }
            return value;
        }
    }
    return (
        <div className="card shadow">
            <div className="card-body btn m-1 p-0">
                <div className="table-responsive">
                    <table className="table custom_table text-center whitespace w-100">
                        <tbody>
                            {uiBetList.length > 0 ? 
                                uiBetList.map((element, key) => (
                                    <tr className={`fw-bold ${key === index ? 'activeRow' : ''}`}>
                                        <td style={{ width: '20%' }}> {element.counter.join('')} </td>
                                        <td style={{ width: '20%' }} className="text-muted">
                                            {element.number}{element.type === 'S' ? '' : '(' +element.type + ')'}
                                        </td>
                                        <td style={{ width: '20%' }} className="text-warning"> {betValue(element) === '' ? 'NO BET' : betValue(element)} </td>
                                        <td style={{ width: '20%' }} className="text-danger"> ${element.money} </td>
                                        { uiBetList.length > 0 ?
                                        <td style={{ width: '20%' }} className="text-danger" onClick={() => removeBetRow(key)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ height: '22px' }} fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </td> : <td></td> }
                                    </tr>
                                ))
                            :
                                <tr className="fw-bold">
                                    <td colSpan={6} style={{ width: '100%' }} className="text-warning"> Your added bets will be shown here </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

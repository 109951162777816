import React, { useState, useEffect } from 'react';
import moment from "moment";
import dataHandeler from '../../services/server';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
const { REACT_APP_SIGNUP_URL } = process.env;

export function QrCode(props) {
	
	const [qrCodeStatement, setQrCodeStatement] = useState([]);
	const [signupUrl, setSignupUrl] = useState('');
	
	useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        dataHandeler.getQrCodeInfo().then(result => {
        	if (result.data.status === 0) {
	        	setQrCodeStatement(result.data);
	        	setSignupUrl(REACT_APP_SIGNUP_URL + '?token=' + result.data.token);
	        	
	        	let buff = new Buffer(result.data.token, 'base64');
	        	let base64ToStringNew = buff.toString('ascii');
        	}
        }).catch(error => console.log('error came after it ', error));
    }
	
	return (
	    <div className="container">
	      <div className="row">
	        <div className="col-12 web-nav headingSection d-flex align-items-center">
	            <h1 className="flex-grow-1">
	                <span className="pageheading">QR Code</span>
	            </h1>
	            <Link className="btn btn-danger respBtn" to="/create-downline">Back</Link>
	        </div>
	        {qrCodeStatement.token ?
	        <div className="col-12 mobileContent mt-3">
	          <div className="card">
	            <div className="card-body shadow">
	              <div className="text-center">
	              	<QRCode value={signupUrl}/>
	                <br/><br/>
	              	{/*<h4>Rate for your downline</h4>
	                <div className="row text-center mx-auto mb-3" style={{ maxWidth: '300px' }}>
	                  <div className="col">
	                    <p>Commission</p>
	                    <h2>{qrCodeStatement.dlComm}%</h2>
	                  </div>
	                </div>
	                <div className="col-12 text-center">
	                  <Link className="btn btn-primary text-uppercase fw-bold" to={{pathname:'/edit-qr-code', module:'EditQrCode', comm: qrCodeStatement.dlComm, maxComm: qrCodeStatement.ownComm}}>Edit</Link>
	                </div>*/}
	                <div className="col-md-6 mt-3 mx-auto">
	                  <div className="form-group text-start form-ani mb-0">
	                    <div className>
	                      <input className="form-control" placeholder=" " defaultValue={signupUrl} type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="col-md-6 mt-3 mx-auto">
	                  <a href="#" className="px-3 text-decoration-none">
	                    <img src="assets/images/whatsapp.svg" height={40} />
	                  </a>
	                  <a href="#" className="px-3 text-decoration-none">
	                    <img src="assets/images/mail.svg" height={40} />
	                  </a>
	                  <a href="#" className="px-3 text-decoration-none">
	                    <img src="assets/images/copy.svg" height={40} />
	                  </a>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	        : <div className="loader">
			        <div className="triple-spinner"></div>
			    </div>
			}
	      </div>
	    </div>
	)
}

import React from 'react';

export default function UiBetList({uiBetList, activeBetRow, removeBetRow, index}) {

    const betValue = (element) => {
        let value = '';
        let numLength = element.number.length;
        if (numLength === 15) {
            return 'NO BET';
        } else {
            if (numLength >= 4) {
                if (element.big !== 0 && element.big !== undefined) {
                    value += element.big && element.big + 'B ';
                }
            }
            if (numLength === 4) {
                if (element.small !== 0 && element.small !== undefined) {
                    value += element.small && element.small + 'S ';
                }

                if (element.foura !== 0 && element.foura !== undefined) {
                    value += element.foura && element.foura + 'SA ';
                }
            }
            if (numLength === 3) {
                if (element.threea !== 0 && element.threea !== undefined) {
                    value += element.threea && element.threea + 'A ';
                }

                if ((element.threeabc !== 0) && (element.threeabc !== undefined)) {
                    value += element.threeabc && element.threeabc + 'C ';
                }
            }
            return value;
        }
    }
    return (
        <div className="card shadow">
            <div className="card-body btn">
                <div className="table-responsive">
                    <table className="table custom_table text-center whitespace">
                        <tbody>
                            {uiBetList && uiBetList.map((element, key) => (
                                <tr className={`fw-bold ${key === index ? 'activeRow' : ''}`}>
                                    <td style={{ width: '16.6666667%' }} onClick={() => key !== index && activeBetRow(key)}> {element.counter.join('')} </td>
                                    <td style={{ width: '16.6666667%' }} className="text-muted" onClick={() => key !== index && activeBetRow(key)}> {element.number} </td>
                                    <td style={{ width: '16.6666667%' }} className="text-warning" onClick={() => key !== index && activeBetRow(key)}> {betValue(element) === '' ? 'NO BET' : betValue(element)} </td>
                                    <td style={{ width: '16.6666667%' }} className="text-muted" onClick={() => key !== index && activeBetRow(key)}> {element.type} </td>
                                    <td style={{ width: '16.6666667%' }} className="text-danger" onClick={() => key !== index && activeBetRow(key)}> ${element.money} </td>
                                    { uiBetList.length > 1 ?
                                    <td style={{ width: '16.6666667%' }} className="text-danger" onClick={() => removeBetRow(key)}>
                                        <svg style={{ height: '22px' }} viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" data-icon="times" data-prefix="far" id="ember419" className="svg-inline--fa fa-times fa-w-10  undefined ember-view">
                                            <path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z">
                                            </path>
                                        </svg>
                                    </td> : <td></td> }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Storage} from '../../Storage/Storage';
import { useTranslation } from 'react-i18next';

export function Settings(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);

    useEffect(() => {
        let userData = Storage.get('user');
        setData(userData);
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('settings.title')}</span>
		            </h1>
                    <Link className="btn btn-danger respBtn" to="/">{t('button.back')}</Link>
                </div>
                <div className="col-12 mobileContent">
                    <div className="card shadow mt-2 p-3 bottomnav">
                        <div className="w-100 d-flex flex-wrap justify-content-left">
                            {/* <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/package">
                                    <img src="assets/images/package.svg" height={40} />
                                    <p className="mb-0 mt-2">{t('settings.package')}</p>
                                </Link>
                            </div> */}
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/downline">
                                    <img src="assets/images/Downline.png" height={40} />
                                    <p className="mb-0 mt-2">{t('settings.downline')}</p>
                                </Link>
                            </div>
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/languages">
                                    <img src="assets/images/languages.png" height={40} />
                                    <p className="mb-0 mt-2">{t('settings.languages')}</p>
                                </Link>
                            </div>
                            { data && data.isMerchant === 'Y' &&
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/merchant-statements">
                                    <img src="assets/images/merchant.png" height={40} />
                                    <p className="mb-0 mt-2">{t('settings.merchant')}</p>
                                </Link>
                            </div>
                            }
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/change-password">
                                    <img src="assets/images/password.png" height={40} />
                                    <p className="mb-0 mt-2">{t('settings.change.password')}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

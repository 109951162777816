import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Storage} from '../../Storage/Storage';

export function Report(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    
    useEffect(() => {
        let userData = Storage.get('user');
        setData(userData);
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('report.title')}</span>
		            </h1>
                    <Link className="btn btn-danger respBtn" to="/">{t('button.back')}</Link>
                </div>
                <div className="col-12 mobileContent">
                    <div className="card shadow mt-2 p-3 bottomnav">
                        <div className="w-100 d-flex flex-wrap justify-content-left">
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md px-2" to="/report-sales">
                                    <img src="assets/images/sales.png" height={40} />
                                    <p className="mb-0 mt-2">{t('report.sales')}</p>
                                </Link>
                            </div>
                            {/*<div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/report-commission">
                                    <img src="assets/images/commission.png" height={40} />
                                    <p className="mb-0 mt-2">{t('report.commission')}</p>
                                </Link>
                            </div>*/}
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/report-bonus">
                                    <img src="assets/images/bonus.png" height={40} />
                                    <p className="mb-0 mt-2">{t('report.bonus')}</p>
                                </Link>
                            </div>
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/sales-order">
                                    <img src="assets/images/Ticket.svg" height={40} />
                                    <p className="mb-0 mt-2">{t('report.ticket')}</p>
                                </Link>
                            </div>
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
                                <Link className="col-md py-3 px-2" to="/result">
                                    <img src="assets/images/result.svg" height={40} />
                                    <p className="mb-0 mt-2">{t('report.result')}</p>
                                </Link>
                            </div>
                            { data && data.isMerchant === 'Y' &&
                            <div className="col-4 col-md-3 mb-0 px-1 mb-2">
	                            <Link className="col-md py-3 px-2" to="/debtor-statements">
	                                <img src="assets/images/debt.svg" height={40} />
	                                <p className="mb-0 mt-2">{t('report.debtor')}</p>
	                            </Link>
	                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

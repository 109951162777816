import React from 'react'

export function Package(props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 headingSection mobileNav d-flex align-items-center">
          <a onClick={() => props.history.goBack()} className="d-inline-block leftIcon text-white">
            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
          </a>
          <h1 className="flex-grow-1">
            <span className="pageheading">Package</span>
          </h1>
        </div>
        <div className="col-12 mobileContent">
          <div className="card mb-3">
            <div className="card-body shadow">
              <div className="row">
                <div className="col-md-6 mx-auto mt-3">
                  <div className="form-group">
                    <label htmlFor="lookingfor" className="floating-label">Select Bet</label>
                    <div className>
                      <select className="form-control" >
                        <option selected />
                        <option value={0}>Bet + 0%</option>
                        <option value={1}>Bet + 5%</option>
                        <option value={2}>Bet + 10%</option>
                        <option value={3}>Bet + 15%</option>
                        <option value={4}>Bet + 20%</option>
                        <option value={5}>Bet + 25%</option>
                        <option value={6}>Bet + 30%</option>
                        <option value={7}>Bet + 35%</option>
                        <option value={8}>Bet + 40%</option>
                        <option value={9}>Bet + 45%</option>
                        <option value={10}>Bet + 50%</option>
                        <option value={11}>Bet + 55%</option>
                        <option value={12}>Bet + 60%</option>
                        <option value={13}>Bet + 65%</option>
                        <option value={14}>Bet + 70%</option>
                        <option value={15}>Bet + 75%</option>
                        <option value={16}>Bet + 80%</option>
                        <option value={17}>Bet + 85%</option>
                        <option value={18}>Bet + 90%</option>
                        <option value={19}>Bet + 95%</option>
                        <option value={20}>Bet + 100%</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body shadow">
              <h4 className="fw-bold">4D</h4>
              <hr />
              <div className="col-12 px-2">
                <table className="table custom_table text-center whitespace w-100">
                  <thead>
                    <tr>
                      <th className="text-end" />
                      <th className="text-end">BIG</th>
                      <th className="text-end">SMALL</th>
                      <th className="text-end">A</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1ST PRIZE</td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">2,500</span><br />+
                            <span className="text-danger">0</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">3,250</b>
                        </small>
                      </td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">3,500</span><br />+
                            <span className="text-danger">0</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">3,250</b>
                        </small>
                      </td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">6,000</span><br />+
                            <span className="text-danger">0</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">6,000</b>
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>2ND PRIZE</td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">1,000</span><br />+
                            <span className="text-danger">300</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">1,300</b>
                        </small>
                      </td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">2,000</span><br />+
                            <span className="text-danger">600</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">2,600</b>
                        </small>
                      </td>
                      <td className="text-end" />
                    </tr>
                    <tr>
                      <td>3RD PRIZE</td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">500</span><br />+
                            <span className="text-danger">150</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">650</b>
                        </small>
                      </td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">1,000</span><br />+
                            <span className="text-danger">300</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">1,300</b>
                        </small>
                      </td>
                      <td className="text-end" />
                    </tr>
                    <tr>
                      <td>SPECIAL</td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">200</span><br />+
                            <span className="text-danger">60</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">260</b>
                        </small>
                      </td>
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr>
                      <td>CONSOLATION</td>
                      <td className="text-end">
                        <small>
                          <span className="text-muted">60</span><br />+
                            <span className="text-danger">18</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">78</b>
                        </small>
                      </td>
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body shadow">
              <h4 className="fw-bold">3D</h4>
              <hr />
              <div className="col-12 px-2">
                <table className="table custom_table text-center whitespace w-100">
                  <tbody>
                    <tr>
                      <td>ABC</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">200</span><br />+
                            <span className="text-danger">60</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">260</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>A</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">600</span><br />+
                            <span className="text-danger">180</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">780</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body shadow">
              <h4 className="fw-bold">5D</h4>
              <hr />
              <div className="col-12 px-2">
                <table className="table custom_table text-center whitespace w-100">
                  <tbody>
                    <tr>
                      <td>1ST PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">15,000</span><br />+
                            <span className="text-danger">4,500</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">19,500</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>2ND PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">5,000</span><br />+
                            <span className="text-danger">1,500</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">6,500</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>3RD PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">3,000</span><br />+
                            <span className="text-danger">900</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">3,900</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>4TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">500</span><br />+
                            <span className="text-danger">150</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">650</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>5TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">20</span><br />+
                            <span className="text-danger">6</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">26</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>6TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">5</span><br />+
                            <span className="text-danger">2</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">7</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body shadow">
              <h4 className="fw-bold">6D</h4>
              <hr />
              <div className="col-12 px-2">
                <table className="table custom_table text-center whitespace w-100">
                  <tbody>
                    <tr>
                      <td>1ST PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">100,000</span><br />+
                            <span className="text-danger">30,000</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">130,000</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>2ND PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">3,000</span><br />+
                            <span className="text-danger">900</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">3,900</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>3RD PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">300</span><br />+
                            <span className="text-danger">90</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">390</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>4TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">30</span><br />+
                            <span className="text-danger">9</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">39</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>5TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">4</span><br />+
                            <span className="text-danger">1</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">5</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body shadow">
              <h4 className="fw-bold">7D</h4>
              <hr />
              <div className="col-12 px-2">
                <table className="table custom_table text-center whitespace w-100">
                  <tbody>
                    <tr>
                      <td>1ST PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">1,000,000</span><br />+
                            <span className="text-danger">300,000</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">1,300,000</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>2ND PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">150,000</span><br />+
                            <span className="text-danger">45,000</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">195,000</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>3RD PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">5,000</span><br />+
                            <span className="text-danger">1,500</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">6,500</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>4TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">500</span><br />+
                            <span className="text-danger">150</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">650</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>5TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">50</span><br />+
                            <span className="text-danger">15</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">65</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td>5TH PRIZE</td>
                      <td className="text-start">
                        <small className="text-end d-inline-block">
                          <span className="text-muted">5</span><br />+
                            <span className="text-danger">2</span><br />
                          <sup className="text-success">RM</sup>
                          <b className="text-success fw-bold">7</b>
                        </small>
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Switch , Redirect } from 'react-router-dom'
import Layout from './Layout'
import PrivateRoutes from './PrivateRoutes'
import * as Page from '../container/Auth'
import RouteFunction from './RouteFunction'
// import useScript from '../Function/useScript'
export default function Index() {
    // useScript('./assets/js/custom.js')
    return (
        <Switch>
            {RouteFunction(Page ,'authRoute')}
            <PrivateRoutes path="/" component= { Layout } />
            <Redirect to='/' from="*"/>
        </Switch>
    )
}
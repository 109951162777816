import React, { useState, useEffect } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import dataHandeler from '../../services/server'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Button } from 'react-bootstrap'

export function PointTransfer() {
    const history = useHistory();
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const [pointsStatement, setPointsStatement] = useState([]);
    const [amount, setAmount] = useState('');
    const [downlinesList, setDownlinesList] = useState([]);
    const [downlineUid, setDownlineUid] = useState('');
    const [points, setPoint] = useState('');
    const [password, setPassword] = useState('');
    const [date, setDate] = useState({ typeCode:'POINT' });
    let { id } = useParams();
    const [isTransactionClosed, setIsTransactionClosed] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch()
        getDownlines()
    }, [])

    const fetch = () => {
        dataHandeler.getPointsStatement(date).then(result => {
            setPointsStatement(result.data);
            setIsTransactionClosed(result.data.isTransactionClosed);
            if (result.data.isTransactionClosed) {
            	toast.error('System temporary close shop, please try again later!');
            }
        }).catch(error => console.log('error came after it ', error))
    }

    const getDownlines = () => {
        dataHandeler.downlines().then((res) => {
            if (res.data.status === 0) {
                setDownlinesList(res.data.downlineList)
            }

            if (id && id != '') {
                setDownlineUid(id)
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    const handleChange = value => {
        setValue(value)
        setPoint(Number((amount * value) / 100) + Number(amount))
    };

    const handleChangeDialog = (e) => {
        setPassword(e.target.value);
    };

    const addAmount = (e) => {
        let reg = /^\d+$/
        if (reg.test(e.target.value) || e.target.value === '') {
            setAmount(e.target.value)
            let commPercent = 0
            if (value) commPercent = value
            else setValue(commPercent)
            setPoint(Number((e.target.value * commPercent) / 100) + Number(e.target.value))
        }
    }

    const onSelectDownline = (e) => {
    	setDownlineUid(e.target.value)
        for (let i = 0; i < downlinesList.length; i++) {
        	if (e.target.value === downlinesList[i].uuid) {
        		setValue(downlinesList[i].ownComm)
        		break
        	}
        }
    }

    const pointTransferEvent = () => {
        if (!password || password === '') {
    		toast.error('Password is required');
    		return;
    	}

        if (!downlineUid) {
            toast.error('please select downline')
            return
        }
        /*if (value === 0) {
            toast.error('please select commission rate')
            return
        }*/
        if (!points) {
            toast.error('amount field is required')
            return
        }
        if (points > pointsStatement.point) {
            toast.error('Insufficient points')
            return
        }
        let postData = {
            point: parseFloat(amount),
            downline: downlineUid,
            commission: value,
            password: password
        }
        
        setLoading(true);
        //console.log("postData", postData)
        dataHandeler.pointTransfer(postData).then(res => {
			if (res.data.status === 0) {
			    history.push('/point-statements')
			    toast.success('Transfer successfully, please check your statement.')
			} else {
				toast.error(res.data.message)
			}
			setLoading(false); 
        });
    }

    const handleShowPrompt = () => setShowPrompt(true);
    const handleClosePrompt = () => setShowPrompt(false);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('point.transfer.title')}</span>
		            </h1>
		            <Link className="btn btn-danger respBtn" to="/point-statements">{t('button.cancel')}</Link>
	            </div>
            </div>
            <div className="row px-2 mobileContent">
                <div className="col-12 my-2 text-align-start px-2">
                    <div className="card shadow bg-primary h-100">
                        <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
                            <small>{t('point.transfer.curr.bal')}</small>
                            <h1 className><img src="assets/images/p-coin.svg" height={25}/> {pointsStatement.point}</h1>
                        </div>
                    </div>
                </div>
                {/* <div className="col-5 my-2 text-center px-2">
                    <div className="card shadow mb-4 h-100 bg-danger">
                        <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
                            <small className="text-center">{t('point.transfer.online')} / ATM</small>
                            <h5 className="text-center sm-h6 fw-bold">10am-7pm</h5>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-12 mx-auto mt-3">
                    <div className="card shadow mb-4">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="col-ms-8 col-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12 md-3 mt-4">
                                        <div className="form-group">
                                            <select required className="form-control" onChange={(e) => onSelectDownline(e)}>
                                                <option value="" selected></option>
                                                {downlinesList.map((list, key) => (
                                                    <option key={key} value={list.uuid} selected={id === list.uuid ? true : false}>{list.name}</option>
                                                ))}
                                            </select>
                                            <label className="form-label">{t('point.transfer.transfer.to')}</label>
                                        </div>
                                    </div>
                                    {/*<div className="col-md-10">
                                        <label htmlFor="companyname" className="text-dark d-block">{t('point.transfer.comm.rate')}({value} %)
                                        </label>
                                        <Slider
                                            min={0}
                                            max={pointsStatement.commission}
                                            value={value}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                    <div className="col-md-2 mt-4">
                                        <div className="form-group">
                                            <input type="text" placeholder="{t('point.transfer.comm.rate')}" style={{ textAlign: 'center' }} className="form-control" value={value} />
                                            <label className="form-label">{t('point.transfer.comm.rate')}</label>
                                        </div>
                                    </div>*/}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text"
                                                onChange={(e) => { addAmount(e) }}
                                                placeholder="{t('point.transfer.amount')}"
                                                className="form-control"
                                                value={amount} />
                                            <label className="form-label">
                                                {t('point.transfer.amount')} {amount > pointsStatement.point && <span className="text-warning"> (Insufficient points) </span>}
                                            </label>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                disabled type="text"
                                                value={points}
                                                placeholder="{t('point.transfer.total')}" className="form-control" />
                                            <label className="form-label">{t('point.transfer.total')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                    	<div className="form-group">
                                    		<button type="button" className="btn btn-primary w-100" onClick={() => { handleShowPrompt() }} disabled={isTransactionClosed}>{t('button.transfer')}</button>
                                    	</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="keyboard_popup" tabIndex={-1} aria-labelledby="keyboard_popupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="text-end p-4 d-flex align-items-center">
                                    <h3 className="text-start text-white flex-grow-1 px-3">Keyboard</h3>
                                    <a data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" height={30} /></a>
                                </div>
                                <div className="modal-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showPrompt} backdrop="static">
                <Modal.Header>
                    <Modal.Title className="fw-bold">{t('label.confirm')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3"><h4>{t('msg.confirm.make.transfer')}</h4></div>
                    <Form.Group className="mb-0 pb-0">
                        <input type="password" placeholder={t('label.password')}
                            className="form-control" name='password' maxLength={10} onChange={(e) => handleChangeDialog(e)}
                            value={password} required/>
                        <label className="form-label">{t('label.password')}</label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClosePrompt()}>{t('button.cancel')}</Button>
                    <Button variant="primary" onClick={() => pointTransferEvent()} disabled={loading}>
                    	{loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.transfer')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

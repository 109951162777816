import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Storage } from '../Storage/Storage'

export default function Public({ component, path } ) {
    if(!Storage.get('user')){
        return (
            <Route path = {path} component={component} />
        )
    }else{
        return (
            <Redirect to="/"/>
        )
    }
}

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

const { REACT_APP_API_IMAGE_URL } = process.env;

export default function OrderText({userPoint, loading, counterLoading, textBet, setTextBet, onBetEvent, isTransactionClosed}) {
	
	const onTextChange = (e) => {
		setTextBet(e.target.value);
	}
	
	return (
		<div className="mobileContent my-2 withoutKeyboard">
			<div className="col-12">
				<div className="card shadow">
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12">
								<img src="assets/images/coin.svg" height={25} style={{marginTop:'-6px'}}/>&nbsp;<span style={{fontWeight:'bold',fontSize:'16px'}}>{userPoint}</span> pts
							</div>
							<div className="col-12 col-md-12 col-lg-12 mt-1">
						    	<textarea style={{width:'100%', minHeight:'450px'}} id="idOrderText" value={textBet} onChange={(e) => onTextChange(e)}>
						    	</textarea>
						    </div>
							<div className="col-12 col-md-12 col-lg-12 mt-1">
								<button type="button" onClick={() => onBetEvent()} className='btn btn-primary w-100' style={{width:'30%'}} disabled={loading || isTransactionClosed}>
		                    		{ loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : 'Bet'}
		                    	</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
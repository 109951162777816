import React, { useState, useEffect } from 'react';
import dataHandeler from '../../services/server';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { REACT_APP_API_IMAGE_URL } = process.env;
export function GameList(props) {
    const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [gameList, setGameList] = useState([]);
    const [isTransactionClosed, setIsTransactionClosed] = useState(false);
	
	useEffect(() => {
		setLoading(true);
		
		dataHandeler.getGames().then((result) => {
			setGameList(result.data.gameList);
			setIsTransactionClosed(result.data.isTransactionClosed);
            if (result.data.isTransactionClosed) {
            	toast.error('System temporary close shop, please try again later!');
            }
			setLoading(false);
			
		}).catch((error) => {
            setLoading(false);
            toast.error(error);
        })
    }, []);
    
    return (
    	<div className="container">
			<div className="row ">
				<div className="col-12 web-nav headingSection d-flex align-items-center">
					<h1 className="flex-grow-1">
						<span className="pageheading">{t('game.list.title')}</span>
					</h1>
					<Link className="btn btn-danger respBtn" to="/">{t('button.back')}</Link>
				</div>
				<div className="col-md-12 mx-auto my-2 mobileContent ">
					<div className="card shadow mb-3 p-3 bottomnav">
					{!loading ?
						<div className="w-100 d-flex flex-wrap justify-content-left">
							{gameList && gameList.length > 0 ? gameList.map((element, i) => (
								<div className="col-4 col-md-3 mb-0 px-1 mb-2" key={i}>
									{!isTransactionClosed ?
										element.gameType === 'MEMBERSHIP' ?
										<Link className="col-md px-2" to={{pathname:'/message-box/' + element.md5GameCode}}>
	                                        <img src={REACT_APP_API_IMAGE_URL + element.defaultImage} width={'90%'} />
	                                        <p className="mb-0 mt-2">{element.name}</p>
	                                    </Link>
										:
										<Link className="col-md px-2" to={{pathname:'/game/' + element.md5GameCode + '-' + element.idPackage}}>
	                                        <img src={REACT_APP_API_IMAGE_URL + element.defaultImage} width={'90%'} />
	                                        <p className="mb-0 mt-2">{element.name}</p>
	                                    </Link>
										
									: 
									<a className="col-md px-2">
	                                    <img src={REACT_APP_API_IMAGE_URL + element.defaultImage} width={'90%'} />
	                                    <p className="mb-0 mt-2">{element.name}</p>
									</a>}
                                </div>
							))
							: <div>No game found!</div>}
						</div>
						: <div className="loader"><div className="triple-spinner"></div></div>
					}
					</div>
				</div>
			</div>
		</div>
    )
}
import React, { useState, useEffect } from 'react'
import dataHandeler from '../../services/server'
import { Link, useParams } from 'react-router-dom'

export function ViewDownline(props) {
	const [downlineDetails, setDownlineDetails] = useState([])
	let { id } = useParams()
	
	useEffect(() => {
        fetch()
    }, [])
	
	const fetch = () => {
        dataHandeler.getMemberDownlineDetails(id).then(result => {
        	setDownlineDetails(result.data.memberInfo)
        }).catch(error => console.log('error came after it ', error));
    }
	
	return (
		<div className="container">
			<div className="row">
				<div className="col-12 web-nav headingSection d-flex align-items-center">
					<h1 className="flex-grow-1">
						<span className="pageheading">Downline Details</span>
					</h1>
					<Link className="btn btn-danger respBtn" to="/downline">Back</Link>
				</div>
				{ downlineDetails != null ?
				<div className="col-12 mobileContent mx-auto my-2">
	                <div className="card mb-3">
	                    <div className="card-body shadow">
	                        <div className="col-12 px-2">
	                            <div className="table-responsive">
	                                <table className="table custom_table text-center whitespace w-100">
	                                    <tbody className="fw-bold">
	                                        <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Name</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>{downlineDetails.name}</td>
	                                        </tr>
                                            <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Phone No.</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>{downlineDetails.contact}</td>
	                                        </tr>
	                                        <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Comm. Rate</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>{downlineDetails.ownComm}%</td>
	                                        </tr>
                                            <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Bonus Rate</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>{downlineDetails.ownBonus}%</td>
	                                        </tr>
	                                        <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Points</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start text-success" style={{border:0}}>{downlineDetails.point}</td>
	                                        </tr>
	                                        <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Downline</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start text-success" style={{border:0}}>{downlineDetails.totalDL}</td>
	                                        </tr>
	                                        <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Level</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>{downlineDetails.totalLvl}</td>
	                                        </tr>
                                            <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Transfer Points</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>
                                                    <Link className="text-primary" to={{pathname:'/point-transfer/' + downlineDetails.uuid}}>Transfer points to {downlineDetails.name}</Link>
                                                </td>
	                                        </tr>
                                            {/* <tr>
	                                            <td width={200} className="text-start" style={{border:0}}>Transfer Cash</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>
                                                    <Link className="text-primary" to={{pathname:'/cash-transfer/' + downlineDetails.uuid}}>Transfer cash to {downlineDetails.name}</Link>
                                                </td>
	                                        </tr> */}
                                            <tr>
	                                        	<td colSpan="3"><Link className="btn btn-danger" to={{pathname:'/edit-downline/' + id, downlineDetails:downlineDetails}}>Edit Downline Details</Link></td>
	                                        </tr>
	                                    </tbody>
	                                </table>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div> : <div className="loader"><div className="triple-spinner"></div></div>}
			</div>
		</div>
	)
}
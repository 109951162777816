import React, { useState, useEffect } from 'react'
import { Storage } from '../../Storage/Storage'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function Languages(props) {
    const [activeOption, setOption] = useState(true)

    useEffect(() => {
        if (Storage.get('localization')) {
            setOption(Storage.get('localization'))
        } else {
            setOption('en')
        }
    }, [])

    const { i18n } = useTranslation();

    const handelChange = (value) => {
        setOption(value)
        i18n.changeLanguage(value)
        Storage.set('localization', value)
    }

    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('settings.languages')}</span>
		            </h1>
                    <Link className="btn btn-danger respBtn" to="/settings">{t('button.back')}</Link>
                </div>
                <div className="col-12 mobileContent">
                    <div className="card shadow mb-3 p-3 bottomnav">
                        <div className="w-100 d-flex flex-wrap justify-content-center">
                            <div class="list-group radio-list-group w-100">
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt1'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'en' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('en')} htmlFor="opt1">English</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt2'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'ms' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('ms')} htmlFor="opt2">Malay</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt3'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'bn' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('bn')} htmlFor="opt3">Bangla</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt4'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'id' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('id')} htmlFor="opt4">Indonesia</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt5'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'th' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('th')} htmlFor="opt5">Thailand</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt6'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'vi' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('vi')} htmlFor="opt6">Vietnam</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt7'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'tw' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('tw')} htmlFor="opt7">Chinese (Tradition)</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt8'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'cn' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('cn')} htmlFor="opt8">Chinese (Simplified)</label>
                                <input
                                    type="radio"
                                    className='btn-check'
                                    name="options-outlined"
                                    id='opt9'
                                    autoComplete="off"
                                    defaultChecked={activeOption === 'tm' ? true : false}
                                />
                                <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => handelChange('tm')} htmlFor="opt9">Tamil</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dataHandeler from '../../services/server';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import QrReader from 'modern-react-qr-reader';

export function MerchantTransfer() {
    let details = {amount:'', minAmt:10, point:0, reference:'', idMember: ''};
    
	const history = useHistory();
    const { t } = useTranslation();
    const [merchantStatement, setMerchantStatement] = useState([]);
    const [merchantDetails, setMerchantDetails] = useState(details);
    const [data, setData] = useState({ typeCode:'POINT', phoneNo:''});
    const [loading, setLoading] = useState(false);
    const amountInputRef = useRef(null);
    const [isTransactionClosed, setIsTransactionClosed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [startScan, setStartScan] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
    	setLoading(true);
    	setMerchantStatement([]);
    	
        dataHandeler.getMerchantStatement(data).then(result => {
            setMerchantStatement(result.data);
            setIsTransactionClosed(result.data.isTransactionClosed);
            if (result.data.isTransactionClosed) {
            	toast.error('System temporary close shop, please try again later!');
            }
            setLoading(false);
        }).catch(error => console.log('error came after it ', error));
    }

    const onSearchChange = (e) => {
        setData({ ...data, phoneNo: e.target.value });
    };
    
    const onSelectType = (e) => {
    	setMerchantDetails({ ...merchantDetails, transType: e.target.value });
    }

    const addAmount = (e) => {
		let reg = /^\d+$/;
        if (reg.test(e.target.value) || e.target.value === '') {
            if (merchantStatement.member)
	        	setMerchantDetails({...merchantDetails, amount: e.target.value, point: e.target.value});
	        			//point: (Number(e.target.value * merchantStatement.member.ownComm / 100) + Number(e.target.value))});
        }
	}

    const handleChange = (e, type) => {
        setMerchantDetails({ ...merchantDetails, [e.target.name]: e.target.value });
    };

    const handleChangeDialog = (e) => {
        setMerchantDetails({ ...merchantDetails, [e.target.name]: e.target.value });
    };
    
    const searchMemberEvent = () => {
    	setLoading(true);
    	setMerchantStatement([]);

        dataHandeler.getMerchantStatement(data).then(result => {
            setMerchantStatement(result.data);
            setLoading(false);
            setMerchantDetails(details);
            
            if (!result.data.member) toast.error('Invalid merchant!');
            else amountInputRef.current.select();
        }).catch(error => console.log('error came after it ', error));
    }

    const merchantTransferEvent = () => {
        if (!merchantDetails.password || merchantDetails.password === '') {
    		toast.error('Password is required');
    		return;
    	}

        if (merchantDetails.amount === 0 || merchantDetails.amount === '') {
    		toast.error('Please fill your amount');
    		return;
    	}

        if (merchantStatement.member) {
            merchantDetails.idMember = merchantStatement.member.uuid;
        } else {
            toast.error('Please search the phone no. for transfer');
    		return;
        }

        toast.dismiss();
        setLoading(true);
        dataHandeler.merchantTransfer(merchantDetails).then(res => {
            if (res.data.status === 0) {
                history.push('merchant-statements');
                toast.success('Transfer successfully, please check your statement.');
            } else toast.error(res.data.message);
            setLoading(false);
        })
    }
    
    const handleShowScanner = () => {
    	setShowModal(true);
    	setStartScan(true);
    }
    
    const handleCloseScanner = () => {
    	setShowModal(false);
    	setStartScan(false);
    }
    
    const onScan = (result) => {
    	if (result) {
    		data.phoneNo = result;
    		
    		setShowModal(false);
    		setStartScan(false);
    		searchMemberEvent();
    	}
    }
    
    const onError = (err) => {
    	toast.error('Error: ' + err);
    }

    const handleShowPrompt = () => setShowPrompt(true);
    const handleClosePrompt = () => setShowPrompt(false);
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('merchant.transfer.title')}</span>
		            </h1>
                    <Link className="btn btn-danger respBtn" to="/merchant-statements">{t('button.cancel')}</Link>
	            </div>
                <div className="col-md-12 mx-auto my-2 mobileContent ">
                    <div className="card shadow">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="col-ms-8 col-10 mx-auto">
                                <div className="row mt-3">
                                    {/* <div className="col-3 px-md-3 px-0">
                                        <div className="form-group text-align-end mb-0 form-ani">
                                            <select className="form-control" name='code' onChange={{}} >
                                                <option value="+60">+60</option>
                                            </select>
                                            <label className="form-label"> + Code</label>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6 md-3 pt-1">
                                        <div className="form-group text-align-end mb-0 form-ani">
                                            <input type="tel" placeholder={t('merchant.transfer.phone.no')} value={data.phoneNo}
                                                className="form-control" name='loginId' onChange={(e) => { onSearchChange(e) }} />
                                            <label className="form-label">{t('merchant.transfer.phone.no')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6 px-md-3 pt-1">
                                        <div className="form-group text-align-end mb-0 pb-0">
                                            <button type="button" className="btn btn-primary w-100 px-4" onClick={() => { searchMemberEvent() }} disabled={isTransactionClosed}>{t('button.search')}</button>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6 px-md-3 pt-1">
	                                    <div className="form-group text-align-end mb-0 pb-0">
	                                        <button type="button" className="btn btn-warning w-100 px-4" disabled={isTransactionClosed}
	                                        	data-bs-toggle="modal" data-bs-target="#idScanner"
	                                        	onClick={() => handleShowScanner()}>{t('button.scan')}</button>
	                                    </div>
	                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row px-2 mobileContent">
                <div className="col-12 my-2 text-align-start px-2">
                    <div className="card shadow bg-primary h-100">
                        <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
                            <small>{t('merchant.transfer.curr.bal')}</small>
                            <h1 className><img src="assets/images/m-coin.svg" height={25}/> {merchantStatement.amount}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mx-auto mt-2">
                    <div className="card shadow mb-4">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="col-ms-8 col-10 mx-auto">
                                { merchantStatement.member &&
                                <div className="table-responsive">
                                    <table className="table custom_table text-center whitespace w-100">
                                        <tbody className="fw-bold">
                                            {/* <tr>
                                                <td width={100} className="text-start" style={{border:0}}>Member Code</td>
                                                <td width={40} style={{border:0}}>-</td>
                                                <td className="text-start" style={{border:0}}>{merchantStatement.member.uuid}</td>
                                            </tr> */}
                                            <tr>
                                                <td width={100} className="text-start" style={{border:0}}>Name</td>
                                                <td width={40} style={{border:0}}>-</td>
                                                <td className="text-start" style={{border:0}}>{merchantStatement.member.name}</td>
                                            </tr>
                                            <tr>
                                                <td width={100} className="text-start" style={{border:0}}>Phone No.</td>
                                                <td width={40} style={{border:0}}>-</td>
                                                <td className="text-start" style={{border:0}}>{merchantStatement.member.contact}</td>
                                            </tr>
                                            <tr>
                                                <td width={100} className="text-start" style={{border:0}}>Point Balance</td>
                                                <td width={40} style={{border:0}}>-</td>
                                                <td className="text-start" style={{border:0}}>{merchantStatement.member.point}</td>
                                            </tr>
                                            <tr>
	                                            <td width={100} className="text-start" style={{border:0}}>Cash Balance</td>
	                                            <td width={40} style={{border:0}}>-</td>
	                                            <td className="text-start" style={{border:0}}>{merchantStatement.member.amount}</td>
	                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }
                                <div className="row">
	                                <div className="col-md-12 md-3 mt-4">
		                                <div className="form-group">
		                                    <select required className="form-control" onChange={(e) => onSelectType(e)}>
		                                        <option value="" selected></option>
		                                        <option value="TOPUP">{t('merchant.transfer.type.topup')}</option>
		                                        <option value="WITHDRAW">{t('merchant.transfer.type.withdraw')}</option>
		                                    </select>
		                                    <label className="form-label">{t('merchant.transfer.type')}</label>
		                                </div>
		                            </div>
                                    <div className="col-md-12 md-3 mt-4">
										<div className="form-group">
											<input type="text" id="idAmount" placeholder="Amount (Min 10)" className="form-control"
												onChange={(e) => { addAmount(e) }} value={merchantDetails.amount}
                                                ref={amountInputRef} required/>
											<label className="form-label">{t('merchant.transfer.amount', { value: merchantDetails.minAmt})}</label>
										</div>
									</div>
									{/*<div className="col-md-6 md-3">
										<div className="form-group">
											<input type="text" className="form-control" value={merchantStatement.member ? merchantStatement.member.ownComm + '%' : '0%'} readonly/>
											<label className="form-label">{t('merchant.transfer.comm.rate')}</label>
										</div>
									</div>*/}
									{ merchantDetails.transType === 'TOPUP' &&
									<div className="col-md-12 md-3">
										<div className="form-group">
											<input type="text" className="form-control" value={merchantDetails.point} readonly/>
											<label className="form-label">{t('merchant.transfer.point.total')}</label>
										</div>
									</div>
									}
                                    <div className="col-md-12 md-3">
										<div className="form-group">
											<input type="text" id="idReference" placeholder="Reference"
												className="form-control" name='reference' onChange={(e) => handleChange(e, 'REFERENCE')}
                                                value={merchantDetails.reference}  />
											<label className="form-label">Reference</label>
										</div>
									</div>
                                    <div className="col-md-12">
                                    	<div className="form-group">
                                    		{(merchantDetails.amount > merchantStatement.amount && merchantDetails.transType === 'TOPUP') || (merchantStatement.member && merchantDetails.amount > merchantStatement.member.amount && merchantDetails.transType === 'WITHDRAW') || merchantDetails.amount < merchantDetails.minAmt || isTransactionClosed ? 
                                    			<button className="btn btn-primary w-100" disabled="true">
                                    				{merchantDetails.amount > merchantStatement.amount ?
                                                        t('merchant.transfer.insufficient.credits') :
                                                        t('merchant.transfer.amount.min', { value: merchantDetails.minAmt})
                                                    }
                                    			</button>
                                    			: <button type="button" className="btn btn-primary w-100" onClick={() => { handleShowPrompt() }} disabled={loading}>
                                    				{loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.transfer')}
                                    			  </button>
                                    		}
                                    	</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal fade" id="keyboard_popup" tabIndex={-1} aria-labelledby="keyboard_popupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="text-end p-4 d-flex align-items-center">
                                    <h3 className="text-start text-white flex-grow-1 px-3">Keyboard</h3>
                                    <a data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" height={30} /></a>
                                </div>
                                <div className="modal-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} backdrop="static" keyboard="false">
            	<Modal.Header>
            		<Modal.Title>Scan member QR code</Modal.Title>
            	</Modal.Header>
            	<Modal.Body>
            		{startScan &&
            		<QrReader style={{width:'100%'}} delay={100} facingMode={"environment"} onError={onError} onScan={onScan}/>
            		}
            	</Modal.Body>
            	<Modal.Footer>
            		<button type="button" className="btn btn-primary w-100 px-4" onClick={() => handleCloseScanner()}>Close</button>
            	</Modal.Footer>
            </Modal>

            <Modal show={showPrompt} backdrop="static">
                <Modal.Header>
                    <Modal.Title className="fw-bold">{t('label.confirm')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3"><h4>{t('msg.confirm.make.transfer')}</h4></div>
                    <Form.Group className="mb-0 pb-0">
                        <input type="password" placeholder={t('label.password')}
                            className="form-control" name='password' maxLength={10} onChange={(e) => handleChangeDialog(e)}
                            value={merchantDetails.password} required/>
                        <label className="form-label">{t('label.password')}</label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClosePrompt()}>{t('button.cancel')}</Button>
                    <Button variant="primary" onClick={() => merchantTransferEvent()} disabled={loading}>
                    	{loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.transfer')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

import { DataService } from './dataService'
require('dotenv').config()
const dataHandeler = {
  postRegistration: async (data) => {
    return DataService.post('/auth/signup', data)
  },

  postEmailValidation: async (data) => {
    return DataService.post('/auth/user_check', data)
  },

  forgotPassword: async (data) => {
    return DataService.post('/auth/member/forgetPassword', data)
  },

  changePassword: async (data) => {
    return DataService.post('/auth/member/changePassword', data)
  },

  postRegistration2: async (data) => {
    return DataService.post('/auth/member/register', data)
  },

  postUserLogin: async (data) => {
    return DataService.post('/auth/member/login', data)
  },

  postUserLogout: async () => {
    return DataService.post('/auth/member/logout')
  },

  postUserLoginByToken: async (data) => {
    return DataService.post('/auth/member/tokenLogin', data)
  },
  
  getFooterData: async () => {
	return DataService.get(`/page`);
  },

  resultPage: async (data) => {
    return DataService.get('/v1/info/results?drawDate=' + data.date)
  },
  
  getGames: async () => {
    return DataService.get('/v1/info/games')
  },
  
  getAvailGames: async () => {
    return DataService.get('/v1/info/availGames')
  },
  
  getCounters: async (gameCode) => {
    return DataService.get('/v1/info/counters?game=' + gameCode)
  },
  
  getBankList: async () => {
	return DataService.get('/v1/info/banks')  
  },
  
  getQrCodeInfo: async() => {
	return DataService.get('/v1/member/qrCode')
  },
  
  editQrCodeInfo: async(data) => {
	return DataService.post('/v1/member/editQrCode', data)
  },
  
  getMemberDownline: async() => {
	return DataService.get('/v1/member/downlines/d')
  },
  
  getMemberDownlineDetails: async(data) => {
	return DataService.get('/v1/member/downlines/d/' + data)
  },
  
  getMemberDownlinePackages: async(data) => {
	return DataService.get('/v1/member/downline/packages/' + data)
  },
  
  updateMemberDownlineRate: async(data) => {
	return DataService.post('/v1/member/updateMember/rate', data)
  },

  downlines: async () => {
    return DataService.get('/v1/member/downlines')
  },

  downlinesWithOther: async () => {
    return DataService.get('/v1/member/downlines?other=Y')
  },

  fetchSales: async (data) => {
    return DataService.get('/v1/member/kpi/details', data)
  },

  homePage: async (data) => {
    return DataService.get('/v1/member/kpi', data)
  },

  systemInfo: async () => {
    return DataService.get('/auth/system/info')
  },

  postMemberBet: async (data) => {
    return DataService.post('/v1/bet/order', data)
  },

  getMemberTicket: async (data) => {
	let orderId = data.id ? '&orderId=' + data.id : ''
    return DataService.get('/v1/bet/ticket?fromDate=' + data.fromDate + '&toDate=' + data.toDate + orderId)
  },
  
  postVoidTicket: async (value) => {
    return DataService.post('/v1/bet/voidTicket/' + value)
  },

  getPointsStatement: async (date) => {
    return DataService.get('/v1/trans/point?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&typeCode=' + date.typeCode)
  },

  pointTransfer: async (data) => {
    return DataService.post('/v1/trans/point/transfer', data)
  },

  pointCashReload: async (data, body) => {
    return DataService.post('/v1/trans/point/cashReload', data)
  },

  cashTransfer: async (data) => {
    return DataService.post('/v1/trans/cash/transfer', data)
  },

  getMemberMerchant: async (data) => {
    return DataService.get('/v1/member/merchant?type=' + data.type + '&phoneNo=' + data.phoneNo)
  },

  getMerchantStatement: async (data) => {
    return DataService.get('/v1/trans/merchant?fromDate=' + data.fromDate + '&toDate=' + data.toDate +
                        '&typeCode=' + data.typeCode + '&phoneNo=' + data.phoneNo + (data.searchMerchant ? '&searchMerchant=Y' : '') +
                        '&searchType=' + data.searchType)
  },

  getMerchantTransaction: async (data) => {
    return DataService.get('/v1/trans/merchant/transaction?fromDate=' + data.fromDate + '&toDate=' + data.toDate +
                        '&typeCode=' + data.typeCode + '&actionCode=' + data.actionCode)
  },

  merchantTransfer: async (data) => {
    return DataService.post('/v1/trans/merchant/transfer', data)
  },
  
  updateOrderRate: async(data) => {
	return DataService.post('/v1/game/updateOrderRate', data)
  },

  requestTagCode: async (data) => {
    return DataService.post('/auth/member/requestTag', data)
  },

  // Report
  getSalesStatement: async (data) => {
    return DataService.get('/v1/trans/report/sales?fromDate=' + data.fromDate + '&toDate=' + data.toDate)
  },

  getBonusStatement: async (data) => {
    return DataService.get('/v1/trans/report/bonus?fromDate=' + data.fromDate + '&toDate=' + data.toDate)
  },

  getCommissionStatement: async (data) => {
    return DataService.get('/v1/trans/report/commission?fromDate=' + data.fromDate + '&toDate=' + data.toDate)
  },

  getDebtorStatement: async (data) => {
    return DataService.get('/v1/trans/report/debtorStatement?fromDate=' + data.fromDate + '&toDate=' + data.toDate)
  },

  getMessageBox: async (data) => {
    return DataService.getMessageBox('/v1/message/member/getMessageList?mobileNumber=' + data.mobileNumber + '&fromDate=' + data.fromDate + '&toDate=' + data.toDate)
  },

  getCashPayoutDetails: async (date) => {
    return DataService.get('/v1/trans/payoutDetails?date=' + date)
  },

}

export default dataHandeler
import axios from "axios";
import { toast } from 'react-toastify';
import { Storage } from '../Storage/Storage'
const { REACT_APP_API_ENDPOINT } = process.env
const API_ENDPOINT = REACT_APP_API_ENDPOINT

let authHeader = {
    'Access-Token': Storage.get('user').accessToken,
    "Content-Type": "application/json",
    'Secret-Key': 'M3mbErsH!p=',
    'User-ID': Storage.get('user').userId
};

let authHeaderMessagebox = {
    'Access-Token': Storage.get('user').accessToken,
    "Content-Type": "application/json",
    'Secret-Key': 'M3mbErsH!p=',
    'User-ID': Storage.get('user').userId
};

const client = axios.create({
    baseURL: API_ENDPOINT,
    withCredentials: false
});

client.defaults.timeout = 10000;

class DataService {
    static get(path = "") {
        if (Storage.get('user')) {
            authHeader['User-ID'] = Storage.get('user').userId
            authHeader['Access-Token'] = Storage.get('user').accessToken
        }

        return client({
            method: "GET",
            url: path,
            headers: authHeader
        });
    }

    static post(path = "", data = {}, optionalHeader = {}) {
        if (Storage.get('user')) {
            authHeader['User-ID'] = Storage.get('user').userId
            authHeader['Access-Token'] = Storage.get('user').accessToken
        }
        return client({
            method: "POST",
            url: path,
            data,
            headers: { ...authHeader, ...optionalHeader }
        });
    }

    static patch(path = "", data = {}) {
        return client({
            method: "PATCH",
            url: path,
            data: JSON.stringify(data),
            headers: authHeader
        });
    }

    static put(path = "", data = {}) {
        return client({
            method: "PUT",
            url: path,
            data: JSON.stringify(data),
            headers: authHeader
        });
    }

    static delete(path = "") {
        return client({
            method: "DELETE",
            url: path,
            headers: authHeader
        });
    }
    
    static getMessageBox(path = "") {
        if (Storage.get('user')) {
        	authHeaderMessagebox['User-ID'] = Storage.get('user').msgBox.appName;
        	authHeaderMessagebox['Access-Token'] = Storage.get('user').msgBox.appKey;
        	authHeaderMessagebox['Secret-Key'] = Storage.get('user').msgBox.secretKey;
        }
        
        return axios.get(Storage.get('user').msgBox.url + '/api' + path, {
            headers: authHeaderMessagebox
        });
    }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;
    requestConfig.headers = { ...headers };
    return requestConfig;
});

client.interceptors.response.use(
    response => response,
    error => {
        /**
         * Do something in case the response returns an error code [3**, 4**, 5**] etc
         * For example, on token expiration retrieve a new access token, retry a failed request etc
         */
        const { response } = error;
        const originalRequest = error.config;
        let pathname = window.location.pathname;

        if (error.message && error.message === 'Network Error') {
            Storage.remove('user')
            Storage.remove('system')

            if (pathname === '/login')
                toast.error('Network Error')
            else 
                window.location.href = "/not-found"
        }

        if (response) {
            if (response.status === 500) {
                // do something here
            } else {
                return originalRequest;
            }
        }
        return Promise.reject(error);
    }
);
export { DataService };

import React, { useState, useEffect } from 'react';
import moment from "moment";
import dataHandeler from '../../services/server';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function MerchantStatementDetails(props) {
    const history = useHistory();
    const [merchantStatement, setMerchantStatement] = useState([]);
    const [data, setData] = useState({typeCode:'MERCHANT', fromDate: moment(new Date()).format('YYYY-MM-DD'), toDate: moment(new Date()).format('YYYY-MM-DD')});
    const [loading, setLoading] = useState(false);
    const [listing, setListing] = useState(true);
    const [title, setTitle] = useState('');
    let { actionCode, fromDate, toDate } = useParams()

    useEffect(() => {
        setHeader()
        fetch()
    }, [data.toDate])

    const setHeader = () => {
        switch(actionCode) {
            case 'PC':
                setTitle(t('merchant.statement.payout.claim'))
                break;
            case 'PCC':
                setTitle(t('merchant.statement.payout.comm'))
                break;
            case 'TU':
                setTitle(t('merchant.statement.topup'))
                break;
            case 'TUC':
                setTitle(t('merchant.statement.topup.comm'))
                break;
            case 'WD':
                setTitle(t('merchant.statement.withdraw'))
                break;
            case 'WDC':
                setTitle(t('merchant.statement.withdraw.comm'))
                break;
            case 'CDT':
                setTitle(t('merchant.statement.credit'))
                break;
            case 'CASH':
                setTitle(t('merchant.statement.cash'))
                break;
            default:
                setTitle('')
        }
    }

    const fetch = () => {
        data.fromDate = moment(fromDate).format('YYYY-MM-DD')
        data.toDate = moment(toDate).format('YYYY-MM-DD')

    	setLoading(true)
    	setMerchantStatement([])

        data.actionCode = actionCode
    	
        dataHandeler.getMerchantTransaction(data).then(result => {
            // console.log('###############result: ' + JSON.stringify(result))
            setMerchantStatement(result.data);
            setLoading(false);
        }).catch(error => console.log('error came after it ', error))
    }

    const onDateSelectEvent = (e) => {
    	// setData({ ...data, [e.target.name]: e.target.value })
    }

    const back = () => {
        history.push('/merchant-statements?fromDate=' + fromDate + '&toDate=' + toDate);
    }

    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="row ">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">
                            {t('merchant.statement.detail.title')} - {title}
                        </span>
		            </h1>
                    <a className="d-inline-block righticon openkeyboard btn" onClick={() => setListing(!listing)}>
                        { listing ?
                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" class="bi bi-view-stacked" viewBox="0 0 16 16">
                                <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z"/>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                            </svg>
                        }
                    </a>
                    {/* <Link className="btn btn-danger respBtn" to={'/merchant-statements'}>{t('button.back')}</Link> */}
                </div>
                <div className="col-md-12 mx-auto my-2 mobileContent ">
                    <div className="card shadow">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="mt-md-4 mt-0 col-md-8 col-12 mx-auto">
                                <div className="row px-md-5 px-2">
                                    <div className="col-md col-5 px-md-3 px-0">
                                        <div className="form-group text-align-end mb-0 form-ani">
                                            <input type="date"
                                                className="form-control text-uppercase"
                                                name="fromDate"
                                                defaultValue={moment(fromDate).format('YYYY-MM-DD')}
                                                onChange={(e) => onDateSelectEvent(e)} disabled={true} />
                                        </div>
                                    </div>
                                    <div className=" col-md-1 col-md col-2 text-dark text-center fw-bold px-md-3 px-0">{t('label.to')}</div>
                                    <div className="col-md col-5 px-md-3 px-0">
                                        <div className="form-group text-align-end mb-0 form-ani">
                                            <input type="date"
                                                className="form-control text-uppercase"
                                                name="toDate"
                                                defaultValue={moment(toDate).format('YYYY-MM-DD')}
                                            	onChange={(e) => onDateSelectEvent(e)} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 px-md-3 px-2">
                                        <div className="form-group text-align-end mb-0 pb-0">
                                            <a className="btn btn-danger w-100 px-4" onClick={() => { back() }}>{t('button.back')}</a>
                                            {/* <Link className="btn btn-danger respBtn" to={'/merchant-statements'}>{t('button.back')}</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            { !loading ?
                <div>
                    <div className="row mt-2">
                        <div className="col-12 mb-3">
                            <div className="card shadow">
                                <div className="card-body">
                                    <div className="table-responsive">
                                    { listing ?
                                        <table className="table custom_table">
                                            <tbody>
                                            	{
                                                    merchantStatement.transList && merchantStatement.transList.length > 0 ? merchantStatement.transList.map((element, key) => (
                                                        <tr className="fw-bold" key={key}>
                                                            <td>
                                                                <p className="mb-0 text-muted small">{element.formatDate}</p>
                                                            { actionCode == 'TU' ?
                                                                <p className="mb-0 text-muted small">{element.loginIdTo}</p>
                                                            :
                                                                <p className="mb-0 text-muted small">{element.createdBy}</p>
                                                            }
                                                                <p className="mb-0">{element.description}</p>
                                                            </td>
                                                            {element.merchantAmt > 0 ?
                                                            <td style={{textAlign:'right'}}>
                                                                <p className="mb-0 text-success small">{element.merchantAmt}</p>
                                                                <p className="mb-0 text-primary">{element.merchantBalance}</p> 
                                                            </td>
                                                            :
                                                            <td style={{textAlign:'right'}}>
                                                                <p className="mb-0 text-danger small">{element.merchantAmt}</p>
                                                                <p className="mb-0 text-primary">{element.merchantBalance}</p> 
                                                            </td>}
                                                        </tr>
                                                    )) : <tr className="fw-bold"><td>{t('msg.no.record.found.on.date')}</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    :
                                        <table className="table custom_table text-center whitespace w-100">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Created By</th>
                                                    <th>Description</th>
                                                    <th>Amount</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    merchantStatement.transList && merchantStatement.transList.length > 0 ? merchantStatement.transList.map((element, key) => (
                                                        <tr key={key}>
                                                            <td>{element.formatDate}</td>
                                                            <td>{element.createdBy}</td>
                                                            <td style={{textAlign: 'left'}}> {element.description} </td>
                                                            {element.merchantAmt > 0 ? <td className="text-success" style={{textAlign:'right'}}> {element.merchantAmt} </td> : <td className="text-danger" style={{textAlign:'right'}}> {element.merchantAmt} </td>}
                                                            <td className="text-primary" style={{textAlign:'right'}}>{element.merchantBalance}</td>
                                                        </tr>
                                                    )): <tr className="fw-bold"><td colSpan={4}>{t('msg.no.record.found.on.date')}</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <div className="loader">
                    <div className="triple-spinner"></div>
                </div>
            }
        </div>
    )
}

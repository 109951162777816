import React, {useState} from 'react';
import OtpInput from 'react-otp-input';

export default function UiCalculator({
	modalNumber, setModalNumber, luckyNumberCount, setLuckyNumberCount, onKeyChange, onSaveEvent,
    showModal, setActiveBetCounter
}) {

    let btn = [1, 2, 3, 4, 5, 6, 7, 8, 9, '0-9', 0, 'svg', '+', 'save'];
    const digits = [3, 4, 5, 6];
    
    const count = () => {
        switch (showModal.act) {
            case 'num':
                return luckyNumberCount;
            case 'num1':
                return luckyNumberCount;
            case 'big':
                return 6;
            case 'small':
                return 6;
            case 'four':
                return 6;
            case 'aAct':
                return 6;
            case 'acAct':
                return 6;
        }
    }

    const removeCount = () => {
        let number = modalNumber.substring(0, modalNumber.length - 1);
        setModalNumber(number);
    }
    
    const isDisabled = () => {
        switch (showModal.act) {
            case 'num':
                return luckyNumberCount;
            case 'num1':
                return luckyNumberCount;
            case 'big':
                return modalNumber.length < 6 ? modalNumber.length : 6;
            case 'small':
                return modalNumber.length < 6 ? modalNumber.length : 6;
            case 'four':
                return modalNumber.length < 6 ? modalNumber.length : 6;
            case 'aAct':
                return modalNumber.length < 6 ? modalNumber.length : 6;
            case 'acAct':
                return modalNumber.length < 6 ? modalNumber.length : 6;
        }
    }

    return (
        <div className='modal fade' id="addnumber" tabIndex={-1} role="dialog" aria-labelledby="addnumberLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    {(showModal.act === 'num' || showModal.act === 'num1') &&
                        <div className="text-end p-4 d-flex align-items-center">
                            <h3 className="text-start flex-grow-1 px-3">{showModal.titel}</h3>
                            <a data-bs-dismiss="modal" aria-label="Close"><img src="assets/images/close.svg" height={30} /></a>
                        </div>
                    }
                    <div className="modal-body">
                        {(showModal.act === 'num' || showModal.act === 'num1')
                        	? <div className="flex justify-center otpbox" id="OTPInput">
                                <OtpInput
                                    value={modalNumber}
                                    numInputs={count()}
                                    separator={<span>-</span>}
                                />
                            </div>
                            : <div className="border p-3 d-flex mb-3">
                                <p className="text-danger">{showModal.titel}</p>
                                <div className="flex justify-center betType" id="OTPInput">
                                    <h1>{modalNumber}</h1>
                                </div>
                            </div>
                        }
                        <br />
                        {
                            showModal.titel === 'Key in your lucky number' &&
                            <div className="text-center mb-4">
                                <div className="btn-group w-100 px-3" role="group" aria-label="Basic example">
                                    {digits.map((item, key) => (
                                        <React.Fragment key={key}>
                                            <input
                                                type="radio"
                                                className='btn-check'
                                                name="options-outlined"
                                                id={`opt${key + 1}`}
                                                autoComplete="off"
                                                defaultChecked={luckyNumberCount === item ? true : false} />
                                            {item === 5 ? <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => (setLuckyNumberCount(item), setModalNumber(''), setActiveBetCounter(['T']))} htmlFor="opt1">{item}D</label>
                                            : <label className="btn btn-outline-primary btn-lg tabbtn" onClick={() => (setLuckyNumberCount(item), setModalNumber(''))} htmlFor="opt1">{item}D</label> }
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        }
                        <div className="keyboard_ row px-2">
                            {btn.map((list, key) => (
                                <>
                                    {list === 'save' ?
                                        <div className="col-8 px-1 mb-2" key={key}>
                                            <button
                                                className="btn btn-primary py-3 px-0 w-100 fw-bold"
                                                disabled={modalNumber && modalNumber.length === isDisabled() ? false : true}
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => onSaveEvent()}>Save</button>
                                        </div>
                                        :
                                        <div className="col-4 mb-2 px-1" key={key}>
                                            {list === 'svg'
                                                ?
                                                <button className="btn btn-success py-3 px-0 w-100 fw-bold" onClick={() => { removeCount() }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z" />
                                                    </svg>
                                                </button>
                                                : list === '+'
                                                    ?
                                                    <button
                                                        className='btn py-3 w-100 fw-bold btn-danger'
                                                        data-bs-dismiss={(showModal.act === 'num' || showModal.act === 'num1') ? '' : 'modal'}
                                                        aria-label={(showModal.act === 'num' || showModal.act === 'num1') ? '' : 'Close'}
                                                        disabled={modalNumber && modalNumber.length === isDisabled() ? false : true}
                                                        onClick={() => onSaveEvent((showModal.act === 'num' || showModal.act === 'num1') ? '+' : 'All')}
                                                    >{(showModal.act === 'num' || showModal.act === 'num1') ? '+' : 'All'}</button>
                                                    :
                                                    <button className="btn py-3 w-100 fw-bold btn-secondary"
                                                    	disabled={list === '0-9' && (luckyNumberCount === 5 || luckyNumberCount === 6) && (showModal.act === 'num' || showModal.act === 'num1')}
                                                        onClick={() => (onKeyChange((showModal.act === 'num' || showModal.act === 'num1')
                                                            ? list === '0-9' ? '#' : list
                                                            : (list === '0-9') ? '.' : list
                                                        )
                                                        )}
                                                    >{(showModal.act === 'num' || showModal.act === 'num1')
                                                        ? list
                                                        : (list === '0-9') ? '.' : list
                                                        }
                                                    </button>
                                            }
                                        </div>
                                    }
                                </>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

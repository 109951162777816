import React, { useState, useEffect } from 'react'
import dataHandeler from '../../services/server'
import { Link } from 'react-router-dom'

export function Downline() {
	const [memberDownline, setMemberDownline] = useState([])
	
	useEffect(() => {
        fetch()
    }, [])
	
	const fetch = () => {
        dataHandeler.getMemberDownline().then(result => {
        	setMemberDownline(result.data)
        }).catch(error => console.log('error came after it ', error))
    }
	
	return (
		<div className="container">
			<div className="row">
				<div className="col-12 web-nav headingSection d-flex align-items-center">
		            <h1 className="flex-grow-1">
		                <span className="pageheading">Downlines</span>
		            </h1>
		        </div>
		    </div>
		    {memberDownline.memberInfo ?
		    <div>
			    <div className="row px-2">
                    <div className="col-12 my-2 text-align-start px-2">
		                <div className="card shadow bg-primary h-100">
		                    <div className="d-flex justify-content-center text-center align-items-center">
		                    	<div className="px-md-3 text-white py-3 row w-100">
                                    <div className="col-4">
                                        <h1>{memberDownline.memberInfo.directDL}</h1>
                                        <p>Direct Downlines</p>
                                    </div>
                                    <div className="col-4">
                                        <h1>{memberDownline.memberInfo.totalDL}</h1>
                                        <p>Total</p>
                                    </div>
                                    <div className="col-4">
                                        <h1>{memberDownline.memberInfo.totalLvl}</h1>
                                        <p>Level</p>
                                    </div>
		                    	</div>
		                    </div>
		                </div>
		            </div>
		            {/* <div className="col-5 my-2 text-align-start px-2">
		                <div className="card shadow bg-primary h-100">
		                    <div className="card-body p-md-3 p-1 text-white d-flex flex-column justify-content-center">
		                        <div className="user pe-3"><img src="assets/images/Downline.png" height="40"/></div>
		                    </div>
		                    <div className="d-flex justify-content-center text-center align-items-center">
		                    	<div className="px-md-3 text-white py-3 row w-100">
		                    		<h1>{memberDownline.memberInfo.directDL}</h1>
		                    		<p>Direct Downlines</p>
		                    	</div>
		                    </div>
		                </div>
		            </div>
		            <div className="col-7 my-2 text-align-start px-2">
			            <div className="card shadow bg-danger h-100">
		                    <div className="card-body p-md-3 p-1 text-white d-flex flex-column justify-content-center">
		                    	<div className="d-flex">
			                        <div className="user pe-3">
			                        	<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
			                        		<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
			                        		<path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"></path>
			                        	</svg>
			                        </div>
			                        <div className="flex-grow-1"><span className="text-white">+60123456789</span></div>
		                        </div>
		                    </div>
		                    <div className="d-flex justify-content-center text-center align-items-center">
		                    	<div className="px-md-3 text-white py-3 row w-100">
		                    		<div className="col-6">
			                    		<h1>{memberDownline.memberInfo.totalDL}</h1>
			                    		<p>Total</p>
		                    		</div>
		                    		<div className="col-6">
			                    		<h1>{memberDownline.memberInfo.totalLvl}</h1>
			                    		<p>Level</p>
		                    		</div>
		                    	</div>
		                    </div>
		                </div>
		            </div> */}
		        </div>
		        <div className="row px-2">
		        	<div className="col-12 headingSection">
		        		<h5><span className="pageheading">Downlines</span></h5>
		        	</div>
		        	<div className="col-12 mb-3">
	                    <div className="card shadow">
	                        <div className="card-body">
	                            <div className="table-responsive">
	                                <table className="table custom_table">
	                                    <tbody>
	                                    {
	                                    	memberDownline.downlineList && memberDownline.downlineList.length > 0 ? memberDownline.downlineList.map(element => (
                                                <tr className="fw-bold">
                                                	<td>
                                                		<Link to={{pathname:'/view-downline/' + element.uuid}} style={{textDecoration:'none'}}>
	                                                		<div className="d-flex justify-content-between">
	                                                			<div>
		                                                			<p className="mb-0">{element.name}</p>
		                                                            <p className="mb-0 text-muted medium">{element.uuid}</p>
	                                                			</div>
	                                                			<div>
		                                                			<p className="mb-0 text-muted medium">Points: <span className="text-success">{element.point}</span></p>
		                                                        	<p className="mb-0 text-muted medium">Sales: <span className="text-success">{element.sales}</span></p>
	                                                			</div>
	                                                			<div>
		                                                			<p className="mb-0 text-muted medium">Downlines: <span className="text-success">{element.totalDL}</span></p>
		    	                                                	<p className="mb-0 text-muted medium">Level: <span className="text-success">{element.totalLvl}</span></p>
	                                                			</div>
	                                                		</div>
                                                		</Link>
                                                	</td>
                                                </tr>
                                            )) : <tr className="fw-bold"><td>No downline found!</td></tr>
                                        }
		        						</tbody>
		        					</table>
		        				</div>
		        			</div>
		        		</div>
		        	</div>
		        </div>
		    </div>
		    : <div className="loader"><div className="triple-spinner"></div></div>}
		</div>
	)
}
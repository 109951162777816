import React, { Component } from 'react'
import Routes from '../Routes/index'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class App extends Component {
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Routes/>
                <ToastContainer />
            </BrowserRouter>
        )
    }
};

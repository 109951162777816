import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import dataHandeler from '../../services/server'
import { toast } from 'react-toastify';
import { Storage } from '../../Storage/Storage';
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

export function Login(props) {

    const [user, setUser] = useState({code: '+60'})
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false)
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
    	let params = queryString.parse(props.location.search)
    	if (params.loginToken) {
            var userData = { token: params.loginToken}
            dataHandeler.postUserLoginByToken(userData).then(result => {
                if (result.data.status === 0) {
                    Storage.set('user', result.data)
                    toast.success('Login Succesfully')
                    props.history.push('/')
                } else {
                    toast.error('Login failed please check your creadentials')
                    props.history.push('/')
                }
            }).catch(error => {
                toast.error(error)
            })
    	} else {
            fetchData()
        }
    }, [])

    const fetchData = () => {
        setLoadingData(true)
        dataHandeler.systemInfo().then(result => {
            Storage.set('system', result.data)
            setData(result.data)
            setLoadingData(false)
        }).catch(error => console.log(error))
    }

    const handelChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    
    const handelSubmit = (e) => {
        e.preventDefault()
        var userData = {}
        if (!user.code) {
            userData = { email: user.email, password: user.password }

        } else {
            userData = { loginId: user.code + user.loginId, password: user.password }

        }

        if (!user.loginId || user.loginId === '') {
    		toast.error(t('msg.phone.number.required'));
    		return;
    	}

        if (!user.password || user.password === '') {
    		toast.error(t('msg.password.required'));
    		return;
    	}

        toast.dismiss();
        setLoading(true);

        dataHandeler.postUserLogin(userData).then(result => {
            if (result.data.status === 0) {
                Storage.set('user', result.data)
                toast.success('Login Succesfully')
                props.history.push('/')
            } else {
                toast.error('Login failed please check your creadentials')
                props.history.push('/')
            }
            setLoading(false);
        }).catch(error => {
        	console.log(error);
            if (error.message && error.message != 'Network Error') toast.error(error.message);
            setLoading(false);
        })
    }

    const { i18n } = useTranslation();

    const handelChangeLang = (value) => {
        i18n.changeLanguage(value)
        Storage.set('localization', value)
    }

    const { t } = useTranslation()

    return (
        <>
            {/* <Helmet>
              <script src="/assets/js/custom.js" async type="text/javascript" />
            </Helmet> */}
            <div className="container-fluid login-bg">
                <div className="loginbox col-12 mx-auto ">
                    <div className="shadow p-4 bg-white p-5">
                        <div className="row">
                            <div className="col">
                                <h1 className="display-6 mb-5">{t('login.welcome')}</h1>
                            </div>
                            <div className="d-flex col px-0">
                                <div className="flex-grow-1 px-1">
                                    <p className="fw-bold" style={{textAlign: 'right'}}>{t('login.customer')}<br/>{t('login.service')}</p>
                                </div>
                                <div className="user">
                                    { loadingData ? <i className="spinner-border" style={{width:'45px', height:'45px'}}></i> :
                                    <a href={'https://wa.me/' + data.whatsappPhoneNo}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="#25D366">
                                            <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.86 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z"/>
                                        </svg>
                                    </a>
                                    }
                                </div>
                            </div>
                        </div>
                        <form onSubmit={(e) => handelSubmit(e)}>
                            <div id="login_mobile" style={{ display: 'block' }}>
                                <div className="row">
                                    {/* <div className="col" style={{ minWidth: '110px', maxWidth: '150px' }}>
                                        <div className="form-group text-left">
                                            <label htmlFor="lookingfor" className="floating-label">Country code</label>
                                            <div className>
                                                <select className=" form-control">
                                                    <option selected />
                                                    <option value={1}>MY +60</option>
                                                    <option value={2}>MY +11</option>
                                                    <option value={3}>MY +61</option>
                                                    
                                                    <option>+91</option>
	                                                <option>+61</option>
	                                                <option>+60</option>
	                                                <option>+93</option>
	                                                <option>+0</option>
	                                                <option>+01</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-3">
                                        <div className="form-group mb-3 mt-4">
                                            <select className="form-control" name='code' onChange={(e) => { handelChange(e) }} >
                                                <option value="+60">+60</option>
                                            </select>
                                            <label className="form-label"> + {t('login.code')}</label>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <div className="form-group ">
                                            <label htmlFor="companyname" className="floating-label">Phone Number</label>
                                            <div className>
                                                <input className="form-control" placeholder="Phone Number" name='loginId' type="text" onChange={(e) => handelChange(e)} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-9">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="tel" placeholder="Phone Number" className="form-control" name='loginId' onChange={(e) => handelChange(e)} />
                                            <label className="form-label">{t('login.phone.number')}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder="Password" className="form-control" name='password' onChange={(e) => handelChange(e)} />
                                            <label className="form-label">{t('login.password')}</label>
                                        </div>
                                    </div>

                                    {/* <div className="col-12">
                                        <div className="form-group ">
                                            <label htmlFor="companyname" className="floating-label">Password</label>
                                            <div className>
                                                <input className="form-control" placeholder=" " name='password' type="password" onChange={(e) => handelChange(e)} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 mb-3 col-12 text-sm-start text-center">
                                    	<Link className="text-dark fw-bold text-decoration-none" to="/forgot">{t('button.forgot.password')}?</Link>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group pb-0">
                                            <button className="btn btn-primary w-100 fw-bold py-3" disabled={loading}>
                                                {loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.login')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="login_email" style={{ display: 'none' }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="email" placeholder="Email" className="form-control" name='email' onChange={(e) => handelChange(e)} />
                                            <label className="form-label">Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder="Password" className="form-control" name='password' onChange={(e) => handelChange(e)} />
                                            <label className="form-label">Password</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-3 col-12 text-sm-start text-center">
                                        <Link className="text-dark fw-bold text-decoration-none" to="/forgot">{t('button.forgot.password')}?</Link>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group pb-0">
                                            <button className="btn btn-primary w-100 fw-bold py-3" disabled={loading}>
                                                {loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.login')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center mobilebtn">
                                    <a onClick={() => handelChangeLang('en')} className="text-dark fw-bold text-decoration-none btn">English</a>
                                    /
                                    <a onClick={() => handelChangeLang('cn')} className="text-dark fw-bold text-decoration-none btn">中文</a>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-12 text-center mobilebtn">
                                    <a onClick={() => mobilelogin()} className="text-dark fw-bold text-decoration-none btn">Using Mobile Number</a>
                                </div>
                                <div className="col-12 text-center emailbtn">
                                    <a onClick={() => emaillogin()} className="text-dark fw-bold text-decoration-none btn">Using Email/Password</a>
                                </div>
                            </div> */}
                        </form>
                    </div>

                </div>
                {/* <script src={"../../../public/assets/js/custom.js?v="+new Date()}></script> */}
            </div>
        </>
    )
}

const mobilelogin = () => {
    $('.emailbtn').show();
    $('.mobilebtn').hide();
    $('#login_mobile').toggle();
    $('#login_email').toggle();
}

const emaillogin = () => {
    $('.emailbtn').hide();
    $('.mobilebtn').show();
    $('#login_mobile').toggle();
    $('#login_email').toggle();
}

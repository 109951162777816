import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import dataHandeler from '../../services/server'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Button } from 'react-bootstrap'

export function CashTransfer() {
    const { t } = useTranslation();
	const history = useHistory();
	const [pointsStatement, setPointsStatement] = useState([]);
    const [merchantStatement, setMerchantStatement] = useState([]);
    const [downlinesList, setDownlinesList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [showOptions, setShowOptions] = useState({downline:false, bank:false, convert:false, merchant: false});
    const [transDetails, setTransDetails] = useState({amount:'', minAmt:60});
    const [data, setData] = useState({ type:'MERCHANT', phoneNo:''});
    const [loading, setLoading] = useState(false);
    const amountInputRef = useRef(null);
    let { id } = useParams();
    const [isTransactionClosed, setIsTransactionClosed] = useState(false);
    const [isBankClosed, setIsBankClosed] = useState(true);
    const [showPrompt, setShowPrompt] = useState(false);

    useEffect(() => {
        fetch();
        getDownlines();
    }, []);

    const fetch = () => {
        dataHandeler.getPointsStatement({ typeCode:'CASH' }).then(result => {
            setPointsStatement(result.data);
            setIsTransactionClosed(result.data.isTransactionClosed);
            setIsBankClosed(result.data.isBankClosed);
            if (result.data.isTransactionClosed) {
            	toast.error('System temporary close shop, please try again later!');
            }
        }).catch(error => console.log('error came after it ', error));
    }

    const getDownlines = () => {
        dataHandeler.downlinesWithOther().then((res) => {
            if (res.data.status === 0) {
                setDownlinesList(res.data.downlineList);
                setBankList(res.data.bankList);
            }

            if (id && id != '') {
                transDetails.transType = 'DOWNLINE';
                setShowOptions({downline:true, bank:false, convert:false, merchant: false});
    		    transDetails.downline = id;
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    
    const addAmount = (e) => {
        let reg = /^\d+$/
        if (reg.test(e.target.value) || e.target.value === '') {
        	setTransDetails({...transDetails, amount:e.target.value});
        }
    }
    
    const addBankDetails = (e, type) => {
    	if (type === 'FULLNAME') {
    		setTransDetails({...transDetails, accountName:e.target.value});
    	} else {
    		let reg = /^\d+$/
	        if (reg.test(e.target.value) || e.target.value === '') {
	        	setTransDetails({...transDetails, accountNumber:e.target.value});
	        }
	    }
    }

    const onSelectDownline = (e) => {
    	transDetails.downline = e.target.value;
    }
    
    const onSelectBank = (e) => {
    	transDetails.bank = e.target.value;
    }
    
    const onSelectType = (e) => {
    	let type = e.target.value;
    	transDetails.transType = type;
    	
    	if (type === 'DOWNLINE') {
    		setShowOptions({downline:true, bank:false, convert:false, merchant: false});
    		transDetails.downline = '';
    		
    	} else if (type === 'BANK') {
    		setShowOptions({downline:false, bank:true, convert:false, merchant: false});
    		transDetails.bank = '';
    		transDetails.accountName = '';
    		transDetails.accountNumber = '';
    		
    	} else if (type === 'CONVERT') {
            setShowOptions({downline:false, bank:false, convert:true, merchant: false});
        } else if (type === 'MERCHANT') {
            transDetails.idMember = '';
            setShowOptions({downline:false, bank:false, convert:false, merchant: true});
        } else setShowOptions({downline:false, bank:false, convert:false, merchant: false});
    }

    const cashTransferEvent = () => {
        if (!transDetails.password || transDetails.password === '') {
    		toast.error('Password is required');
    		return;
    	}

    	if (!transDetails.transType || transDetails.transType === '') {
    		toast.error('Please select transfer type');
    		return;
    	}

    	if (transDetails.amount == 0 || transDetails.amount === '') {
    		toast.error('Please fill your amount');
    		return;
    	}
        
        if (transDetails.amount > pointsStatement.cash) {
            toast.error('Insufficient Credits');
            return
        }
    	
    	if (showOptions.downline) {
    		if (!transDetails.downline) {
                toast.error('Please select downline');
                return
            }
    	}
    	
    	if (showOptions.bank) {
    		if (transDetails.amount < transDetails.minAmt) {
    			toast.error('Minimum amount is ' + transDetails.minAmt);
                return
    		}
    		if (!transDetails.bank || transDetails.bank === '') {
    			toast.error('Please select bank');
                return
    		}
    		if (!transDetails.accountName || transDetails.accountName === '') {
    			toast.error('Please fill your full name');
                return
    		}
    		if (!transDetails.accountNumber || transDetails.accountNumber === '') {
    			toast.error('Please fill you bank account number');
                return
    		}
    	}

        if (showOptions.merchant) {
            if (merchantStatement.member) {
                transDetails.idMember = merchantStatement.member.uuid
            } else {
                toast.error('Please search the merchant phone no. for transfer')
                return;
            }
        }

        setLoading(true)
        dataHandeler.cashTransfer(transDetails).then(res => {
            if (res.data.status === 0) {
                history.push('/cash-statements')
                if (showOptions.bank) {
                    toast.success('Cash withdraw request already sent to company, the process will be made in 24 hours')
                } else {
                    toast.success('Transfer successfully, please check your statement.')
                }
            } else toast.error(res.data.message)
            setLoading(false)
        })
    }

    const handleShowPrompt = () => setShowPrompt(true);
    const handleClosePrompt = () => setShowPrompt(false);

    const handleChangeDialog = (e) => {
        setTransDetails({ ...transDetails, [e.target.name]: e.target.value });
    };

    const onSearchChange = (e) => {
        setData({ ...data, phoneNo: e.target.value })
    };

    const searchMemberEvent = () => {
    	setLoading(true)
    	setMerchantStatement([])

        dataHandeler.getMemberMerchant(data).then(result => {
            setMerchantStatement(result.data);
        	if (!result.data.member) toast.error("Invalid merchant!");
        	else amountInputRef.current.select();
            setLoading(false)
        }).catch(error => console.log('error came after it ', error))
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('cash.transfer.title')}</span>
		            </h1>
		            <Link className="btn btn-danger respBtn" to="/cash-statements">{t('button.cancel')}</Link>
	            </div>
            </div>
            <div className="row px-2 mobileContent">
                <div className="col-12 my-2 text-align-start px-2">
                    <div className="card shadow bg-primary h-100">
                        <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
                            <small>{t('cash.transfer.curr.bal')}</small>
                            <h1 className>{pointsStatement.cash} <span style={{fontSize:'15px'}}></span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mx-auto mt-3">
                    <div className="card shadow mb-4">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="col-ms-8 col-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12 md-3 mt-4">
                                        <div className="form-group">
                                            <select required className="form-control" onChange={(e) => onSelectType(e)}>
                                                <option value="" selected></option>
                                                <option value="UPLINE">{t('cash.transfer.upline')}</option>
                                                {/* <option value="DOWNLINE" selected={id ? true : false}>{t('cash.transfer.downline')}</option> */}
                                                { !isBankClosed ? <option value="BANK">{t('cash.transfer.bank')}</option> : ''}
                                                <option value="CONVERT">{t('cash.transfer.convert.point')}</option>
                                                <option value="MERCHANT">{t('cash.transfer.merchant')}</option>
                                                {/*downlinesList.map((list, key) => (
                                                    <option key={key} value={list.uuid} >{list.name}</option>
                                                ))*/}
                                            </select>
                                            <label className="form-label">{t('cash.transfer.transfer.to')}</label>
                                        </div>
                                    </div>
                                    { showOptions.downline ?
                                    	<div className="col-md-12 md-3">
                                    		<div className="form-group">
		                                    	<select required className="form-control" onChange={(e) => onSelectDownline(e)}>
				                                    <option value="" selected></option>
				                                    {downlinesList.map((list, key) => (
				                                        <option key={key} value={list.uuid} selected={id === list.uuid ? true : false}>{list.name}</option>
				                                    ))}
				                                </select><label className="form-label">{t('cash.transfer.downline')}</label>
				                         </div></div> : ''
                                    }
                                    { showOptions.merchant ?
                                        <>
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <input type="tel" placeholder={t('cash.transfer.merchant.phone.no')}
                                                    className="form-control" name='phoneNo' onChange={(e) => { onSearchChange(e) }} />
                                                <label className="form-label">{t('cash.transfer.merchant.phone.no')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <a className="btn btn-primary w-100 px-4" onClick={() => { searchMemberEvent() }} disabled={loading}>
                                                    {loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.search')}
                                                </a>
                                            </div>
                                        </div>
                                        </> : ''
                                    }
                                    { showOptions.merchant && merchantStatement.member ?
                                        <div className="col-md-12 md-3">
                                            <div className="form-group">
                                                <table className="table custom_table text-center whitespace w-100">
                                                    <tbody className="fw-bold">
                                                        {/* <tr>
                                                            <td width={100} className="text-start" style={{border:0}}>Member Code</td>
                                                            <td width={40} style={{border:0}}>-</td>
                                                            <td className="text-start" style={{border:0}}>{merchantStatement.member.uuid}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td width={100} className="text-start" style={{border:0}}>Name</td>
                                                            <td width={40} style={{border:0}}>-</td>
                                                            <td className="text-start" style={{border:0}}>{merchantStatement.member.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={100} className="text-start" style={{border:0}}>Phone No.</td>
                                                            <td width={40} style={{border:0}}>-</td>
                                                            <td className="text-start" style={{border:0}}>{merchantStatement.member.contact}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                    <div className="col-md-12 md-3">
                                        <div className="form-group">
                                            <input type="text"
                                                onChange={(e) => { addAmount(e) }}
                                                placeholder="{t('cash.transfer.amount')}"
                                                className="form-control"
                                                value={transDetails.amount}
                                                ref={amountInputRef} />
                                            <label className="form-label">
                                                {t('cash.transfer.amount')} {showOptions.bank && <span>({t('cash.transfer.min')}{transDetails.minAmt})</span>}
                                            </label>
                                        </div>
                                    </div>
                                    { showOptions.bank ?
                                    	<>
	                                    	<div className="col-md-12">
			                                    <div className="form-group">
				                                    <select required className="form-control" onChange={(e) => onSelectBank(e)}>
					                                    <option value="" selected></option>
					                                    {bankList.map((list, key) => (
					                                        <option key={key} value={list.code}>{list.description}</option>
					                                    ))}
					                                </select><label className="form-label">{t('cash.transfer.bank.name')}</label>
				                                </div>
	                                    	</div>
	                                    	<div className="col-md-12">
			                                    <div className="form-group">
				                                    <input type="text"
		                                                onChange={(e) => { addBankDetails(e, 'FULLNAME') }}
		                                                placeholder="{t('cash.transfer.full.name')}"
		                                                className="form-control"
		                                                value={transDetails.accountName} />
			                                    	<label className="form-label">{t('cash.transfer.full.name')}</label>
				                                </div>
	                                    	</div>
	                                    	<div className="col-md-12">
			                                    <div className="form-group">
				                                    <input type="text"
		                                                onChange={(e) => { addBankDetails(e, 'BANKNUMBER') }}
		                                                placeholder="{t('cash.transfer.bank.acct.no')}"
		                                                className="form-control"
		                                                value={transDetails.accountNumber} />
			                                    	<label className="form-label">{t('cash.transfer.bank.acct.no')}</label>
				                                </div>
	                                    	</div>
                                    	</> : ''
                                    }
                                    {<div className="col-md-12">
                                    	<div className="form-group">
                                    		{transDetails.amount > pointsStatement.cash || (showOptions.bank && transDetails.amount < transDetails.minAmt) || isTransactionClosed ? 
                                    			<button className="btn btn-primary w-100" disabled="true">
                                    				{transDetails.amount > pointsStatement.cash ? t('cash.transfer.insufficient.credits') : t('cash.transfer.amount.min') + transDetails.minAmt}
                                    			</button>
                                    			: <button type="button" className="btn btn-primary w-100" onClick={() => { handleShowPrompt() }} disabled={loading}>
                                    				{loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.transfer')}
                                    			  </button>
                                    		}
                                    	</div>
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal fade" id="keyboard_popup" tabIndex={-1} aria-labelledby="keyboard_popupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="text-end p-4 d-flex align-items-center">
                                    <h3 className="text-start text-white flex-grow-1 px-3">Keyboard</h3>
                                    <a data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" height={30} /></a>
                                </div>
                                <div className="modal-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showPrompt} backdrop="static">
                <Modal.Header>
                    <Modal.Title className="fw-bold">{t('label.confirm')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3"><h4>{t('msg.confirm.make.transfer')}</h4></div>
                    <Form.Group className="mb-0 pb-0">
                        <input type="password" placeholder={t('label.password')}
                            className="form-control" name='password' maxLength={10} onChange={(e) => handleChangeDialog(e)}
                            value={transDetails.password} required/>
                        <label className="form-label">{t('label.password')}</label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClosePrompt()}>{t('button.cancel')}</Button>
                    <Button variant="primary" onClick={() => cashTransferEvent()} disabled={loading}>
                    	{loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('button.transfer')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

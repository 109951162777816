import React from 'react'

export function Transfer(props) {
  return (
    <div className="container ">
      <div className="row">
        <div className="col-12 headingSection mobileNav d-flex align-items-center">
          <a onClick={() => props.history.goBack()} className="d-inline-block leftIcon text-white">
            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
          </a>
          <h1 className="flex-grow-1">
            <span className="pageheading">Points Transfer</span>
          </h1>
          <a className="btn btn-primary respBtn" href="point_statements.html">Transfer</a> &nbsp;&nbsp;
            <a className="btn btn-danger respBtn">Cancel</a>
          <a href="point_statements.html" className="d-inline-block righticon message_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="currentColor" className="bi bi-envelope-open" viewBox="0 0 16 16">
              <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.818l5.724 3.465L8 8.917l1.276.766L15 6.218V5.4a1 1 0 0 0-.53-.882l-6-3.2zM15 7.388l-4.754 2.877L15 13.117v-5.73zm-.035 6.874L8 10.083l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738zM1 13.117l4.754-2.852L1 7.387v5.73zM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2z" />
            </svg>
          </a>
        </div>
        {/* <div class="col-12 headingSection d-flex align-items-center">
                    <h1 class="flex-grow-1">Points Transfer</h1>
                    <a class="btn btn-primary" href="point_statements.html">Transfer</a> &nbsp;&nbsp;
                    <a class="btn btn-danger">Cancel</a>
                </div> */}
      </div>
      <div className="row px-2 mobileContent">
        <div className="col-7 my-2 text-align-start px-2">
          <div className="card shadow bg-primary h-100">
            <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
              <small>Current Point Balance</small>
              <h1 className>46.8</h1>
            </div>
          </div>
        </div>
        <div className="col-5 my-2 text-center px-2">
          <div className="card shadow mb-4 h-100 bg-danger">
            <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
              <small className="text-center">Online / ATM</small>
              <h5 className="text-center sm-h6 fw-bold">10am-7pm</h5>
            </div>
            <hr className="my-0 text-white" />
            <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
              <small className="text-center">Online / ATM</small>
              <h5 className="text-center sm-h6 fw-bold">10am-7pm</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* <hr class="my-3"> */}
        <div className="col-md-12 mx-auto mt-3">
          <div className="card shadow mb-4">
            <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
              <form className="mt-4 col-ms-8 col-10 mx-auto">
                <div className="row">
                  <div className="col-12 mb-3 pb-3">
                    <div className="form-group text-align-end form-ani">
                      <label htmlFor="companyname" className="floating-label" style={{ left: 0 }}>Transfer
                          to</label>
                      <div className>
                        <select className="custom-select form-control" style={{ visibility: 'hidden' }}>
                          <option />
                          <option>Upline</option>
                          <option>Downline</option>
                          <option>Bank</option>
                          <option>Convert to Points</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group text-align-end form-ani">
                      <label htmlFor="companyname" className="floating-label" style={{ left: 0 }}>Your
                          Downline</label>
                      <div className>
                        <input className="form-control" placeholder=" " type="select" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group text-align-end form-ani">
                      <label htmlFor="companyname" className="floating-label" style={{ left: 0 }}>Amount
                          RM</label>
                      <div className>
                        <input className="form-control" placeholder=" " />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Modal */}
          <div className="modal fade" id="keyboard_popup" tabIndex={-1} aria-labelledby="keyboard_popupLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                {/* <div class="modal-header">
                  <h5 class="modal-title" id="keyboard_popupLabel">Modal title</h5>
                  
                  </div> */}
                <div className="text-end p-4 d-flex align-items-center">
                  <h3 className="text-start text-white flex-grow-1 px-3">Keyboard</h3>
                  <a data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" height={30} /></a>
                </div>
                <div className="modal-body">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

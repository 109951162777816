import React from 'react'
import { Route  } from "react-router-dom";
import { Storage } from '../Storage/Storage'
import Public from './Public'

const startsWithCapital = ([...word]) => {
    let path = [ ]
    word.map((char , key) => {
        if(/[A-Z]/.test(char)){
            if(key > 0){
                path.push('-')
                path.push(char.toLowerCase())
            }else{
                path.push(char.toLowerCase())
            }
        }else{
            path.push(char)
        }
    })
    return path.join("")
}

const getkey = (Pages) => {
    let arry = []
    let userData = Storage.get('user')

    for(let key in Pages){
    	if (Object.hasOwnProperty.call(Pages ,key)) {
            if (key === 'Home'){
                arry.push(<Route path={'/'} exact component={Pages[key]} />)
                arry.push(<Route path={'/'+ startsWithCapital(key)} exact component={Pages[key]} />)
            } else if (key === 'ViewDownline' || key === 'EditDownline') {
            	arry.push(<Route path={'/'+ startsWithCapital(key) + "/:id"} exact component={Pages[key]} />)
            } else if (key === 'Game') {
            	arry.push(<Route path={'/'+ startsWithCapital(key) + "/:gameCode"} exact component={Pages[key]} />)
            } else if (key === 'MessageBox') {
            	arry.push(<Route path={'/'+ startsWithCapital(key) + "/:gameCode"} exact component={Pages[key]} />)
            } else if (key === 'SalesOrder') {
            	arry.push(<Route path={'/'+ startsWithCapital(key)} exact component={Pages[key]} />)
            	arry.push(<Route path={'/'+ startsWithCapital(key) + '/:id'} exact component={Pages[key]} />)
            } else if (key === 'PointTransfer') {
            	arry.push(<Route path={'/'+ startsWithCapital(key)} exact component={Pages[key]} />)
            	arry.push(<Route path={'/'+ startsWithCapital(key) + '/:id'} exact component={Pages[key]} />)
            } else if (key === 'CashTransfer') {
                arry.push(<Route path={'/'+ startsWithCapital(key)} exact component={Pages[key]} />)
                arry.push(<Route path={'/'+ startsWithCapital(key) + '/:id'} exact component={Pages[key]} />)
            } if ((key === 'MerchantStatements' || key === 'MerchantTransfer' || key === 'MerchantStatementDetails') && userData.isMerchant != 'Y'){
                arry.push(<Route path={'/'+ startsWithCapital('Home')} exact component={Pages[key]} />)
            } else if (key === 'MerchantStatementDetails') {
                arry.push(<Route path={'/'+ startsWithCapital(key) + '/:actionCode/:fromDate/:toDate'} exact component={Pages[key]} />)
            } else {
                arry.push(<Route path={'/'+ startsWithCapital(key)} exact component={Pages[key]} />)
            }
        }
    }
    return arry
}

export default function RouteFunction(Pages ,isthis) {
    if(isthis){
        return authRoute(Pages)
    }else{
        return getkey(Pages)
    }
}

const authRoute = (Pages) => {
    let arry = []
    for(let key in Pages){
    	if(Object.hasOwnProperty.call(Pages ,key)){
        	arry.push(<Public path={'/'+startsWithCapital(key)} exact component={Pages[key]} />)
        }
    }
    return arry
}
import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import dataHandeler from '../../services/server'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

export function Signup(props) {
	
	const [user, setUser] = useState([])
	
	useEffect(() => {
    	let params = queryString.parse(props.location.search)
    	if (params.token) {
	    	let buff = new Buffer(params.token, 'base64');
		    let base64ToStr = buff.toString('ascii');
		    let splitStr = base64ToStr.split('/');
	    	setUser({ ...user, 'agent': splitStr[0], 'token': params.token, 'code': '+60' })
    	}
    }, [])
	
    const handelChange = (e) => {
    	setUser({ ...user, [e.target.name]: e.target.value })
    }
    
	const handelSubmit = (e) => {
		e.preventDefault()

		if (!user.loginId || !user.name || !user.password || !user.confirmPassword || !user.passwordWithdrawal) {
			toast.error('Signup failed, please fill up all fields!')
		} else {
			if (user.password !== user.confirmPassword) {
				toast.error('PIN and confirm PIN not match!')
			} else {
				var userData = {
						mobileNumber: user.code + user.loginId,
						password: user.password,
						name: user.name,
						passwordWithdrawal: user.passwordWithdrawal,
						token: user.token
				}
				
				dataHandeler.postRegistration2(userData).then(result => {
			        if (result.data.status === 0) {
			            toast.success('Register successfully')
			            props.history.push('/login')
			        } else {
			        	toast.error('Register failed: ' + result.data.message)
			        }
			    }).catch(error => {
			    	toast.error('Register failed: ' + error)
			    })
			}
		}
	}
    
	return (
		<div className="container-fluid login-bg">
			<div className="loginbox col-12 mx-auto ">
				{user.token ?
				<div className="shadow p-4 bg-white">
					<h6>Welcome, {user.agent} has invited you to signup or <Link to="/login">here to login</Link></h6>
					<form onSubmit={(e) => handelSubmit(e)}>
						<div id="login_mobile" style={{ display: 'block' }}>
							<div className="row">
								<div className="col-md-3">
									<div className="form-group mb-3 mt-4">
										<select className="form-control" name='code' onChange={(e) => { handelChange(e) }} >
											<option value="+60">+60</option>
										</select>
									</div>
								</div>
								<div className="col-md-9">
									<div className="form-group mb-3 mt-4">
										<input type="text" placeholder="Phone Number" className="form-control" name='loginId' onChange={(e) => handelChange(e)} />
										<label className="form-label">Phone Number</label>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group mb-3 mt-4">
										<input type="text" placeholder="Name" className="form-control" name='name' onChange={(e) => handelChange(e)} />
										<label className="form-label">Name</label>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group mb-3 mt-4">
										<input type="password" placeholder="6-digit PIN code" className="form-control" name='password' onChange={(e) => handelChange(e)} />
										<label className="form-label">6-digit PIN code</label>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group mb-3 mt-4">
										<input type="password" placeholder="Confirm 6-digit PIN code" className="form-control" name='confirmPassword' onChange={(e) => handelChange(e)} />
										<label className="form-label">Confirm 6-digit PIN code</label>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group mb-3 mt-4">
										<input type="password" placeholder="Security password for withdrawal" className="form-control" name='passwordWithdrawal' onChange={(e) => handelChange(e)} />
										<label className="form-label">Security password for withdrawal</label>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group pb-0">
										<button className="btn btn-primary w-100 fw-bold py-3">Sign up and accept</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				: <div className="shadow p-4 bg-danger"><h6 style={{textAlign:'center', color:'white'}}>Sorry, you have not been invited!</h6></div>}
			</div>
		</div>
	)
}
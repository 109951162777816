import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { Header } from '../component'
import RouteFunction from './RouteFunction'
import * as Page from '../container'
// import useScript from '../Function/useScript'
export default function Layout () {
    // useScript('./assets/js/custom.js')    
    return (       
        <>
        <Header/>
        <Switch>
            {RouteFunction(Page)}
            <Redirect exact to={'/'} from="*"/>
        </Switch>
        </>
    )
}

import React, { useState, useEffect } from 'react';
import { Storage } from '../../Storage/Storage';
import KeyBoard from './KeyBoard';
import UiEvent from './UiEvent';
import OrderSimple from './OrderSimple';
import OrderText from './OrderText';
import OrderNormal from './OrderNormal';
import Package from './package/Package';
import dataHandeler from '../../services/server';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { hashing, getBetType, getPostBetStr, getPostBetStrNormal, getPostKeyboardBetStr, Convertcounter, ConvertNumberToCounter } from './util/BetUtils.js';

const layoutOptions = [{ label: 'Order(Type)', value: 5 }, { label: 'Grid Order', value: 1 }, { label: 'Filling Order', value: 2 }, { label: 'Text Order', value: 3 }, { label: 'Package', value: 0}];
const layoutOptions6 = [{ label: 'Text Order', value: 4 }, { label: 'Package', value: 0}];
const dayOptions = [{ label: 'Day 1', value: 1 }, { label: 'Day 2', value: 2 }, { label: 'Day 3', value: 3 }, { label: 'Day 4', value: 4 }, { label: 'Day 5', value: 5 }, { label: 'Day 6', value: 6 }, { label: 'Day 7', value: 7 }];
let permArr = [], usedChars = [];

export function Game(props) {

	let { gameCode } = useParams();
	
    const [counterList, setCounterList] = useState([]);
    const [userPoint, setUserPoint] = useState(0);
    const [orderPoint, setOrderPoint] = useState(0);
    const [pointExtDeduction, setPointExtDeduction] = useState(1);
    const [loading, setLoading] = useState(false);
    const [counterLoading, setCounterLoading] = useState(false);
    const [defaultLayoutList, setDefaultLayoutList] = useState([]);
    const [selectedDay, setSelectedDay] = useState({ label: 'Day 1', value: 1 });
    const [selectedLayout, setSelectedLayout] = useState({});

    const [activeOption, setActiveOption] = useState({});
    const [activeBetCounter, setActiveBetCounter] = useState([]);
    const [packageInfo, setPackageInfo] = useState({});
    const [orderRate, setOrderRate] = useState(0);
    const [orderRateList, setOrderRateList] = useState([]);
    
    const [betList, setBetList] = useState([{counter:[], number: '', big: '', small: '', foura: '', threea: '', threeabc: ''}]);
    const [uiBetList, setUiBetList] = useState([{counter:[], number: 'NO LUCKY NUMBER', type: 'S', money: ''}]);
    const [betListNormal, setBetListNormal] = useState([]);
    const [keyboardBetList, setKeyboardBetList] = useState([{index: 0, value: 'D'}, {index: 1, value: ''}]);
    const [textBet, setTextBet] = useState('');
    const [isTransactionClosed, setIsTransactionClosed] = useState(false);
    
    let digitPattern = /^[0-9]+$/;

    useEffect(() => {
    	fetchCounters();
    }, []);
    	
    const fetchCounters = () => {
    	setCounterLoading(true);
    	
    	dataHandeler.getCounters(gameCode).then((result) => {
        	setCounterList(result.data.counterList);
        	setUserPoint(result.data.point);
        	setPointExtDeduction(result.data.pointExtDeduction);
        	setPackageInfo(result.data.gamePackage);
        	setOrderRate(result.data.orderRate);
        	setOrderRateList(result.data.orderRateList);
        	setIsTransactionClosed(result.data.isTransactionClosed);
            if (result.data.isTransactionClosed) {
            	toast.error('System temporary close shop, please try again later!');
            }
        	
        	if (result.data.orderType === 6) {
        		setDefaultLayoutList(layoutOptions6);
        		setSelectedLayout({label: layoutOptions6[0].label, value: layoutOptions6[0].value});
            	setActiveOption({previous: layoutOptions6[0].value, current: layoutOptions6[0].value});
        	} else {
        		setDefaultLayoutList(layoutOptions);
        		setSelectedLayout({label: layoutOptions[0].label, value: layoutOptions[0].value});
            	setActiveOption({previous: layoutOptions[0].value, current: layoutOptions[0].value});
        	}
        	
        	//console.log('------ pointExtDeduction = ' + result.data.pointExtDeduction);
        	if (result.data.counterList.length > 0) {
        		setActiveBetCounter([result.data.counterList[0].code]);
        		betList[0].counter = [result.data.counterList[0].code];
        		uiBetList[0].counter = [result.data.counterList[0].code];
        	}
        	setCounterLoading(false);
        	
        }).catch((error) => {
            console.log(error);
            setCounterLoading(false);
        });
    }
	
	const onSelectDay = (e) => {
		setSelectedDay(e);
	}
    
    const onKeyboardClick = (e) => {
    	setSelectedLayout(e);
    	setActiveOption({previous: e.value, current: e.value});
    }
    
    const convertFromKeyboardView = (active) => {
    	let day = keyboardBetList[0].value.slice(1, 2);
		let newArray = [];
		let newObj = {};
		
		keyboardBetList.map((item, key) => {
			if (key > 0) {
				if (key === 1) {
					if (digitPattern.test(item.value)) {
						newObj.counter = ConvertNumberToCounter(item.value, counterList);
						setActiveBetCounter(newObj.counter);
						
					} else {
						toast.error("Wrong counter, please check (" + item.value + ")");
						return
					}
				} else {
					if (item.value.match(digitPattern)) {
						newObj.counter = ConvertNumberToCounter(item.value, counterList);
						setActiveBetCounter(newObj.counter);
				
					} else {
						let value = item.value;
				
						if (value.startsWith('#')) {
							let number = value.replace('#', '*');
							number = number.substring(0, number.indexOf('#')).replace('*', '#');
							newObj.number = number;
							newObj.type = getBetType(newObj.number);
							if (newObj.number.length === 4)
								value = value.replace(newObj.number, '0000');
							else value = value.replace(newObj.number, '000');
			
						} else if (value.indexOf('##') >= 0) {
							newObj.number = value.substring(0, value.indexOf('##') + 1);
							newObj.type = getBetType(newObj.number);
							if (newObj.number.length === 4)
								value = value.replace(newObj.number, '0000');
							else value = value.replace(newObj.number, '000');
			
						} else {
							let numbers = value.split('#');
							if (active === 0) newObj.number = numbers[0];
							else newObj.number = numbers[0].replaceAll('*', '');
							newObj.type = getBetType(numbers[0]);
						}
		
						if (newObj.number === '') newObj.number = 'NO LUCKY NUMBER';
						let typo = hashing(value);
						newObj = { ...newObj, ...typo };
		                newArray.push({ ...newObj });
					}
				}
			}
		});
		
		if (active === 0) setBetList(newArray);
		else setUiBetList(newArray);
		onSelectDay({ label: 'Day ' + day, value: day });
    }
    
    const onBetEvent = () => {
    	let postData = {};
    	
    	if (activeOption.current === 1) { // default submit order
    		postData.days = selectedDay;
            
    		if (orderPoint > userPoint) {
            	toast.error('Insufficient point to order.');
            	return;
            }
    		
            if (betList.length > 0) {
                postData.bets = betList;
            }
            
            for (let index = 0; index < uiBetList.length; index++) {
                let betObj = betList[index];
            	
            	if (betObj.counter.length === 0) {
                    toast.warning('Please select bet counter');
                    return;
                }
                if (betObj.number.length > 0 && betObj.big.length === 0 && betObj.small.length === 0 && betObj.foura.length === 0
                		&& betObj.threea.length === 0 && betObj.threeabc.length === 0) {
                    toast.warning('Please keyin bet amount');
                    return;
                }
            }

            if (postData.days === '') {
                toast.warning('Please select day');
                return;
            }

            setLoading(true);
        	let str = getPostBetStr(postData, counterList, 0, orderRate);
        	postData = {};
        	postData.bet = str;
            postData.gameCode = gameCode;

    	} else if (activeOption.current === 2) { // ui submit order
            postData.days = selectedDay;
            
            if (uiBetList.length > 0) {
                postData.bets = uiBetList;
            }
            
            if (orderPoint > userPoint) {
            	toast.error('Insufficient point to order.');
            	return;
            }
            for (let index = 0; index < uiBetList.length; index++) {
                if (uiBetList[index].counter.length === 0) {
                    toast.warning('Please select bet counter');
                    return;
                }
                if (uiBetList[index].number === 'NO LUCKY NUMBER') {
                    toast.warning('Please key in your lucky number');
                    return;
                }
                if (uiBetList[index].type === 'NO BET TYPE') {
                    toast.warning('Please select bet type');
                    return;
                }
                if (uiBetList[index].money == 0) {
                    toast.warning('Please select bet');
                    return;
                }
            }

            if (postData.days === '') {
                toast.warning('Please select day');
                return;
            }

            setLoading(true);
        	let str = getPostBetStr(postData, counterList, 1, orderRate);
        	postData = {};
        	postData.bet = str;
            postData.gameCode = gameCode;

    	} else if (activeOption.current === 3) { // keyboard submit order
    		if (keyboardBetList.length < 3) {
                toast.warning('Please enter bet amount.');
                return;
            }
            if (orderPoint > userPoint) {
            	toast.error('Insufficient point to order.');
            	return;
            }
            
            try {
	            setLoading(true);
	            
	            let str = JSON.stringify(getPostKeyboardBetStr(keyboardBetList, orderRate));
	            str = str.replace(/"/g, '');
	            str = str.substring(1);
	            str = str.slice(0, -1);
	            //console.log('------ str = ' + str);
	            postData.bet = str;
	            postData.gameCode = gameCode;
	
            } catch (error) {
            	setLoading(false);
            	toast.error(error.message);
            }
        } else if (activeOption.current === 5) { // default submit order
    		postData.days = selectedDay;
            
            if (betListNormal.length > 0) {
                postData.bets = betListNormal;
            }
            
            if (betListNormal.length === 0) {
            	toast.error('Invalid order');
            	return;
            }
            if (orderPoint > userPoint) {
            	toast.error('Insufficient point to order.');
            	return;
            }
            for (let index = 0; index < betListNormal.length; index++) {
                if (betListNormal[index].counter.length === 0) {
                    toast.warning('Please select bet counter');
                    return;
                }
                if (betListNormal[index].number === 'NO LUCKY NUMBER') {
                    toast.warning('Please key in your lucky number');
                    return;
                }
                if (betListNormal[index].type === 'NO BET TYPE') {
                    toast.warning('Please select bet type');
                    return;
                }
                if (betListNormal[index].money == 0) {
                    toast.warning('Please select bet');
                    return;
                }
            }

            if (postData.days === '') {
                toast.warning('Please select day');
                return;
            }

            setLoading(true);
        	let str = getPostBetStrNormal(postData, counterList, orderRate);
        	postData = {};
        	postData.bet = str;
            postData.gameCode = gameCode;
        
        } else { // text order
            setLoading(true);
            
    		let str = textBet.replace(/\s/g, ',');
    		str = str.replace('D', '');
    		//str += ',GPRS';
    		
    		let dateStr = str.substring(0, str.indexOf(','));
    		if (dateStr.length === 0) str = '1' + str;
    		
    		postData.bet = str;
            postData.gameCode = gameCode;
    	}
    	
    	dataHandeler.postMemberBet(postData).then((res) => {
            if (res.data.status === 0) {
                toast.success(res.data.message);
                props.history.push('/sales-order');
            } else toast.error(res.data && res.data.message ? res.data.message : 'error');
            setLoading(false);
            
        }).catch((error) => {
            console.log(error);
            toast.error(error.message);
            setLoading(false);
        });
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection mobileNav d-flex align-items-center">
                    <h1 className="flex-grow-1">
                        <span className="pageheading">Bet</span>
                    </h1>
                    <div className="d-inline-block righticon openkeyboard" style={{width:'200px'}}>
                    	<Select value={selectedLayout} onChange={(e) => onKeyboardClick(e)} options={defaultLayoutList} isSearchable={false} />
                    </div>
                    {/* activeOption.current === 1 || activeOption.current === 2 ?
                    <a className="d-inline-block righticon openkeyboard btn" onClick={() => onKeyboardClick(0)}>
	                    <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} fill="currentColor" className="bi bi-keyboard-fill" viewBox="0 0 16 16">
		                    <path d="M0 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm13 .25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25zM2.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 8.75v-.5A.25.25 0 0 0 2.75 8h-.5zM4 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 5 8.75v-.5A.25.25 0 0 0 4.75 8h-.5a.25.25 0 0 0-.25.25zM6.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 7 8.75v-.5A.25.25 0 0 0 6.75 8h-.5zM8 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 9 8.75v-.5A.25.25 0 0 0 8.75 8h-.5a.25.25 0 0 0-.25.25zM13.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5zm-3-2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-1.5zm.75 2.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25zM11.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5zM9 6.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5A.25.25 0 0 0 9.75 6h-.5a.25.25 0 0 0-.25.25zM7.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 8 6.75v-.5A.25.25 0 0 0 7.75 6h-.5zM5 6.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 6 6.75v-.5A.25.25 0 0 0 5.75 6h-.5a.25.25 0 0 0-.25.25zM2.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5A.25.25 0 0 0 4 6.75v-.5A.25.25 0 0 0 3.75 6h-1.5zM2 10.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25zM4.25 10a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-5.5z"/>
		                  </svg>
	                </a> : '' 
                    <a className="d-inline-block righticon openkeyboard btn" onClick={() => onKeyboardClick(activeOption.current === 0 || activeOption.current === 2 ? 1 : 2)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="currentColor" className="bi bi-keyboard" viewBox="0 0 16 16">
                            <path d="M14 5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12zM2 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2z" />
                            <path d="M13 10.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5 0A.25.25 0 0 1 8.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 8.75v-.5zm2 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-.5zm1 2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5-2A.25.25 0 0 1 6.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 6 8.75v-.5zm-2 0A.25.25 0 0 1 4.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 8.75v-.5zm-2 0A.25.25 0 0 1 2.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 8.75v-.5zm11-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0A.25.25 0 0 1 9.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 9 6.75v-.5zm-2 0A.25.25 0 0 1 7.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 7 6.75v-.5zm-2 0A.25.25 0 0 1 5.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 5 6.75v-.5zm-3 0A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 6.75v-.5zm0 4a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm2 0a.25.25 0 0 1 .25-.25h5.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-5.5a.25.25 0 0 1-.25-.25v-.5z" />
                        </svg>
                    </a> */}
                </div>
                <div className="mobile-nav px-1" style={{position:'absolute', zIndex:'1031', top:'3px', right:'0px', width:'140px'}}>
                	<Select value={selectedLayout} onChange={(e) => onKeyboardClick(e)} options={defaultLayoutList} isSearchable={false}/>
                	{/* <a className="d-inline-block righticon" onClick={() => onKeyboardClick(activeOption.current === 0 || activeOption.current === 2 ? 1 : 2)} style={{float:'right'}}>
		                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-keyboard" viewBox="0 0 16 16">
		                    <path d="M14 5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12zM2 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2z" />
		                    <path d="M13 10.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5 0A.25.25 0 0 1 8.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 8.75v-.5zm2 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-.5zm1 2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5-2A.25.25 0 0 1 6.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 6 8.75v-.5zm-2 0A.25.25 0 0 1 4.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 8.75v-.5zm-2 0A.25.25 0 0 1 2.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 8.75v-.5zm11-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0A.25.25 0 0 1 9.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 9 6.75v-.5zm-2 0A.25.25 0 0 1 7.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 7 6.75v-.5zm-2 0A.25.25 0 0 1 5.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 5 6.75v-.5zm-3 0A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 6.75v-.5zm0 4a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm2 0a.25.25 0 0 1 .25-.25h5.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-5.5a.25.25 0 0 1-.25-.25v-.5z" />
		                </svg>
		            </a>
                	 activeOption.current === 1 || activeOption.current === 2 ?
                    <a className="d-inline-block righticon openkeyboard btn" onClick={() => onKeyboardClick(0)} style={{float:'right'}}>
	                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-keyboard-fill" viewBox="0 0 16 16">
		                    <path d="M0 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm13 .25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25zM2.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 8.75v-.5A.25.25 0 0 0 2.75 8h-.5zM4 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 5 8.75v-.5A.25.25 0 0 0 4.75 8h-.5a.25.25 0 0 0-.25.25zM6.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 7 8.75v-.5A.25.25 0 0 0 6.75 8h-.5zM8 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 9 8.75v-.5A.25.25 0 0 0 8.75 8h-.5a.25.25 0 0 0-.25.25zM13.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5zm-3-2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-1.5zm.75 2.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25zM11.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5zM9 6.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5A.25.25 0 0 0 9.75 6h-.5a.25.25 0 0 0-.25.25zM7.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 8 6.75v-.5A.25.25 0 0 0 7.75 6h-.5zM5 6.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 6 6.75v-.5A.25.25 0 0 0 5.75 6h-.5a.25.25 0 0 0-.25.25zM2.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5A.25.25 0 0 0 4 6.75v-.5A.25.25 0 0 0 3.75 6h-1.5zM2 10.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25zM4.25 10a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-5.5z"/>
		                  </svg>
	                </a> : '' */}
                </div>
                {activeOption.current === 1 ? 
                	<OrderSimple 
                		userPoint={userPoint}
                		orderPoint={orderPoint}
                		setOrderPoint={setOrderPoint}
                		counterList={counterList}
        				loading={loading}
                		counterLoading={counterLoading}
                		activeBetCounter={activeBetCounter}
        				setActiveBetCounter={setActiveBetCounter}
                		betList={betList}
                		setBetList={setBetList}
                		onBetEvent={onBetEvent}
                		dayOptions={dayOptions}
                		selectedDay={selectedDay}
                		onSelectDay={onSelectDay}
                		pointExtDeduction={pointExtDeduction}
                		orderRate={orderRate}
                		isTransactionClosed={isTransactionClosed}
                		orderMsg={props.location.orderMsg}/>
                    : activeOption.current === 2 ?
                	<UiEvent
                        counterList={counterList}
                    	userPoint={userPoint}
                    	orderPoint={orderPoint}
                    	setOrderPoint={setOrderPoint}
                		loading={loading}
                		counterLoading={counterLoading}
	            		activeBetCounter={activeBetCounter}
	    				setActiveBetCounter={setActiveBetCounter}
                    	uiBetList={uiBetList}
                    	setUiBetList={setUiBetList}
            			onBetEvent={onBetEvent}
	            		dayOptions={dayOptions}
	            		selectedDay={selectedDay}
	            		onSelectDay={onSelectDay}
                    	pointExtDeduction={pointExtDeduction}
                		orderRate={orderRate}
                		isTransactionClosed={isTransactionClosed}/>
                    : activeOption.current === 3 ?
                	<KeyBoard
                    	loading={loading}
                    	keyboardBetList={keyboardBetList}
						setKeyboardBetList={setKeyboardBetList}
                    	orderPoint={orderPoint}
						setOrderPoint={setOrderPoint}
						onBetEvent={onBetEvent}
                    	pointExtDeduction={pointExtDeduction}
                		orderRate={orderRate}
                		isTransactionClosed={isTransactionClosed}/>
            		: activeOption.current === 4 ?
                	<OrderText
                		userPoint={userPoint}
						loading={loading}
                		counterLoading={counterLoading}
                		onBetEvent={onBetEvent}
                		textBet={textBet}
                		setTextBet={setTextBet}
                		isTransactionClosed={isTransactionClosed}/>
                    : activeOption.current === 5 ?
                    <OrderNormal
                		userPoint={userPoint}
                		orderPoint={orderPoint}
                		setOrderPoint={setOrderPoint}
                		counterList={counterList}
        				loading={loading}
                		counterLoading={counterLoading}
                		activeBetCounter={activeBetCounter}
        				setActiveBetCounter={setActiveBetCounter}
                		betList={betListNormal}
                		setBetList={setBetListNormal}
                		onBetEvent={onBetEvent}
                		dayOptions={dayOptions}
                		selectedDay={selectedDay}
                		onSelectDay={onSelectDay}
                		pointExtDeduction={pointExtDeduction}
                		orderRate={orderRate}
                		isTransactionClosed={isTransactionClosed}
                		orderMsg={props.location.orderMsg}/>
                	: activeOption.current === 0 ?
                	<Package
                		packageInfo={packageInfo}
                    	orderRate={orderRate}
                    	setOrderRate={setOrderRate}
                    	orderRateList={orderRateList}
                    	pointExtDeduction={pointExtDeduction}/>
                    : ''
                }
            </div>
        </div>
    )
}

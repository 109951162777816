import React, { useState, useEffect, useRef } from 'react';
import 'react-rangeslider/lib/index.css';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import dataHandeler from '../../services/server';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../Utils/Constants';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import QRCode from 'react-qr-code';
const { REACT_APP_SIGNUP_URL } = process.env;

export function CreateDownline(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [user, setUser] = useState([]);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginUrl, setLoginUrl] = useState('');
    const [availGames, setAvailGames] = useState([]);
    const [checkedAvailGames, setCheckedAvailGames] = useState([]);
    const [checkedDefaultGames, setCheckedDefaultGames] = useState([]);
    const [pwdLen, setPwdLen] = useState('4');
    const textInputRef = useRef(null);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        dataHandeler.getQrCodeInfo().then(result => {
        	if (result.data.status === 0) {
                let pass = result.data.dlDefaultPass;
                setPassword(pass);

                let buff = new Buffer(result.data.token, 'base64');
                let base64ToStr = buff.toString('ascii');
                let splitStr = base64ToStr.split('/');
                setUser({ ...user, 'agent': splitStr[0], 'token': result.data.token, 'code': '+60',
                    password: pass, confirmPassword: pass, passwordWithdrawal: pass });
        	}
        }).catch(error => console.log('error came after it ', error));
        
        dataHandeler.getAvailGames().then(result => {
        	if (result.data.status === 0) {
        		setAvailGames(result.data.gameList);
        	}
        });
    }

    const handelChange = (e) => {
    	setUser({ ...user, [e.target.name]: e.target.value });
    }

    const createDownlineEvent = () => {
        // console.log('###############user: ' + JSON.stringify(user))
		// if (!user.loginId || !user.name || !user.password || !user.confirmPassword || !user.passwordWithdrawal) {
        if (!user.loginId || !user.name || !user.password || !user.confirmPassword) {
			toast.error(t('msg.signup.failed.fill.fields'));
            return;
		} else {
			if (user.password !== user.confirmPassword) {
				toast.error(t('msg.pin.confirm.not.match'));
                return;
			} else {
				let availCount = 0;
				let defaultCount = 0;
				let duplicatedGame = 0;
				let prevGame = '';
				
		    	for (let i = 0; i < availGames.length; i++) {
		    		if (availGames[i].availChecked) availCount++;
		    		if (availGames[i].defaultChecked) defaultCount++;
		    		
		    		if (i === 0) {
		    			prevGame = availGames[i].gameCode;
		    			if (availGames[i].defaultChecked) duplicatedGame++;
		    		} else {
		    			if (prevGame === availGames[i].gameCode) {
		    				if (availGames[i].defaultChecked) duplicatedGame++;
		    				if (duplicatedGame > 1) {
			    				toast.error('Cannot select duplicated default games!');
			    				return;
			    			}
		    			} else {
		    				duplicatedGame = 0;
		    				if (availGames[i].defaultChecked) duplicatedGame++;
		    			}
	    				prevGame = availGames[i].gameCode;
		    		}
		    	}
		    	
		    	if (availCount === 0 || defaultCount === 0) {
		    		toast.error('At least 1 default game!');
    				return;
		    	}
				
				var userData = {
					mobileNumber: user.code + user.loginId,
					password: user.password,
					name: user.name,
					passwordWithdrawal: user.password,
					token: user.token,
                    loginType: Constants.TOKEN_LOGIN,
                    availGames: availGames
				}
				setLoading(true);
				dataHandeler.postRegistration2(userData).then(result => {
                    // console.log('###############result: ' + JSON.stringify(result))
			        if (result.data.status === 0) {
                        let token = result.data.token;
                        if (token != undefined && token != null) {
                            toast.success(t('msg.register.success'));
                            setLoginUrl(REACT_APP_SIGNUP_URL.replace('signup', 'login') + '?loginToken=' + token);
                        } else {
                            toast.error(t('msg.register.failed') + 'Receiver error, please contact admin');
                        }
			        } else {
			        	toast.error(t('msg.register.failed') + result.data.message);
			        }
                    setLoading(false);
			    }).catch(error => {
			    	toast.error(t('msg.register.failed') + error);
                    setLoading(false);
			    })
			}
		}
	}

    const copyCodeToClipboard = () => {
        textInputRef.current.select();
        document.execCommand('copy');
    }
    
    const handleCheckAll = (e) => {
    	let availCheckboxes = document.getElementsByName('availCheckbox');
    	let defaultCheckboxes = document.getElementsByName('defaultCheckbox');
    	
    	for (let i = 0; i < availCheckboxes.length; i++) {
    		availCheckboxes[i].checked = e.target.checked;
    		availGames[i].availChecked = e.target.checked;
    		
    		if (defaultCheckboxes[i].checked) {
    			defaultCheckboxes[i].checked = e.target.checked;
        		availGames[i].defaultChecked = e.target.checked;
    		}
    	}
    }
    
    const handleCheck = (e, key, type) => {
    	if (type === 'A') {
    		availGames[key].availChecked = e.target.checked;
    		
    		if (!e.target.checked && availGames[key].defaultChecked) {
    			let defaultCheckboxes = document.getElementsByName('defaultCheckbox');
    			
    			availGames[key].defaultChecked = e.target.checked;
    			defaultCheckboxes[key].checked = e.target.checked;
    		}
    	} else {
    		let availCheckboxes = document.getElementsByName('availCheckbox');
    		availCheckboxes[key].checked = e.target.checked;
    		
    		availGames[key].availChecked = e.target.checked;
    		availGames[key].defaultChecked = e.target.checked;
    	}
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('create.downline.title')}</span>
		            </h1>
		            <Link className="btn btn-danger respBtn" to="/home">{t('button.cancel')}</Link>
	            </div>
            </div>
            { loginUrl ?
            <div className="col-12 mobileContent mt-3">
                <div className="card">
                    <div className="card-body shadow">
                        <div className="text-center">
                            <QRCode value={loginUrl}/>
                            <br/><br/>
                            <div className="col-md-6 mt-3 mx-auto">
                            <div className="form-group text-start form-ani mb-0">
                                <div className>
                                <input className="form-control" placeholder=" " defaultValue={loginUrl} type="text"
                                    ref={textInputRef} />
                                </div>
                            </div>
                            </div>
                            <div className="col-md-6 mt-3 mx-auto">
                                <WhatsappShareButton url={loginUrl}>
                                    <WhatsappIcon size={40} round={true}/>
                                </WhatsappShareButton>
                                <a href={'sms:?&body=' + loginUrl} className="px-2 text-decoration-none">
                                    <img src="assets/images/mail.svg" height={40} />
                                </a>
                                <a className="px-3 text-decoration-none" onClick={() => copyCodeToClipboard()}>
                                    <img src="assets/images/copy.svg" height={40} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : (user.token ?
            <div className="row">
            	<div className="col-md-12 mt-3">
	            	<button type="button" class="btn3d btn btn-primary w-100" onClick={() => props.history.push('/qr-code')}>
		                <Link className="col-md py-3 px-2">
		                    <img src="https://miro.medium.com/max/990/1*PNniLVIC_Hc7gNIxjvWPWg.png" width={20} />
		                </Link>
		            </button>
            	</div>
                <div className="col-md-12 mx-auto mt-3">
                    <div className="card shadow mb-1">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="col-ms-8 col-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-3 mt-4">
                                            <select className="form-control" name='code' onChange={(e) => { handelChange(e) }} >
                                                <option value="+60">+60</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="text" placeholder={t('create.downline.phone')} className="form-control" name='loginId'
                                                onChange={(e) => handelChange(e)} />
                                            <label className="form-label">{t('create.downline.phone')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="text" placeholder={t('create.downline.name')} className="form-control" name='name'
                                                onChange={(e) => handelChange(e)}/>
                                            <label className="form-label">{t('create.downline.name')}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder={t('create.downline.password')} className="form-control" name='password'
                                                onChange={(e) => handelChange(e)} defaultValue={password} />
                                            <label className="form-label">{t('create.downline.password', { value: pwdLen})}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder={t('create.downline.confirm')} className="form-control" name='confirmPassword'
                                                onChange={(e) => handelChange(e)} defaultValue={password} />
                                            <label className="form-label">{t('create.downline.confirm', { value: pwdLen})}</label>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="form-group mb-3 mt-4">
                                            <input type="password" placeholder={t('create.downline.sec.pass.withdraw')} className="form-control" name='passwordWithdrawal'
                                                onChange={(e) => handelChange(e)} defaultValue={password} />
                                            <label className="form-label">{t('create.downline.sec.pass.withdraw')}</label>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div className="table-responsive mb-2">
                            	{availGames ?
                            	<table className="table custom_table">
	                            	<thead>
		                                <tr>
		                                	<th><input type="checkbox" onChange={(e) => handleCheckAll(e)}/></th>
		                                    <th>Default</th>
		                                    <th>Game</th>
		                                    <th>Package</th>
		                                </tr>
		                            </thead>
		                            <tbody>
                            		{availGames.length > 0 ? availGames.map((element, key) =>(
	                        			<tr className="fw-bold" key={key}>
	                        				<td><input type="checkbox" name="availCheckbox" onChange={(e) => handleCheck(e, key, 'A')}/></td>
	                        				<td style={{textAlign:'center'}}><input type="checkbox" name="defaultCheckbox" onChange={(e) => handleCheck(e, key, 'D')}/></td>
	                                        <td>
	                                            <p className="mb-0 text-muted small">{element.name}</p>
	                                        </td>
	                                        <td>
	                                            <p className="mb-0 text-danger small">{element.packageDesc}</p>
	                                        </td>
	                                    </tr>
                                    )) : <tr className="fw-bold"><td>{t('msg.no.record.found.on.date')}</td></tr> }
                            		</tbody>
                            	</table>
                            	: 'No game found!'}
                            </div>
                            <div className="col-12">
	                            <div className="form-group pb-1 mb-0">
	                                <button type="button" className="btn btn-primary w-100 fw-bold py-3" onClick={() => { createDownlineEvent() }} disabled={loading}>
	                                    {loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : t('create.downline.button.create') }
	                                </button>
	                            </div>
	                        </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="keyboard_popup" tabIndex={-1} aria-labelledby="keyboard_popupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="text-end p-4 d-flex align-items-center">
                                    <h3 className="text-start text-white flex-grow-1 px-3">Keyboard</h3>
                                    <a data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" height={30} /></a>
                                </div>
                                <div className="modal-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :  <div className="shadow p-4 bg-danger"><h6 style={{textAlign:'center', color:'white'}}>Sorry, you have not been invited!</h6></div>
            )}
        </div>
    )
}

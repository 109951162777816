import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Storage } from '../Storage/Storage'

 function PrivateRoutes({ component, path } ) {
     if(Storage.get('user')){
         return (
             <Route path = {path} component={component} />
         )
     }else{
        return (
            <Redirect to="/login"/>
        )
     }
}
export default PrivateRoutes
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'

const numType = ['B', 'S', '4A', 'A', 'C'];
let permArr = [], usedChars = [];

export default function KeyBoard({
	loading, keyboardBetList, setKeyboardBetList, orderPoint, setOrderPoint, pointExtDeduction, onBetEvent, orderRate, isTransactionClosed
}) {
 
    const [betRow, setBetRow] = useState(1);
    const [number, setNumber] = useState('');

    useEffect(() => {
    	
    }, []);

    useEffect(() => {
    	let summession = 0;
    	let multiplicative = 1;
    	let multidate = 1;
    	let prevAmount = '';
		let counter = 0;
		
		// box 1*234 or 123*4
		let patt1 = /\d{3,3}[*]\d{1}/i;
		let patt2 = /\d{1}[*]\d{3,3}/i;
    	
    	for (let index in keyboardBetList) {
    		let str = keyboardBetList[index].value;
    		if (index > 0) {
    			if (str.indexOf('#') === -1) { // counter
    				counter = str.length;
    				
    			} else { // number
    				let number = '';
    				if (str.startsWith('**')) { // ibox
    					number = str.substring(2, str.indexOf('#'));
    					multiplicative = 1;
    					str = str.substring(str.indexOf('#') + 1);
    					
    				} else if (str.startsWith('*')) { // box
    					number = str.substring(1, str.indexOf('#'));
    					if (number.endsWith('**')) number = number.substring(0, number.length - 2); // 4DS
    					
    					let myFunc = num => Number(num);
    					let num = Array.from(number, myFunc);
    					permArr = [];
    					multiplicative = permute(num);
    					str = str.substring(str.indexOf('#') + 1);
    					
    				} else if (str.startsWith('#')) { // bao head
    					multiplicative = 10;
    					str = str.substring(1);
    					str = str.substring(str.indexOf('#') + 1);
    					
    				} else if (str.indexOf('##') === 3 ||  str.indexOf('##') === 2) { // bao tail
    					multiplicative = 10;
    					str = str.substring(str.indexOf('##') + 2);
    					
    				} else {
    					number = str.substring(0, str.indexOf('#'));
    					if (number.endsWith('**')) number = number.substring(0, number.length - 2); // 4DS
    					
    					if (patt1.test(number) || patt2.test(number)) {
    						if (patt1.test(number)) number = number.substring(0, 3);
    						else number = number.substring(2);
    						
    						let myFunc = num => Number(num);
        					let num = Array.from(number, myFunc);
        					permArr = [];
        					multiplicative = permute(num);
    						
    					} else if (number.endsWith('**')) { // 4DS
    						multiplicative = 1;
    						
    					} else if (str.indexOf('*') === 3 || str.indexOf('*') === 4) { // rev
        					multiplicative = 2;
        					
        				} else multiplicative = 1;
    					str = str.substring(str.indexOf('#') + 1);
    				}

    				let str_arr = str.split('#');
    				let totalAmt = 0;
    				for (let i = 0; i < str_arr.length; i++) {
    					totalAmt += parseFloat(str_arr[i].replace('*','.'));
    					prevAmount += '#' + str_arr[i];
    				}
    				if (!totalAmt) totalAmt = 0;
    				summession += totalAmt * multiplicative * multidate * counter * pointExtDeduction;
    			}
    		} else {
    			let multidate_str = str.replace('D', '');
    			if (multidate_str === '') multidate = 1;
    			else multidate = parseInt(multidate_str);
    		}
    	}
    	summession = summession * orderRate;
        setOrderPoint(summession.toFixed(2));
    },[keyboardBetList]);

    const addBetRow = () => {
        let regex = /^[0-9 ]+$/;
        let list = [...keyboardBetList];
        if ((list[list.length - 1].value != '') && !(regex.test(list[list.length - 1]))) {
            list.push({ value: '' });
            activeBetRow(list.length - 1);
            setKeyboardBetList(list);
        } else {
            toast.warning("please complete last bet");
        }
    }

    const removeBetRow = () => {
        let list = [...keyboardBetList];
        if (betRow > 1) {
            list.splice(betRow, 1);
            activeBetRow(betRow - 1);
            setKeyboardBetList(list);
        }
    }

    const removeNumber = () => {
        let list = [...keyboardBetList];
        let obj = list[betRow] ? list[betRow] : { value: '' };
        let value = obj.value;
        /*if( betRow === 0) {
           if (value.length > 1) {
                value = value.slice(0, -1);
            }
        }else if (betRow === 1) {
            if (value.length != 1) {
                value = value.slice(0, -1);
            }
        } else {
            value = value.slice(0, -1);
        }*/
        value = value.slice(0, -1);
        obj.value = value;
        list.splice(betRow, 1, obj);
        setKeyboardBetList(list);
    }

    const permute = (input) => {
        var i, ch;
        for (i = 0; i < input.length; i++) {
	        ch = input.splice(i, 1)[0];
	        usedChars.push(ch);
	        if (input.length == 0 && !permArr.includes(usedChars.join(''))) {
                permArr.push(usedChars.join(''));
            }
	        permute(input);
	        input.splice(i, 0, ch);
	        usedChars.pop();
	    }
	    return permArr.length;
    }
        
    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const onClickNumber = (e) => {
        let list = [...keyboardBetList];
        let obj = list[betRow] ? list[betRow] : { value: '' };
        
        if (betRow === 0) {
        	if (Number(e) && obj.value.search(e) < 0 && e != 0) {
                obj.value='D'+ e;
            }
        } else {
        	 if (number.length < 8) {
                 setNumber(getLukyNumber());
                 if (!obj.value && keyboardBetList.length < 2) {
                     obj.value = obj.value + e;
                     list.push(obj);
 
                 } else {
                     obj.value = obj.value + e;
                     list.splice(betRow, 1, obj);
                 }
             } else if (obj.value.search(/[*]/g) >= 0 || obj.value.search('#') >= 0) {
                 setNumber(getLukyNumber());
                 if (!obj.value && keyboardBetList.length < 2) {
                     obj.value = obj.value + e;
                     list.push(obj);
 
                 } else {
                     obj.value = obj.value + e;
                     list.splice(betRow, 1, obj);
                 }
             }
        }
        setKeyboardBetList(list);
    }

    const onClickBetType = (action) => {
        let list = [...keyboardBetList];
        let obj = list[betRow] ? list[betRow] : { value: '' };
        let value = obj.value;

        if (!value.startsWith('#') && value.indexOf('##') == -1) {
        	if (value.startsWith('**')) value = value.substring(2); // ibox
        	else if (value.startsWith('*')) value = value.substring(1); // box
        	else if (value.indexOf('*') === 3 || value.indexOf('*') === 4) value = value.replace('*', ''); // reverse
        }

        switch (action) {
            case 'BOX':
                if (value.search(getLukyNumber()) >= 0) {
                    value = value.replace(getLukyNumber(), '*' + getLukyNumber());
                }
                break;
            case 'iBOX':
                if (value.search(getLukyNumber()) >= 0) {
                    value = value.replace(getLukyNumber(), '**' + getLukyNumber());
                }
                break;
            case 'Rev':
                if (value.search(getLukyNumber()) >= 0) {
                    value = value.replace(getLukyNumber(), getLukyNumber() + '*');
                }
                break;
            default:
                break;
        }

        obj.value = value;
        list.splice(betRow, 1, obj);
        setKeyboardBetList(list);
    }

    const onClickbetAmount = (act) => {
        let list = [...keyboardBetList];
        let obj = list[betRow] ? list[betRow] : { value: '' };
        let value = obj.value;
        let newNum = '';
        switch (act) {
            case 'B':
                if (value.search('#1') < 0) {
                    newNum = value + '#1';
                } else {
                    value = value.replace('#1', '');
                    newNum = value;
                }
                break;
            case 'S':
                if (value.search('#2') < 0) {
                    newNum = value + '#2';
                } else {
                    value = value.replace('#2', '');
                    newNum = value;
                }
                break;
            case '4A':
                if (value.search('#3') < 0) {
                    newNum = value + '#3';
                } else {
                    value = value.replace('#3', '');
                    newNum = value;
                }
                break;
            case 'A':
                if (value.search('#4') < 0) {
                    newNum = value + '#4';
                } else {
                    value = value.replace('#4', '');
                    newNum = value;
                }
                break;
            case 'C':
                if (value.search('#5') < 0) {
                    newNum = value + '#5';
                } else {
                    value = value.replace('#5', '');
                    newNum = value;
                }
                break;
        }

        obj.value = newNum;
        list.splice(betRow, 1, obj);
        setKeyboardBetList(list);
    }

    const activeBetRow = (id) => {
        setBetRow(id);
    }

    const getLukyNumber = () => {
        let betValue = keyboardBetList[betRow] && keyboardBetList[betRow].value;
        let arry = betValue ?betValue.split(''): [];
        let isnum = 1;
        let myNumber = '';
        arry.forEach(async function (item) {
            if (Number(item) == item) {
                if ((isnum == 1) || (isnum == 2)) {
                    myNumber += item;
                    isnum = 2;
                }
            } else {
                if (isnum == 2) {
                    isnum = 3;
                }
            }
        })
        return myNumber;
    }

    const activeAmountBtn = (act) => {
        let number = getLukyNumber();
    	let numLength = number.length;
    	
        if (betRow >= 2) {
            switch (act) {
                case 'B':
                    if (numLength >= 4) {
                        return false;
                    } else {
                        return true;
                    }
                case 'S':
                    if (numLength === 4) {
                        return false;
                    } else {
                        return true;
                    }
                case '4A':
                    if (numLength === 4) {
                        return false;
                    } else {
                        return true;
                    }
                case 'A':
                    if (numLength === 3) {
                        return false;
                    } else {
                        return true;
                    }
                case 'C':
                    if (numLength === 3) {
                        return false;
                    } else {
                        return true;
                    }
                }
        } else {
            return true;
        }
    }

    const activeTypeBtn = (act) => {
    	let number = getLukyNumber();
        let numLength = number.length;
        
        if (betRow >= 2) {
            switch (act) {
                case 'BOX':
                    if ((numLength <= 4) && (numLength > 2)) {
                        return false;
                    } else {
                        return true;
                    }
                case 'iBOX':
                    if ((numLength <= 4) && (numLength > 2)) {
                        return false;
                    } else {
                        return true;
                    }
                case 'Rev':
                    if (numLength > 2) {
                        return false;
                    } else {
                        return true;
                    }
            }
        } else {
            return true;
        }
    }

    return (
        <div className="col-12 mobileContent keyboard mt-2" >
            <h5>{orderPoint} pts</h5>
            <div className="card">
                <div className="card-body shadow">
                    <table className="table">
	                    <tbody>
	                        {keyboardBetList.map((bet, key) => (
	                            <tr key={key} className={`fw-bold ${betRow === key ? 'activeRow' : ''}`} onClick={() =>activeBetRow(key)}>
	                                <td className="h5" height={40}>{bet.value}</td>
	                            </tr>
	                        ))}
	                        <tr className="fw-bold pointer" onClick={() => addBetRow()}>
	                            <td className="text-muted h5" style={{ width: '30px' }}>+</td>
	                        </tr>
	                    </tbody>
	                </table>
                    {/*<div className="keyboard_ row px-2">
                        {numType.map((num, key) => (
                            <div key={key} className="col-2-5 mb-2 px-1">
                                <button
                                    className="btn btn-success py-3 px-0 w-100 fw-bold"
                                    onClick={() => onClickbetAmount(num)}
                                    disabled={activeAmountBtn(num)}
                                >{num}</button>
                            </div>
                        ))}
                    </div>*/}
                    <div className="keyboard_ row px-2">
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(1)}>1</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(2)}>2</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(3)}>3</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-primary py-3 px-0 w-100 fw-bold"
                                onClick={() => onClickBetType('BOX')}
                                disabled={activeTypeBtn('BOX')}>BOX</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1" >
                            <button onClick={() => removeNumber()}
                                className="btn btn-outline-primary py-3 w-100 fw-bold"
                                data-toggle="tooltip"
                                date-placement="top"
                                title="clear element">
                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-backspace" viewBox="0 0 16 16">
                                    <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                                    <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="keyboard_ row px-2">
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(4)}>4</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(5)}>5</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(6)}>6</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button
                                className="btn btn-primary py-3 px-0 w-100 fw-bold"
                                onClick={() => onClickBetType('iBOX')}
                                disabled={activeTypeBtn('iBOX')}>iBOX</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button
                                className="btn btn-outline-primary py-3 w-100 fw-bold"
                                onClick={() => removeBetRow()}
                                data-toggle="tooltip"
                                date-placement="top"
                                title="remove row">
                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="keyboard_ row px-2">
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(7)}>7</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(8)}>8</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(9)}>9</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button
                                className="btn btn-primary py-3 px-0 w-100 fw-bold"
                                onClick={() => onClickBetType('Rev')}
                                disabled={activeTypeBtn('Rev')}
                            >Rev</button>
                        </div>                                     
                        <div className="col-2-5 mb-2 px-1">
                            <button
                                className="btn btn-outline-primary py-3 w-100 fw-bold"
                                onClick={() => addBetRow()}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="create new row">
                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="keyboard_ row px-2">
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber('*')}>*</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber(0)}>0</button>
                        </div>
                        <div className="col-2-5 mb-2 px-1">
                            <button className="btn btn-outline-primary py-3 w-100 fw-bold" onClick={() => onClickNumber('#')}>#</button>
                        </div>
                        <div className="col mb-2 px-1">
                            <button type="button" className="btn btn-outline-success py-3 w-100 fw-bold" onClick={() => onBetEvent()} disabled={loading || isTransactionClosed}>
                            	{ loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : 'Bet'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

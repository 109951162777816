export const Storage = {

    get (key) {
        if(localStorage.getItem(key)){
            // JSON.parse(localStorage.getItem(key))
            return JSON.parse(localStorage.getItem(key))
        }else{
            return false
        }
    },
    set (key ,data) {
        localStorage.setItem(key ,JSON.stringify(data))
    },
    remove (key) {
        localStorage.removeItem(key)
    }

}

// const get = new Promise(key, (resolve, reject) => {
//     let data = {}
//     if (localStorage.getItem(key)) {
//         data.key = JSON.parse(localStorage.getItem(key))
//         resolve(data);
//     }
//     else {
//         data.key = false
//         reject(data);
//     }
// })
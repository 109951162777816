import React, { useState, useEffect, useRef } from 'react'
import dataHandeler from '../../services/server'
import { toast } from 'react-toastify'

export function Forgot() {

    const [user, setUser] = useState({code: '+60'})
    useState({ code: '+60', phoneNo:'', password:'', confirmPassword:'', tagCode: '' })
    const [loading, setLoading] = useState(false)
    const [tagLoading, setTagLoading] = useState(false)
    const COUNTDOWNSECONDS = 60
    let [countDownTime, setCountDownTime] = useState(COUNTDOWNSECONDS)
    let [countingTime, setCountingTime] = useState(COUNTDOWNSECONDS + '')
    const Ref = useRef(null);

    const handelChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const requestTagCode = () => {
        if (!user.phoneNo || user.phoneNo === '') {
            toast.error('Please fill your phone number')
            return
        }

        var userData = {
            phoneNo: user.code + user.phoneNo
        }

        setLoading(true)
        dataHandeler.requestTagCode(userData).then(result => {
            //console.log('###############result: ' + JSON.stringify(result))
            if (result.data.status === 0) {
                // toast.success('Your TAC via SMS has been sent')
                toast.success(result.data.message)
                startCountdownTimer()
            } else {
                toast.error(result.data.message)
            }
            setLoading(false)
        }).catch(error => {
            toast.error('Error: ' + error)
            setLoading(false)
        })
    }

    const startCountdownTimer = () => {
        if (Ref.current) clearInterval(Ref.current)

        setTagLoading(true)
        setCountingTime('Waiting(' + countDownTime + ')...')
        const id = setInterval(() => {
            countDownTime -= 1
            setCountingTime('Waiting(' + countDownTime + ')...')
            if (countDownTime <= 0) {
                countDownTime = COUNTDOWNSECONDS
                setTagLoading(false)
                if (Ref.current) clearInterval(Ref.current)
            }
        }, 1000)
        Ref.current = id
    }

    const forgotPasswordEvent = () => {
        if (!user.phoneNo || user.phoneNo === '') {
            toast.error('Please fill your phone number')
            return
        }

        if (!user.tagCode || user.tagCode === '') {
			toast.error('Please fill your tag code')
            return
        }

        if (!user.password || user.password === '' || !user.confirmPassword || user.confirmPassword === '') {
			toast.error('Please fill your password and confirm password')
            return
        }

        if (user.password !== user.confirmPassword) {
            toast.error('PIN and confirm PIN not match')
            return
        }

        var userData = {
            phoneNo: user.code + user.phoneNo,
            password: user.password,
            confirmPassword: user.confirmPassword,
            tagCode: user.tagCode
        }

        setLoading(true)
        dataHandeler.forgotPassword(userData).then(result => {
            console.log('###############result: ' + JSON.stringify(result))
            if (result.data.status === 0) {
                toast.success(result.data.message)
                setUser({ code: '+60', phoneNo:'', password:'', confirmPassword:'', tagCode: '' })
            } else {
                toast.error(result.data.message)
            }
            setLoading(false)
        }).catch(error => {
            toast.error('Error: ' + error)
            setLoading(false)
        })
    }

    return (
        <div className="container-fluid login-bg">
            <div className="loginbox col-12 mx-auto ">
                <div className="shadow p-4 bg-white p-5">
                    <h1 className="display-6 mb-5">Forgot PIN code</h1>
                    <form>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group mb-3 mt-4">
                                    <select className="form-control" name='code' onChange={(e) => { handelChange(e) }} >
                                        <option value="+60">+60</option>
                                    </select>
                                    <label className="form-label"> + Code</label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group mb-3 mt-4">
                                    <input type="tel" placeholder="Phone Number" className="form-control" name='phoneNo'
                                        value={user.phoneNo} onChange={(e) => handelChange(e)} />
                                    <label className="form-label">Phone Number</label>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="form-group">
                                    <input type="password" placeholder="Tag code" className="form-control" name='tagCode' maxLength={6}
                                        value={user.tagCode} onChange={(e) => handelChange(e)} />
                                    <label className="form-label">Tag Code</label>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary w-100" onClick={() => { requestTagCode() }}
                                        disabled={tagLoading || loading}>
                                        { loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> :
                                            (tagLoading ?
                                            countingTime
                                            :
                                            'Request'
                                            )
                                        }
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <input type="password" placeholder="4-digit PIN code" className="form-control" name='password'
                                        value={user.password} onChange={(e) => handelChange(e)} />
                                    <label className="form-label">Please enter new 4-digit PIN code</label>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <input type="password" placeholder="Confirm 4-digit PIN code" className="form-control" name='confirmPassword'
                                        value={user.confirmPassword} onChange={(e) => handelChange(e)} />
                                    <label className="form-label">Confirm 4-digit PIN code</label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group pb-0">
                                    <button type="button" className="btn btn-primary w-100 fw-bold py-3" onClick={() => { forgotPasswordEvent() }} disabled={loading}>
                                        { loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : 'Submit' }
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a href="login.html" className="text-dark fw-bold text-decoration-none">Back to Login</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

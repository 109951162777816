import React, { useState, useEffect } from 'react'
import moment from "moment";
import dataHandeler from '../../services/server'
import Slider from 'react-rangeslider'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

export function EditQrCode(props) {
	
	const [value, setValue] = useState(props.location.comm)
    const [defaultPass, setDefaultPass] = useState('')
	
	const editDlCommissionEvent = () => {
		var userData = {dlComm: value, dlDefaultPass: defaultPass}
		
		dataHandeler.editQrCodeInfo(userData).then(result => {
			if (result.data.status === 0) {
				props.history.push('/qr-code')
				toast.success('Change QR code succesfully')
			} else {
				toast.error('Change QR Code failed: ' + result.data.message)
			}
		}).catch(error => console.log('error came after it ', error))
	}
	
	const handleChange = value => {
        setValue(value)
    };

    const handelChangePassword = (e) => {
        setDefaultPass(e.target.value)
    }
	
	return (
		<div className="container">
			<div className="row">
				{/*<div className="col-12 headingSection mobileNav d-flex align-items-center">
					<Link to="/qr-code" className="d-inline-block leftIcon text-white">
						<svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
							<path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
						</svg>
					</Link>
					<h1 className="flex-grow-1">
						<span className="pageheading">Edit QR Code</span>
					</h1>
					<Link className="btn btn-danger respBtn" to="/qr-code">Cancel</Link>
			    </div>*/}
				<div className="col-12 web-nav headingSection d-flex align-items-center">
		            <h1 className="flex-grow-1">
		                <span className="pageheading">Edit QR Code</span>
		            </h1>
		            <Link className="btn btn-danger respBtn" to="/qr-code">Cancel</Link>
		        </div>
			    <div className="col-12 mobileContent">
			    	<div className="card">
			    		<div className="card-body shadow">
			    			<div className="row">
				    			<div className="col-12 text-center">
				    				<img src="//s3-ap-southeast-1.amazonaws.com/assets.squarrific.com/fortune-web/assets/qr-code-blur-1a69c04d6859b54143144984bb097dc6.png" className="img-fluid" style={{width: '250px'}} />
				    				<div className="text-black" style={{position: 'absolute', top: '150px', left: '50%', transform: 'translate(-50%, -50%)'}}>
				    					<h4>Choose rate to generate QR code</h4>
				    				</div>
				    			</div>
			    			</div>
			    			<br/><br/>
			    			<div className="row">
						    	<div className="col-md-12">
									<label htmlFor="companyname" className="text-dark d-block">Commission Rate({value} %)
									</label>
									<Slider
									    min={0}
									    max={props.location.maxComm}
									    value={value}
									    onChange={(e) => handleChange(e)}
									/>
								</div>
								<div className="col-md-6">
	                                <div className="form-group mb-3 mt-4">
	                                    <input type="text" placeholder="Commission Rate" style={{ textAlign: 'center' }} className="form-control" value={value} />
	                                    <label className="form-label">Commission Rate</label>
	                                </div>
	                            </div>
                                <div className="col-md-6">
	                                <div className="form-group mb-3 mt-4">
	                                    <input type="text" placeholder="Downline Default Password" style={{ textAlign: 'center' }} className="form-control"
                                            onChange={(e) => handelChangePassword(e)}/>
	                                    <label className="form-label">Downline Default Password</label>
	                                </div>
	                            </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
	                            	<div className="form-group mb-3 mt-4">
	                            		<a className="btn btn-primary w-100" onClick={() => { editDlCommissionEvent() }}>Submit</a>
	                            	</div>
	                            </div>
                            </div>
			    		</div>
			    	</div>
			    </div>
			</div>
		</div>
	)
}
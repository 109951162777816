import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import UiBetList from './UiBetListNormal';
import { ConvertNumberToCounter, getTmpAmt } from './util/BetUtils';

const { REACT_APP_API_IMAGE_URL } = process.env;
let permArr = [], usedChars = [];
const numTypeOptions = [
    { label: '3D/4D', value: 34, maxLength: 4 },
    { label: '3D', value: 3, maxLength: 3  },
    { label: '2D', value: 2, maxLength: 2  },
    { label: '4DS', value: 40, maxLength: 4  },
    { label: '3DS', value: 30, maxLength: 3  },
    { label: '2DS', value: 20, maxLength: 2  },
    { label: '5D', value: 5, maxLength: 5  },
    { label: '6D', value: 6, maxLength: 6  }
];
const betTypeItems = [
    { label: 'Normal(Format)', value: 'S' },
    { label: 'BOX', value: 'B' },
    { label: 'IBOX', value: 'IB' },
    { label: 'Bao Head', value: 'PH' },
    { label: 'Bao Tail', value: 'PB' },
    { label: 'Reverse', value: 'RV' }
];
const betTypeItems5D6D = [
    { label: 'Normal(Format)', value: 'S' },
    { label: 'Reverse', value: 'RV' }
];
const betTypeItems4DS = [
    { label: 'Normal(Format)', value: 'S' },
    { label: 'BOX', value: 'B' },
    { label: 'IBOX', value: 'IB' },
    { label: 'Bao Head', value: 'PH' },
    { label: 'Bao Tail', value: 'PB' }
];
const betTypeItemsNormal = [
    { label: 'Normal(Format)', value: 'S' }
];

export default function OrderNormal({
	userPoint, orderPoint, setOrderPoint, pointExtDeduction, counterList, loading, counterLoading,
	activeBetCounter, setActiveBetCounter, betList, setBetList, onBetEvent, dayOptions, selectedDay, onSelectDay,
	orderRate, isTransactionClosed, orderMsg
}) {

    const [staticBetList, setStaticBetList]  = useState({ counter: [], number: '', type: "S", money: '', noBet: 0, type4ds: 0, type3ds: 0, type2ds: 0 });
	const [betRow, setBetRow] = useState(0);
    const [selectedBetType, setSelectedBetType] = useState(betTypeItems[0]);
    const [luckyNumber, setLuckyNumber] = useState('');
    const [luckyNumberCount, setLuckyNumberCount] = useState(4);
    const [selectedNumType, setSelectedNumType] = useState({ label: '3D/4D', value: 34, maxLength: 4 });
    const [big, setBig] = useState('');
    const [small, setSmall] = useState('');
    const [foura, setFoura] = useState('');
    const [threea, setThreea] = useState('');
    const [threeabc, setThreeabc] = useState('');
    const [foursb, setFoursb] = useState('');
    const [foursc, setFoursc] = useState('');
    const [fourd, setFourd] = useState('');
    const [foure, setFoure] = useState('');
    const [fourc, setFourc] = useState('');
    const [threesa, setThreesa] = useState('');
    const [threesb, setThreesb] = useState('');
    const [threesc, setThreesc] = useState('');
    const [threed, setThreed] = useState('');
    const [threee, setThreee] = useState('');
    const [twoa, setTwoa] = useState('');
    const [twoabc, setTwoabc] = useState('');
    const [twosa, setTwosa] = useState('');
    const [twosb, setTwosb] = useState('');
    const [twosc, setTwosc] = useState('');
    const [twod, setTwod] = useState('');
    const [twoe, setTwoe] = useState('');
    const [betTypeOptions, setBetTypeOptions] = useState(betTypeItems);
	
	useEffect(() => {
		if (orderMsg && orderMsg !== '') {
			importBetList(orderMsg);
		}
		document.getElementById('orderNumber').focus();
	}, []);
	
	const importBetList = (message) => {
		//console.log('------ ' + message);
		let digitPattern = /^[0-9]+$/;
		let msg_arr = message.split(',');
		let day = parseInt(msg_arr[0]);
		
		for (let i = 0; i < dayOptions.length; i++) {
			if (dayOptions[i].value === day) {
				onSelectDay(dayOptions[i]);
				break;
			}
		}
		
		let list = [];
		let obj = {};
		let type = '';
		let counter = [];
		
		for (let i = 1; i < msg_arr.length; i++) {
			if (digitPattern.test(msg_arr[i])) {
				// check counter
				obj = {};
				counter = [];
				counter = ConvertNumberToCounter(msg_arr[i].split(''), counterList);
				obj.counter = counter;
				type = 'counter';
			} else {
				let order_arr = [];
				if (type === 'number') {
					obj = {};
					obj.counter = counter;
				}
				
				if (msg_arr[i].startsWith('#')) { // bao head
					order_arr = msg_arr[i].substring(1).split('#');
					obj.number = '0' + order_arr[0];
					obj.type = 'PH';
					
				} else if (msg_arr[i].includes('##')) { // bao tail
					let idx = msg_arr[i].indexOf('#');
					obj.number = msg_arr[i].substring(0, idx + 1).replace('#', '0');
					obj.type = 'PB';
					order_arr = msg_arr[i].substring(idx + 1).split('#');
					
				} else {
					order_arr = msg_arr[i].split('#');
					
					if (msg_arr[i].startsWith('**')) { // IB
						obj.number = order_arr[0].substring(2);
						obj.type = 'IB';
					} else if (msg_arr[i].startsWith('*')) { // B
						obj.number = order_arr[0].substring(1);
						obj.type = 'B';
					} else {
						let number = order_arr[0];
						
						if (number.endsWith('*') && !number.endsWith('**')) { // RV
							obj.number = number.replace('*', '');
							obj.type = 'RV';
						} else {
							obj.number = number;
							obj.type = 'S';
						}
					}
				}
				
				let number = obj.number.replaceAll('*', '');
				if (obj.number.endsWith('**')) {
					if (number.length === 4) obj.type4ds = 1;
					else if (number.length === 3) obj.type3ds = 1;
					else if (number.length === 2) obj.type2ds = 1;
				}
				//if (obj.type != 'S') obj.number = number + '(' + obj.type + ')';
				obj.number = number;
				
				for (let i = 1; i < order_arr.length; i++) {
					let amount = getTmpAmt(order_arr[i], orderRate);
					amount = amount.substring(1).split('.').join('*');
					
					if (obj.type4ds === 1) {
						if (i === 1 && order_arr[i] !== '0') obj.foura = amount;
						else if (i === 2 && order_arr[i] !== '0') obj.foursb = amount;
						else if (i === 3 && order_arr[i] !== '0') obj.foursc = amount;
						else if (i === 4 && order_arr[i] !== '0') obj.fourd = amount;
						else if (i === 5 && order_arr[i] !== '0') obj.foure = amount;
						else if (i === 6 && order_arr[i] !== '0') obj.fourc = amount;
						
					} else if (obj.type3ds === 1) {
						if (i === 1 && order_arr[i] !== '0') obj.threesa = amount;
						else if (i === 2 && order_arr[i] !== '0') obj.threesb = amount;
						else if (i === 3 && order_arr[i] !== '0') obj.threesc = amount;
						else if (i === 4 && order_arr[i] !== '0') obj.threed = amount;
						else if (i === 5 && order_arr[i] !== '0') obj.threee = amount;
						
					} else if (obj.type2ds === 1) {
						if (i === 1 && order_arr[i] !== '0') obj.twosa = amount;
						else if (i === 2 && order_arr[i] !== '0') obj.twosb = amount;
						else if (i === 3 && order_arr[i] !== '0') obj.twosc = amount;
						else if (i === 4 && order_arr[i] !== '0') obj.twod = amount;
						else if (i === 5 && order_arr[i] !== '0') obj.twoe = amount;
						
					} else {
						if (number.length === 4) {
							if (i === 1 && order_arr[i] !== '0') obj.big = amount;
							else if (i === 2 && order_arr[i] !== '0') obj.small = amount;
							else if (i === 3 && order_arr[i] !== '0') obj.threea = amount;
							else if (i === 4 && order_arr[i] !== '0') obj.threeabc = amount;
							else if (i === 5 && order_arr[i] !== '0') obj.foura = amount;
							
						} else if (number.length === 3) {
							if (i === 1 && order_arr[i] !== '0') obj.threea = amount;
							else if (i === 2 && order_arr[i] !== '0') obj.threeabc = amount;
							
						} else if (number.length === 2) {
							if (i === 1 && order_arr[i] !== '0') obj.twoa = amount;
							else if (i === 2 && order_arr[i] !== '0') obj.twoabc = amount;
							else if (i === 3 && order_arr[i] !== '0') obj.big = amount;
							
						} else obj.big = amount;
					}
				}
				type = 'number';
				list.push(obj);
			}
		}
		calculateOrder(list);
		setBetList(list);
	}
	
    const permute = (input) => {
        var i, ch;
        for (i = 0; i < input.length; i++) {
	        ch = input.splice(i, 1)[0];
	        usedChars.push(ch);
	        if (input.length == 0 && !permArr.includes(usedChars.join(''))) {
                permArr.push(usedChars.join(''));
            }
	        permute(input);
	        input.splice(i, 0, ch);
	        usedChars.pop();
	    }
	    return permArr.length;
    }
    
	const calculateOrder = (paramBetList) => {
    	let grandTotal = 0;
    	let myFunc = num => Number(num);
    	
        let arry = [...betList];
        if (paramBetList) arry = [...paramBetList];
        
        arry.map((list) => {
            let money = 0;
            let count = list.counter.length;
            
            switch (list.type) {
                case 'RV':
                    for (const i in list) {
                        if (Object.hasOwnProperty.call(list, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc') ||
                                (i === 'foursb') || (i === 'foursc') || (i === 'fourd') || (i === 'foure') || (i === 'fourc')) {
                                money = money + parseFloat(list[i] ? list[i] * 2 : 0);
                            }
                        }
                    }
                    list.money = (money * count * selectedDay.value).toFixed(2);
                    grandTotal += parseFloat(list.money);
                    break;
                case 'B':
                    let intArr = Array.from(String(list.number), myFunc);
                    
                    for (const i in list) {
                        if (Object.hasOwnProperty.call(list, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc') ||
                                (i === 'foursb') || (i === 'foursc') || (i === 'fourd') || (i === 'foure') || (i === 'fourc')) {
                                permArr = [];
                                money = money + parseFloat(list[i] ? list[i] * permute(intArr) : 0);
                            }
                        }
                    }
                    list.money = (money * count * selectedDay.value).toFixed(2);
                    grandTotal += parseFloat(list.money);
                    break;
                case 'PH':
                case 'PB':
                    for (const i in list) {
                        if (Object.hasOwnProperty.call(list, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc') ||
                                (i === 'foursb') || (i === 'foursc') || (i === 'fourd') || (i === 'foure') || (i === 'fourc')) {
                                money = money + parseFloat(list[i] ? list[i] * 10 : 0);
                            }
                        }
                    }
                    list.money = (money * count * selectedDay.value).toFixed(2);
                    grandTotal += parseFloat(list.money);
                    break;
                default:
                    for (const i in list) {
                        if (Object.hasOwnProperty.call(list, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc') ||
                                (i === 'foursb') || (i === 'foursc') || (i === 'fourd') || (i === 'foure') || (i === 'fourc') ||
                                (i === 'threesa') || (i === 'threesb') || (i === 'threesc') || (i === 'threed') || (i === 'threee') ||
                                (i === 'twoa') || (i === 'twoabc') ||
                                (i === 'twosa') || (i === 'twosb') || (i === 'twosc') || (i === 'twod') || (i === 'twoe')) {
                                money = money + parseFloat(list[i] ? list[i] : 0);
                            }
                        }
                    }
                    list.money = (money * count * selectedDay.value).toFixed(2);
                    grandTotal += parseFloat(list.money);
                    break;
            }
        })
        let totalOrderPoint = grandTotal * pointExtDeduction * orderRate;
        setOrderPoint(totalOrderPoint.toFixed(2));
    }

	const clickCounter = (code) => {
        let counterArry = [...activeBetCounter];
        let ind = counterArry.indexOf(code);
        
        if (ind === -1) {
        	counterArry.push(code);
        } else {
        	counterArry.splice(ind, 1);
        }
        
        setActiveBetCounter(counterArry);
    }
	
	const clickBetType = (e) => {
        setSelectedBetType(e);
	}

    const addBetRow = () => {
        let arry = betList;
		
        if (activeBetCounter.length === 0) {
			toast.warning("Invalid counter");
			return;
		}

		if (luckyNumber.length < 2 ||
            (selectedNumType.value === 5 && luckyNumber.length !== 5) ||
            (selectedNumType.value === 6 && luckyNumber.length !== 6) ||
            (selectedNumType.value === 40 && luckyNumber.length !== 4) ||
            (selectedNumType.value === 30 && luckyNumber.length !== 3) ||
            (selectedNumType.value === 20 && luckyNumber.length !== 2) ||
            (selectedNumType.value === 34 && (luckyNumber.length !== 3 && luckyNumber.length !== 4))) {
			toast.warning("Invalid bet number");
			return;
		}

        if (selectedNumType.value === 40) {
            if ((foura === '0'|| foura.length === 0) &&
                (foursb === '0' || foursb.length === 0) &&
                (foursc === '0' || foursc.length === 0) &&
                (fourd === '0'|| fourd.length === 0) &&
                (foure === '0' || foure.length === 0) &&
                (fourc === '0' || fourc.length === 0)) {
                toast.warning("Invalid bet amount");
                return;
            }
        } else if (selectedNumType.value === 30) {
            if ((threesa === '0'|| threesa.length === 0) &&
                (threesb === '0' || threesb.length === 0) &&
                (threesc === '0' || threesc.length === 0) &&
                (threed === '0'|| threed.length === 0) &&
                (threee === '0' || threee.length === 0)) {
                toast.warning("Invalid bet amount");
                return;
            }
        } else if (selectedNumType.value === 20) {
            if ((twosa === '0'|| twosa.length === 0) &&
                (twosb === '0' || twosb.length === 0) &&
                (twosc === '0' || twosc.length === 0) &&
                (twod === '0'|| twod.length === 0) &&
                (twoe === '0' || twoe.length === 0)) {
                toast.warning("Invalid bet amount");
                return;
            }
        } else if (selectedNumType.value === 2) {
            if ((twoa === '0'|| twoa.length === 0) &&
                (twoabc === '0' || twoabc.length === 0) &&
                (big === '0' || big.length === 0)) {
                toast.warning("Invalid bet amount");
                return;
            }
        } else {
            if ((big === '0' || big.length === 0) &&
                (small === '0' || small.length === 0) &&
                (foura === '0'|| foura.length === 0) &&
                (threea === '0' || threea.length === 0) &&
                (threeabc === '0' || threeabc.length === 0)) {
                toast.warning("Invalid bet amount");
                return;
            }
        }

        let type4ds = 0;
        if (selectedNumType.value === 40) type4ds = 1

        let type3ds = 0;
        if (selectedNumType.value === 30) type3ds = 1

        let type2ds = 0;
        if (selectedNumType.value === 20) type2ds = 1

        let obj = staticBetList;
        obj = { ...staticBetList, number: luckyNumber, counter: activeBetCounter, type: selectedBetType.value, type4ds: type4ds, type3ds: type3ds, type2ds: type2ds }
        if (big !== '0' && big.length !== 0) obj = { ...obj, big: big }
        if (small !== '0' && small.length !== 0) obj = { ...obj, small: small }
        if (foura !== '0' && foura.length !== 0) obj = { ...obj, foura: foura }
        if (threea !== '0' && threea.length !== 0) obj = { ...obj, threea: threea }
        if (threeabc !== '0' && threeabc.length !== 0) obj = { ...obj, threeabc: threeabc }
        if (foursb !== '0' && foursb.length !== 0) obj = { ...obj, foursb: foursb }
        if (foursc !== '0' && foursc.length !== 0) obj = { ...obj, foursc: foursc }
        if (fourd !== '0' && fourd.length !== 0) obj = { ...obj, fourd: fourd }
        if (foure !== '0' && foure.length !== 0) obj = { ...obj, foure: foure }
        if (fourc !== '0' && fourc.length !== 0) obj = { ...obj, fourc: fourc }
        if (threesa !== '0' && threesa.length !== 0) obj = { ...obj, threesa: threesa }
        if (threesb !== '0' && threesb.length !== 0) obj = { ...obj, threesb: threesb }
        if (threesc !== '0' && threesc.length !== 0) obj = { ...obj, threesc: threesc }
        if (threed !== '0' && threed.length !== 0) obj = { ...obj, threed: threed }
        if (threee !== '0' && threee.length !== 0) obj = { ...obj, threee: threee }
        if (twosa !== '0' && twosa.length !== 0) obj = { ...obj, twosa: twosa }
        if (twosb !== '0' && twosb.length !== 0) obj = { ...obj, twosb: twosb }
        if (twosc !== '0' && twosc.length !== 0) obj = { ...obj, twosc: twosc }
        if (twod !== '0' && twod.length !== 0) obj = { ...obj, twod: twod }
        if (twoe !== '0' && twoe.length !== 0) obj = { ...obj, twoe: twoe }
        if (twoa !== '0' && twoa.length !== 0) obj = { ...obj, twoa: twoa }
        if (twoabc !== '0' && twoabc.length !== 0) obj = { ...obj, twoabc: twoabc }

        betList.push(obj);
		setBetRow(betList.length - 1);
        clearInput2(selectedNumType.value, luckyNumber.length);
        calculateOrder();
        document.getElementById('orderNumber').focus();
	}
	
	const removeBetRow = (idx) => {
		let list = [...betList];
		
		if (list.length > 0) {
			list.splice(idx, 1);
			setBetList(list);
            calculateOrder(list);
		}
	}

	const onNumberKeyIn = (type, e) => {
		switch (type) {
			case 'number':
				if (e.target.value.length <= 8)
                    setStaticBetList({ ...staticBetList, number: e.target.value })
				break;
			case 'big':
				if (e.target.value.length <= 6)
                    setBig(e.target.value)
				break;
			case 'small':
				if (e.target.value.length <= 6)
                    setSmall(e.target.value)
				break;
			case 'foura':
				if (e.target.value.length <= 6)
                    setFoura(e.target.value)
				break;
			case 'threea':
                if (e.target.value.length <= 6)
                    setThreea(e.target.value)
				break;
			case 'threeabc':
                if (e.target.value.length <= 6)
                    setThreeabc(e.target.value)
				break;
            case 'foursb':
                if (e.target.value.length <= 6)
                    setFoursb(e.target.value)
                break;
            case 'foursc':
                if (e.target.value.length <= 6)
                    setFoursc(e.target.value)
                break;
            case 'fourd':
                if (e.target.value.length <= 6)
                    setFourd(e.target.value)
				break;
            case 'foure':
                if (e.target.value.length <= 6)
                    setFoure(e.target.value)
				break;
            case 'fourc':
                if (e.target.value.length <= 6)
                    setFourc(e.target.value)
                break;
            case 'threesa':
                if (e.target.value.length <= 6)
                    setThreesa(e.target.value)
                break;
            case 'threesb':
                if (e.target.value.length <= 6)
                    setThreesb(e.target.value)
                break;
            case 'threesc':
                if (e.target.value.length <= 6)
                    setThreesc(e.target.value)
                break;
            case 'threed':
                if (e.target.value.length <= 6)
                    setThreed(e.target.value)
                break;
            case 'threee':
                if (e.target.value.length <= 6)
                    setThreee(e.target.value)
                break;
            case 'twoa':
                if (e.target.value.length <= 6)
                    setTwoa(e.target.value)
                break;
            case 'twoabc':
                if (e.target.value.length <= 6)
                    setTwoabc(e.target.value)
                break;
            // case 'twob':
            //     if (e.target.value.length <= 6)
            //         setTwob(e.target.value)
            //     break;
            case 'twosa':
                if (e.target.value.length <= 6)
                    setTwosa(e.target.value)
                break;
            case 'twosb':
                if (e.target.value.length <= 6)
                    setTwosb(e.target.value)
                break;
            case 'twosc':
                if (e.target.value.length <= 6)
                    setTwosc(e.target.value)
                break;
            case 'twod':
                if (e.target.value.length <= 6)
                    setTwod(e.target.value)
                break;
            case 'twoe':
                if (e.target.value.length <= 6)
                    setTwoe(e.target.value)
                break;
		}
	}

    const handleChange = (e) => {
        var regex = /^(\s*|\d+)$/;
        if (regex.test(e.target.value)) {
            setLuckyNumber(e.target.value);
            /*if (selectedNumType.value === 34 && e.target.value.length >= 3) {
                if (e.target.value.length === 3) {
                    setBig('');
                    setSmall('');
                    setFoura('');
                }
            }*/
        }
    };

    const handleNumTypeChange = (e) => {
        setSelectedNumType(e);
        setLuckyNumberCount(e.maxLength);
        clearInput();
        if (e.maxLength == 5) {
            setActiveBetCounter(['T']);
        }

        if (e.maxLength >= 5) {
            setBetTypeOptions(betTypeItems5D6D);
            setSelectedBetType(betTypeItems5D6D[0]);
        } else {// 3D/4D or 4DS
            if (e.value === 40) {
                setBetTypeOptions(betTypeItems4DS);
                setSelectedBetType(betTypeItems4DS[0]);
            } else if (e.value === 2 || e.value === 30 || e.value === 20) { // 2D, 2DS and 3DS
                setBetTypeOptions(betTypeItemsNormal);
                setSelectedBetType(betTypeItemsNormal[0]);
            } else {
                setBetTypeOptions(betTypeItems);
                setSelectedBetType(betTypeItems[0]);
            }
        }
    };

    const clearInput = () => {
        setLuckyNumber('');
        setBig('');
        setSmall('');
        setFoura('');
        setThreea('');
        setThreeabc('');
        setFoursb('');
        setFoursc('');
        setFourd('');
        setFoure('');
        setFourc('');
        setThreesa('');
        setThreesb('');
        setThreesc('');
        setThreed('');
        setThreee('');
        setTwoa('');
        setTwoabc('');
        setTwosa('');
        setTwosb('');
        setTwosc('');
        setTwod('');
        setTwoe('');
    }

    const clearInput2 = (selectedNumType, numLength, focusType) => {
    	//console.log('--- selectedNumType = ' + selectedNumType + ' / ' + numLength + ' / ' + focusType);
    	
    	if (focusType) {
    		if (((numLength === 5 || numLength === 6) && focusType === 'Big') ||
    				(numLength === 4 && focusType === 'Big') || (numLength === 3 && focusType === '3A') ||
    				(numLength === 2 && focusType === '2A') || (selectedNumType === 40 && focusType === '4A')) {
	    		setBig('');
	            setSmall('');
	            setFoura('');
	            setThreea('');
	            setThreeabc('');
	            setFoursb('');
	            setFoursc('');
	            setFourd('');
	            setFoure('');
	            setFourc('');
	            setThreesa('');
	            setThreesb('');
	            setThreesc('');
	            setThreed('');
	            setThreee('');
	            setTwoa('');
	            setTwoabc('');
	            setTwosa('');
	            setTwosb('');
	            setTwosc('');
	            setTwod('');
	            setTwoe('');
    		}
    	} else {
	        setLuckyNumber('');
	        if (selectedNumType === 40) {
	            setBig('');
	            setSmall('');
	            setThreea('');
	            setThreeabc('');
	            setThreesa('');
	            setThreesb('');
	            setThreesc('');
	            setThreed('');
	            setThreee('');
	            setTwoa('');
	            setTwoabc('');
	            setTwosa('');
	            setTwosb('');
	            setTwosc('');
	            setTwod('');
	            setTwoe('');
	        } else if (selectedNumType === 30) {
	            setBig('');
	            setSmall('');
	            setFoura('');
	            setThreea('');
	            setThreeabc('');
	            setFoursb('');
	            setFoursc('');
	            setFourd('');
	            setFoure('');
	            setFourc('');
	            setTwoa('');
	            setTwoabc('');
	            setTwosa('');
	            setTwosb('');
	            setTwosc('');
	            setTwod('');
	            setTwoe('');
	        } else if (selectedNumType === 20) {
	            setBig('');
	            setSmall('');
	            setFoura('');
	            setThreea('');
	            setThreeabc('');
	            setFoursb('');
	            setFoursc('');
	            setFourd('');
	            setFoure('');
	            setFourc('');
	            setThreesa('');
	            setThreesb('');
	            setThreesc('');
	            setThreed('');
	            setThreee('');
	            setTwoa('');
	            setTwoabc('');
	        } else if (numLength === 2) {
	            setSmall('');
	            setFoura('');
	            setThreea('');
	            setThreeabc('');
	            setFoursb('');
	            setFoursc('');
	            setFourd('');
	            setFoure('');
	            setFourc('');
	            setThreesa('');
	            setThreesb('');
	            setThreesc('');
	            setThreed('');
	            setThreee('');
	            setTwosa('');
	            setTwosb('');
	            setTwosc('');
	            setTwod('');
	            setTwoe('');
	        } else if (numLength === 3) {
	        	setBig('');
	            setSmall('');
	            setFoura('');
	            setFoursb('');
	            setFoursc('');
	            setFourd('');
	            setFoure('');
	            setFourc('');
	            setThreesa('');
	            setThreesb('');
	            setThreesc('');
	            setThreed('');
	            setThreee('');
	            setTwoa('');
	            setTwoabc('');
	            setTwosa('');
	            setTwosb('');
	            setTwosc('');
	            setTwod('');
	            setTwoe('');
	        } else if (numLength === 4) {
	            setFoursb('');
	            setFoursc('');
	            setFourd('');
	            setFoure('');
	            setFourc('');
	            setThreesa('');
	            setThreesb('');
	            setThreesc('');
	            setThreed('');
	            setThreee('');
	            setTwoa('');
	            setTwoabc('');
	            setTwosa('');
	            setTwosb('');
	            setTwosc('');
	            setTwod('');
	            setTwoe('');
	        } else {
	            setSmall('');
	            setFoura('');
	            setThreea('');
	            setThreeabc('');
	            setFoursb('');
	            setFoursc('');
	            setFourd('');
	            setFoure('');
	            setFourc('');
	            setThreesa('');
	            setThreesb('');
	            setThreesc('');
	            setThreed('');
	            setThreee('');
	            setTwoa('');
	            setTwoabc('');
	            setTwosa('');
	            setTwosb('');
	            setTwosc('');
	            setTwod('');
	            setTwoe('');
	        }
    	}
    }

	return (
		<div className="mobileContent my-2 withoutKeyboard">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
			<div className="col-12">
				<div className="card shadow">
		            <div className="card-body py-2">
	                    <div className="row" style={{fontSize:'13px'}}>
	                    	<div className="col-6">
	                        	<img src="assets/images/coin.svg" height={25} style={{marginTop:'-6px'}}/>&nbsp;<span style={{fontWeight:'bold',fontSize:'16px'}}>{userPoint}</span> pts
	                    	</div>
	                        <div className="col-6" style={{textAlign:'right', fontSize:'13px'}}>
	                        	<img src="assets/images/coin.svg" height={25} style={{marginTop:'-6px'}}/>&nbsp;Order&nbsp;
	                        		{ orderPoint > userPoint ?
	                        			<span style={{fontWeight:'bold',fontSize:'16px',color:'red'}}>{orderPoint}</span>
	                        			: <span style={{fontWeight:'bold',fontSize:'16px'}}>{orderPoint}</span>
	                        		} pts
	                        </div>
	                    </div>
                        <div className="row">
		                    <div className="col-6 col-md-6 col-lg-3 pt-2 pb-1 px-1">
                                <Select value={selectedDay} onChange={(e) => onSelectDay(e)} options={dayOptions} isSearchable={false} />
		                    </div>
                            <div className="col-6 col-md-6 col-lg-3 pt-2 pb-1 px-1">
                                <Select value={selectedBetType} onChange={(e) => clickBetType(e)} options={betTypeOptions} isSearchable={false} />
		                    </div>
	                    </div>
		            </div>
		        </div>
                <div className="d-flex align-items-center overflow-auto my-md-4 my-2">
	            	{ !counterLoading ?
	                    (counterList && counterList.length > 0 ? counterList.map((element, i) => (
	                        <div
	                            className={(selectedNumType.value === 5 && element.code != 'T')
	                        		? 'logos disable'
	                            	: (selectedNumType.value === 6 && (element.code != 'M' && element.code != 'K' && element.code != 'T'))
	                            	? 'logos disable' 
	                            	: activeBetCounter.filter((item) => item === element.code).length > 0
	                            	? 'logos active btn' : 'logos btn'
	                            }
	                            onClick={() => {selectedNumType.value === 5
	                            	? element.code === 'T' && clickCounter(element.code)
	                            	: selectedNumType.value === 6
	                            	? (element.code === 'M' || element.code === 'K' || element.code === 'T') && clickCounter(element.code)
	                            	: clickCounter(element.code)}}
	                            key={i} id={'idCounter' + element.code} style={{padding:'0'}}>
	                            <img src={REACT_APP_API_IMAGE_URL + element.image} />
	                            <h4>{element.code}</h4>
	                        </div>
	                    )) : <div>Counter not found!</div>)
	            	: <div style={{width:'100%',height:'50px',textAlign:'center'}}><i className="spinner-border" style={{width:'30px', height:'30px'}}></i></div> }
	            </div>

                <div className="card shadow">
		    		<div className="card-body px-md-3 py-md-4 py-1 px-2">
		    			<div className="col-12 col-md-6 mx-auto px-0">
		    				<div className="points_section py-2 mb-3 background-transparent">
			    				<div className="d-flex align-items-center pointer">
                                    <div className="row w-100">
                                        <div className="col-5 px-2">
                                            <Select value={selectedNumType} onChange={(e) => handleNumTypeChange(e)} options={numTypeOptions} isSearchable={false} />
                                        </div>
                                        <div className="col-6 px-1">
                                            <div class="input-group">
                                            <input id="orderNumber" type="tel" pattern="\d*" inputmode="decimal" placeholder="Number" name="Number" style={{ textAlign: 'center', borderRadius: '5px' }}
                                                className="form-control border" value={luckyNumber} maxLength={luckyNumberCount}
                                                onChange={(e) => (handleChange(e))} />
                                            </div>
                                        </div>
                                        <div className="col-1 px-1">
                                            <button type="button"
                                                className="btn btn-primary fw-bold"
                                                onClick={() => addBetRow()}>+</button>
                                        </div>
                                    </div>
		                        </div>
		    				</div>
		    			</div>
		    			<div className="d-flex align-items-center justify-content-center flex-column w-100">
			    			<div className="numbersec row px-1">
		                        {(selectedNumType.value === 5 || selectedNumType.value === 6) &&
                                    <div className="col-md-4 col-4 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">B</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={big} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('big', e)}
                                            	onFocus={() => clearInput2(selectedNumType.value, luckyNumber.length, 'Big')}/>
                                        </div>
                                    </div>
		                        }
		                        {/* {(luckyNumber.length === 4 || luckyNumber.length === 0) && selectedNumType.value === 34 &&
                                    <div className="col-md-4 col-4 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">S</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={small} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('small', e)}/>
                                        </div>
                                    </div>
		                        } */}
                                {(selectedNumType.value === 40) &&
                                    <div className="col-md-4 col-4 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">4A</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={foura} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('foura', e)}
                                            	onFocus={() => clearInput2(selectedNumType.value, luckyNumber.length, '4A')}/>
                                        </div>
                                    </div>
		                        }
                                {(selectedNumType.value === 3 || (selectedNumType.value === 34 && (luckyNumber.length === 3))) &&
                                    <div className="col-md-6 col-6 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">3A</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={threea} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threea', e)}
                                            	onFocus={() => clearInput2(selectedNumType.value, luckyNumber.length, '3A')}/>
                                        </div>
                                    </div>  
		                        }
		                        {(selectedNumType.value === 3 || (selectedNumType.value === 34 && (luckyNumber.length === 3))) &&
                                    <div className="col-md-6 col-6 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">3ABC</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={threeabc} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threeabc', e)}/>
                                        </div>
                                    </div>
		                        }
                                {selectedNumType.value === 40 &&
                                    <div className="col-md-4 col-4 px-0 input-group-sm">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">4SB</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={foursb} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('foursb', e)}/>
                                        </div>
                                    </div>
		                        }
                                {selectedNumType.value === 40 &&
                                    <div className="col-md-4 col-4 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">4SC</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={foursc} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('foursc', e)}/>
                                        </div>
                                    </div>
		                        }
                                {selectedNumType.value === 40 &&
                                    <div className="col-md-4 col-4 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">4D</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={fourd} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('fourd', e)}/>
                                        </div>
                                    </div>
		                        }
                                {selectedNumType.value === 40 &&
                                    <div className="col-md-4 col-4 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">4E</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={foure} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('foure', e)}/>
                                        </div>
                                    </div>
		                        }
                                {selectedNumType.value === 40 &&
                                    <div className="col-md-4 col-4 px-0">
                                        <div class="input-group input-group-sm px-0">
                                            <span class="input-group-text px-1 fw-bold">4C</span>
                                            <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                                value={fourc} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('fourc', e)}/>
                                        </div>
                                    </div>
		                        }
                                {/* 4D Row Layout */}
                                {(selectedNumType.value === 34 && (luckyNumber.length === 4 || luckyNumber.length === 0)) &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">B</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={big} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('big', e)}
                                    	onFocus={() => clearInput2(selectedNumType.value, luckyNumber.length, 'Big')}/>
                                </div>
                                }
                                {(selectedNumType.value === 34 && (luckyNumber.length === 4 || luckyNumber.length === 0)) &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">S</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={small} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('small', e)}/>
                                </div>
                                }
                                {(selectedNumType.value === 34 && (luckyNumber.length === 4 || luckyNumber.length === 0)) &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">3A</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={threea} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threea', e)}/>
                                </div>
                                }
                                {(selectedNumType.value === 34 && (luckyNumber.length === 4 || luckyNumber.length === 0)) &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">3ABC</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={threeabc} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threeabc', e)}/>
                                </div>
                                }
                                {(selectedNumType.value === 34 && (luckyNumber.length === 4 || luckyNumber.length === 0)) &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">4A</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={foura} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('foura', e)}/>
                                </div>
                                }
                                {/* 3DS Row Layout */}
                                {selectedNumType.value === 30 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">3SA</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={threesa} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threesa', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 30 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">3SB</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={threesb} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threesb', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 30 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">3SC</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={threesc} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threesc', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 30 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">3D</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={threed} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threed', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 30 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">3E</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={threee} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('threee', e)}/>
                                </div>
                                }
                                {/* 2D Row Layout */}
                                {selectedNumType.value === 2 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">2A</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={twoa} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('twoa', e)}
                                    	onFocus={() => clearInput2(selectedNumType.value, luckyNumber.length, '2A')}/>
                                </div>
                                }
                                {selectedNumType.value === 2 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">2ABC</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={twoabc} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('twoabc', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 2 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">B</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={big} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('big', e)}/>
                                </div>
                                }
                                {/* 2DS Row Layout */}
                                {selectedNumType.value === 20 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">2SA</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={twosa} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('twosa', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 20 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">2SB</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={twosb} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('twosb', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 20 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">2SC</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={twosc} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('twosc', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 20 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">2D</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={twod} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('twod', e)}/>
                                </div>
                                }
                                {selectedNumType.value === 20 &&
                                <div class="col input-group-sm px-0">
                                    <span class="input-group-text px-0 justify-content-center fw-bold">2E</span>
                                    <input type="tel" pattern="\d*" inputmode="decimal" class="form-control border"
                                        value={twoe} style={{textAlign:'center'}} onChange={(e) => onNumberKeyIn('twoe', e)}/>
                                </div>
                                }
		                    </div>
		    			</div>
		    		</div>
		    	</div>
			</div>
            <div className="col-12 my-2">
	            <UiBetList
	                uiBetList={betList}
	            	removeBetRow={removeBetRow}
	            	index={betRow}
	            />
	        </div>
	            <div className="row justify-content-center mt-3 mb-3">
                    <div className="col-md-4 col-12 my-md-3">
                        <button type="button" className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
                            onClick={() => onBetEvent('eve')} disabled={loading || isTransactionClosed}>
                            { loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : 'Bet'}
                        </button>
                    </div>
                </div>
		</div>
        
	)
}
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import UiBetList from './UiBetList';
import UiCalculator from './UiCalculator';
import { toast } from 'react-toastify';

const { REACT_APP_API_IMAGE_URL } = process.env;
const betType = [{ STD: 'S' }, { REV: 'RV' }, { BOX: 'B' }, { IBOX: 'IB' }];
let permArr = [], usedChars = [];

export default function UiEvent({
	counterList, userPoint, orderPoint, setOrderPoint, pointExtDeduction, loading, counterLoading, activeBetCounter, setActiveBetCounter, 
	uiBetList, setUiBetList, onBetEvent, dayOptions, selectedDay, onSelectDay, orderRate, isTransactionClosed
}) {
	
	const staticBetList = { counter: [], number: 'NO LUCKY NUMBER', type: "S", money: '', noBet: 0 };
	const [betRow, setBetRow] = useState(0);
	const [activeBetType, setActiveBetType] = useState('S');
	const [modalNumber, setModalNumber] = useState('');
	const [luckyNumber, setLuckyNumber] = useState('');
	const [luckyNumberCount, setLuckyNumberCount] = useState(4);
	const [numberAmount, setNumberAmount] = useState({big: 0, small: 0, foura: 0, threea: 0, threeabc: 0});
	const [showModal, setShowModal] = useState({ number: luckyNumber, show: 'show d-block pr-3', titel: 'Key in your lucky number', act: 'num1' })
	
	useEffect(() => {
		activeBetRow(0);
		calculateOrder('ALL');
		
		if (uiBetList[0].number === 'NO LUCKY NUMBER') {
	    	const script = document.createElement('script');
	        script.src = "/assets/js/modal.js";
	        script.async = true;
	        document.body.appendChild(script);
	        return () => {
	            document.body.removeChild(script);
	        }
		}
	}, []);
	
	const clickCounter = (code) => {
        let betArray = uiBetList;
        let betObj = betArray[betRow];

        let counterArry = [...activeBetCounter];
        let ind = counterArry.indexOf(code);
        
        if (ind === -1) {
        	counterArry.push(code);
        } else {
        	counterArry.splice(ind, 1);
        }
        
        betObj.counter = counterArry;
        betArray.splice(betRow, 1, betObj);
        setActiveBetCounter(counterArry);
        setUiBetList(betArray);
        calculateOrder('ONE');
    	
        // disable other counter if not same company
    	/*let counterInfo = counterList.filter(counter => counter.code === code);
    	let element, classname, classarr;
    	classname = 'disable';
    	for (var a = 0; a < counterList.length; a++) {
    		if (counterArry.length > 0) {
	    		if (counterList[a].code !== code && counterList[a].company !== counterInfo[0].company) {
	    			element = document.getElementById('idCounter' + counterList[a].code);
	    			classarr = element.className.split(' ');
	    			if (classarr.indexOf(classname) == -1) element.className += ' ' + classname;
	    		}
    		} else {
    			element = document.getElementById('idCounter' + counterList[a].code);
    			classarr = element.className.split(' ');
    			if (classarr.indexOf(classname) !== -1) element.className = 'logos btn';
    		}
    	}*/
    }
	
	const clickBetType = (type) => {
        let arry = uiBetList;
		let betObj = uiBetList[betRow];
		
		betObj.type = type;
        arry.splice(betRow, 1, betObj);

        setUiBetList(arry);
        setActiveBetType(type);
        calculateOrder('ONE');
	}
	
	const activeBetRow = (idx, betList) => {
		setBetRow(idx);

		let obj = uiBetList[idx];
		if (betList) obj = betList[idx];
		
		if (obj.number.length > 0 && obj.number !== 'NO LUCKY NUMBER') {
			setLuckyNumber(obj.number);
			setLuckyNumberCount(obj.number.length);
		} else {
			setLuckyNumber('');
			setLuckyNumberCount(4);
		}
		
		if (obj.big) numberAmount.big = obj.big;
		else numberAmount.big = 0;
		
		if (obj.small) numberAmount.small = obj.small;
		else numberAmount.small = 0;
		
		if (obj.foura) numberAmount.foura = obj.foura;
		else numberAmount.foura = 0;
		
		if (obj.threea) numberAmount.threea = obj.threea;
		else numberAmount.threea = 0;
		
		if (obj.threeabc) numberAmount.threeabc = obj.threeabc;
		else numberAmount.threeabc = 0;
		
		setActiveBetCounter(obj.counter);
		setActiveBetType(obj.type);
	}
	
	const removeBetRow = (idx) => {
		let arry = [...uiBetList];
        if (uiBetList.length > 1) {
            arry.splice(idx, 1);
            setUiBetList(arry);
            
            let nextId = idx;
            if (arry.length >= (idx + 1)) nextId = idx;
            else nextId = idx - 1;
            activeBetRow(nextId, arry);
            calculateOrder('ALL', arry);
        }
	}

    const calculateOrder = (type, betList) => {
    	let grandTotal = 0;
    	let myFunc = num => Number(num);
    	
    	if (type === 'ALL') {
        	let arry = [...uiBetList];
        	if (betList) arry = [...betList];
        	
        	arry.map((list) => {
        		let money = 0;
        		let count = list.counter.length;
        		
        		switch (list.type) {
        			case 'RV':
        				for (const i in list) {
                            if (Object.hasOwnProperty.call(list, i)) {
                                if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                    money = money + parseFloat(list[i] ? list[i] * 2 : 0);
                                }
                            }
                        }
    					list.money = (money * count * selectedDay.value).toFixed(2);
    					grandTotal += parseFloat(list.money);
        				break;
        			case 'B':
        				let intArr = Array.from(String(list.number), myFunc);
        				
        				for (const i in list) {
                            if (Object.hasOwnProperty.call(list, i)) {
                                if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                    permArr = [];
                                    money = money + parseFloat(list[i] ? list[i] * permute(intArr) : 0);
                                }
                            }
                        }
        				list.money = (money * count * selectedDay.value).toFixed(2);
    					grandTotal += parseFloat(list.money);
        				break;
        			case 'H':
                    case 'T':
                        for (const i in list) {
                            if (Object.hasOwnProperty.call(list, i)) {
                                if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                    money = money + parseFloat(list[i] ? list[i] * 10 : 0);
                                }
                            }
                        }
                        list.money = (money * count * selectedDay.value).toFixed(2);
    					grandTotal += parseFloat(list.money);
                        break;
        			default:
        				for (const i in list) {
                            if (Object.hasOwnProperty.call(list, i)) {
                                if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                    money = money + parseFloat(list[i] ? list[i] : 0);
                                }
                            }
                        }
    	    			list.money = (money * count * selectedDay.value).toFixed(2);
    					grandTotal += parseFloat(list.money);
        				break;
        		}
        	})
            setOrderPoint(grandTotal * pointExtDeduction * orderRate);
        	
        } else {
            let arry = uiBetList;
            let betObj = uiBetList[betRow];
            let money = 0;
            let count = betObj.counter.length;
            
            var intArr = Array.from(String(betObj.number), myFunc);
            switch (betObj.type) {
                case 'RV':
                    for (const i in betObj) {
                        if (Object.hasOwnProperty.call(betObj, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                money = money + parseFloat(betObj[i] ? betObj[i] * 2 : 0);
                            }
                        }
                    }
                    break;
                case 'B':
                    for (const i in betObj) {
                        if (Object.hasOwnProperty.call(betObj, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                permArr = [];
                                money = money + parseFloat(betObj[i] ? betObj[i] * permute(intArr) : 0);
                            }
                        }
                    }
                    break;
                case 'H':
                case 'T':
                    for (const i in betObj) {
                        if (Object.hasOwnProperty.call(betObj, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                money = money + parseFloat(betObj[i] ? betObj[i] * 10 : 0);
                            }
                        }
                    }
                    break;
                default:
                    for (const i in betObj) {
                        if (Object.hasOwnProperty.call(betObj, i)) {
                            if ((i === 'small') || (i === 'big') || (i === 'foura') || (i === 'threea') || (i === 'threeabc')) {
                                money = money + parseFloat(betObj[i] ? betObj[i] : 0);
                            }
                        }
                    }
                    break;
            }
            money = money * count * selectedDay.value;
            betObj.money = money !== 0 && money.toFixed(2);
            arry.splice(betRow, 1, betObj);

            setUiBetList(arry);
            arry.map((list) => {
                if (list.money && list.money !== 0) {
                    grandTotal += parseFloat(list.money);
                }
            })
            setOrderPoint(grandTotal * pointExtDeduction * orderRate);
    	}
    }

    const permute = (input) => {
    	var i, ch;
        for (i = 0; i < input.length; i++) {
            ch = input.splice(i, 1)[0];
            usedChars.push(ch);
            if (input.length == 0 && !permArr.includes(usedChars.join(''))) {
                permArr.push(usedChars.join(''));
            }
            permute(input);
            input.splice(i, 0, ch);
            usedChars.pop();
        }
        return permArr.length;
    }
	
	const showModalEvent = (obj) => {
		switch (obj.act) {
	        case 'num': // add new number
	            setLuckyNumberCount(4);
	            break;
	        case 'num1': // edit existing number
	            setModalNumber((luckyNumber === undefined) || (luckyNumber === 'NO LUCKY NUMBER') ? '' : luckyNumber)
	            break;
	        case 'big':
	        	setModalNumber(numberAmount.big === 0 ? '' : numberAmount.big.toString());
	            break;
	        case 'small':
	        	setModalNumber(numberAmount.small === 0 ? '' : numberAmount.small.toString());
	            break;
	        case 'four':
	        	setModalNumber(numberAmount.foura === 0 ? '' : numberAmount.foura.toString());
	            break;
	        case 'aAct':
	        	setModalNumber(numberAmount.threea === 0 ? '' : numberAmount.threea.toString());
	            break;
	        case 'acAct':
	        	setModalNumber(numberAmount.threeabc === 0 ? '' : numberAmount.threeabc.toString());
	            break;
	    }
	    setShowModal(obj);
	}
	
	const onKeyChange = (e) => {
        if (showModal.act === 'num1' || showModal.act === 'num') {
            if (modalNumber.length < luckyNumberCount) {
                setModalNumber(modalNumber + (Number.isInteger(e) ? JSON.stringify(e) : e));
            }
        } else {
            if (modalNumber.length < 6) {
                setModalNumber(modalNumber + (Number.isInteger(e) ? JSON.stringify(e) : e));
            }
        }
    }
	
	const addNumberEvent = () => {
        if (uiBetList[uiBetList.length - 1].number === 'NO LUCKY NUMBER') {
            toast.warning("please enter last bet");
        } else {
        	//staticBetList.counter = [counterList[0].code];
        	setActiveBetCounter([counterList[0].code]);
        	setActiveBetType('S');
            showModalEvent({ show: 'show d- block pr - 3', titel: 'Key in your lucky number', act: 'num' });
        }
    }
    
    const onSaveEvent = (type) => {
    	let arry = uiBetList;
    	
        if (type === 'All') {
            uiBetList.map((bet, key) => {
                let numLength = bet.number.length;

                switch (true) {
                    case numLength === 4:
                        bet[showModal.act === 'four' ? 'a4' : showModal.act] = modalNumber;
                        arry.splice(key, 1, bet);
                        setModalNumber('');
                        switch (showModal.act) {
                            case 'big':
                                numberAmount.big = modalNumber;
                                break;
                            case 'small':
                                numberAmount.small = modalNumber;
                                break;
                            case 'four':
                                numberAmount.foura = modalNumber;
                                break;
                        }
                        break;
                    case numLength === 3:
                        bet[showModal.act] = modalNumber;
                        arry.splice(key, 1, bet);
                        setModalNumber('');
                        switch (showModal.act) {
                            case 'aAct':
                                numberAmount.threea = modalNumber;
                                break;
                            case 'acAct':
                                numberAmount.threeabc = modalNumber;
                                break;
                        }
                        break;
                    case numLength > 4:
                        bet[showModal.act] = modalNumber;
                        arry.splice(key, 1, bet);
                        setModalNumber('');
                        numberAmount.big = modalNumber;
                        break;
                }
            });
            calculateOrder('ALL');
            
        } else {
        	let obj = arry[betRow];
        	let orderType = 'S';

        	switch (showModal.act) {
                case 'num': // add new number
                	if (modalNumber.length < 5) {
	                	if (modalNumber.startsWith('#')) {
	                		orderType = 'H';
	                		setActiveBetType('H');
	                	} else if (modalNumber.endsWith('#')) {
	                		orderType = 'T';
	                		setActiveBetType('T');
	                	}
                	}
                	
                	arry.push({ ...staticBetList, number: modalNumber, counter: activeBetCounter, type: orderType });
                    setBetRow(arry.length - 1);
                    setUiBetList(arry);
                    setLuckyNumber(modalNumber);
                    setModalNumber('');
                    
                    numberAmount.big = 0;
                    numberAmount.small = 0;
                    numberAmount.foura = 0;
                    numberAmount.threea = 0;
                    numberAmount.threeabc = 0;
                    break;
                	
                case 'num1': // edit existing number
                	if (!type) {
                		if (obj) {
                			orderType = obj.type;
                        	
                        	if (modalNumber.length < 5) {
        	                	if (modalNumber.startsWith('#')) {
        	                		orderType = 'H';
        	                		setActiveBetType('H');
        	                	} else if (modalNumber.endsWith('#')) {
        	                		orderType = 'T';
        	                		setActiveBetType('T');
        	                	} else {
        	                		if (activeBetType === 'H' || activeBetType === 'T') {
        	                			orderType = 'S';
        	                			setActiveBetType('S');
        	                		}
        	                	}
                        	}
                        	
                			obj.number = modalNumber;
                            obj.type = orderType;
                            if (modalNumber.length === 5) obj.counter = ['T'];
                            arry.splice(betRow, 1, betTypeHandle(obj));
                            
                        } else {
                            arry.push({ ...staticBetList, number: modalNumber, counter: activeBetCounter, type: orderType });
                        }
                        setUiBetList(arry);
                        setLuckyNumber(modalNumber);
                        setModalNumber('');
                        setBetRow(arry.length - 1);
                        
                    } else if (type === '+') {
                    	/*if (arry[betRow].number === 'NO LUCKY NUMBER') {
                            arry.splice(betRow, 1, { ...staticBetList, number: modalNumber, counter: activeBetCounter, type: orderType });
                        	
                        } else {
                            arry.push({ ...staticBetList, number: modalNumber, counter: activeBetCounter, type: orderType });
                        }*/
                    	
                    	arry.splice(betRow, 1, { ...staticBetList, number: modalNumber, counter: activeBetCounter, type: orderType });
                        setUiBetList(arry);
                        setBetRow(arry.length - 1);
                        setLuckyNumber(modalNumber);
                        setModalNumber('');
                        showModal.act = 'num';
                    }
                    break;
                    
                case 'big':
                    obj.big = modalNumber;
                    numberAmount.big = modalNumber;
                    arry.splice(betRow, 1, betTypeHandle(obj));
                    setUiBetList(arry);
                    setModalNumber('');
                    break;
                    
                case 'small':
                    obj.small = modalNumber;
                    numberAmount.small = modalNumber;
                    arry.splice(betRow, 1, betTypeHandle(obj));
                    setUiBetList(arry);
                    setModalNumber('');
                    break;
                    
                case 'four':
                    obj.foura = modalNumber;
                    numberAmount.foura = modalNumber;
                    arry.splice(betRow, 1, betTypeHandle(obj));
                    setUiBetList(arry);
                    setModalNumber('');
                    break;
                    
                case 'aAct':
                    obj.threea = modalNumber;
                    numberAmount.threea = modalNumber;
                    arry.splice(betRow, 1, betTypeHandle(obj));
                    setUiBetList(arry);
                    setModalNumber('');
                    break;
                    
                case 'acAct':
                    obj.threeabc = modalNumber;
                    numberAmount.threeabc = modalNumber;
                    arry.splice(betRow, 1, betTypeHandle(obj));
                    setUiBetList(arry);
                    setModalNumber('');
                    break;
            }
            calculateOrder('ONE');
        }
    }
    
    const betTypeHandle = (obj) => {
        let numLength = luckyNumber.length;

        switch (true) {
            case numLength === 0:
                obj.big = obj.big;
                obj.small = obj.small;
                obj.foura = obj.foura;

                numberAmount.threea = 0;
                numberAmount.threeabc = 0;
                
                delete obj['treea'];
                delete obj['threeabc'];
                return obj;

            case numLength > 4:
                obj.big = obj.big;

                numberAmount.small = 0;
                numberAmount.foura = 0;
                numberAmount.threea = 0;
                numberAmount.threeabc = 0;

                delete obj['small'];
                delete obj['foura'];
                delete obj['threea'];
                delete obj['threeabc'];
                return obj;

            case numLength === 4:
                obj.big = obj.big;
                obj.small = obj.small;
                obj.foura = obj.foura;

                numberAmount.threea = 0;
                numberAmount.threeabc = 0;
                
                delete obj['threea'];
                delete obj['threeabc'];
                return obj;

            case numLength === 3:
                obj.threea = obj.threea;
                obj.threeabc = obj.threeabc;

                numberAmount.big = 0;
                numberAmount.small = 0;
                numberAmount.foura = 0;

                delete obj['big'];
                delete obj['small'];
                delete obj['foura'];
                return obj;
        }
    }
	
	return (
        <div className="mobileContent my-2 withoutKeyboard">
	        <div className="col-12 ">
		        <div className="card shadow">
		            <div className="card-body">
		                <div className="d-flex flex-wrap align-items-center mb-md-3">
		                    <span className="fw-bold flex-grow-1">
		                        <img src="assets/images/coin.svg" height={30} />&nbsp;{userPoint} pts</span>
		                    <div className="form-group text-left mb-3 active pb-0" style={{ width: '150px' }}>
		                        <div className>
		                            <Select value={selectedDay}  onChange={(e) => onSelectDay(e)} options={dayOptions} />
		                        </div>
		                    </div>
		                </div>
		                <div className="col-12 col-md-6 mx-auto px-2">
		                    <div className="points_section d-flex justify-content-between text-center align-items-center">
		                        <p className="mb-0"><span style={{fontSize:'15px'}}>Order</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize:'30px'}}>{orderPoint}</span></p>
		                    </div>
		                </div>
		            </div>
		        </div>
		        <div className="d-flex align-items-center overflow-auto my-md-4 my-3">
	            	{ !counterLoading ?
	                    (counterList && counterList.length > 0 ? counterList.map((element, i) => (
	                        <div
	                            className={(luckyNumber.length === 5 && element.code != 'T')
	                        		? 'logos disable'
	                            	: (luckyNumber.length === 6 && (element.code != 'M' && element.code != 'K' && element.code != 'T'))
	                            	? 'logos disable' 
	                            	: activeBetCounter.filter((item) => item === element.code).length > 0
	                            	? 'logos active btn' : 'logos btn'
	                            }
	                            onClick={() => {luckyNumber.length === 5
	                            	? element.code === 'T' && clickCounter(element.code)
	                            	: luckyNumber.length == 6
	                            	? (element.code === 'M' || element.code === 'K' || element.code === 'T') && clickCounter(element.code)
	                            	: clickCounter(element.code)}}
	                            key={i} id={'idCounter' + element.code}>
	                            <img src={REACT_APP_API_IMAGE_URL + element.image} />
	                            <h4>{element.displayName}</h4>
	                        </div>
	                    )) : <div>Counter not found!</div>)
	            	: <div style={{width:'100%',height:'50px',textAlign:'center'}}><i className="spinner-border" style={{width:'30px', height:'30px'}}></i></div> }
	            </div>
		    </div>
		    <div className="col-12">
		    	<div className="card shadow">
		    		<div className="card-body px-md-3 py-md-4 py-2 px-2">
		    			<div className="col-12 col-md-6 mx-auto px-2">
		    				<div className="points_section text-center py-2 mb-3 background-transparent">
			    				<div className="d-flex align-items-center pointer">
		                            <div className="fw-bold">{luckyNumberCount}D</div>
		                            <div className="h1 mb-0 flex-grow-1 pointer"
		                                data-bs-toggle="modal"
		                                data-bs-target="#addnumber"
		                                onClick={() => showModalEvent({
		                                    number: luckyNumber,
		                                    show: 'show d-block pr-3',
		                                    titel: 'Key in your lucky number', act: 'num1'
		                                })}
		                                onClickCapture={() => setLuckyNumberCount(luckyNumber.length > 0 ? (luckyNumber === 'NO LUCKY NUMBER' ? 4 : luckyNumber.length) : 4)}
		                            >{luckyNumber.length > 0 && luckyNumber != 'NO LUCKY NUMBER' ? luckyNumber : '----'}
		                            </div>
		                        </div>
		    				</div>
		    			</div>
		    			<div className="d-flex align-items-center justify-content-center flex-column w-100">
			    			<div className="numbersec row px-1">
		                        {luckyNumber.length === 3 &&
		                            <div className="h4 col px-1">
		                                <button
		                                    className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
		                                    data-bs-toggle="modal"
		                                    data-bs-target="#addnumber"
		                                    onClick={() => showModalEvent({ show: 'show d- block pr-3', titel: 'A', act: 'aAct', num: numberAmount.threea })}>
		                                    A<br />{numberAmount.threea}
		                                </button>
		                            </div>
		                        }
		                        {luckyNumber.length === 3 &&
		                            <div className="h4 col px-1">
		                                <button
		                                    className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
		                                    data-bs-toggle="modal"
		                                    data-bs-target="#addnumber"
		                                    onClick={() => showModalEvent({ show: 'show d- block pr-3', titel: 'ABC', act: 'acAct', num: numberAmount.threeabc })}>
		                                    ABC<br />{numberAmount.threeabc}
		                                </button>
		                            </div>
		                        }
		                        {(luckyNumber.length >= 4 || luckyNumber.length === 0) &&
		                            <div className="h4 col px-1">
		                                <button
		                                    className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
		                                    data-bs-toggle="modal"
		                                    data-bs-target="#addnumber"
		                                    onClick={() => showModalEvent({ show: 'show d- block pr-3', titel: 'BIG', act: 'big', num: numberAmount.big })}>
		                                    BIG<br />{numberAmount.big}
		                                </button>
		                            </div>
		                        }
		                        {(luckyNumber.length === 4 || luckyNumber.length === 0) &&
		                            <div className="h4 col px-1">
		                                <button
		                                    className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
		                                    data-bs-toggle="modal"
		                                    data-bs-target="#addnumber"
		                                    onClick={() => showModalEvent({ show: 'show d- block pr-3', titel: 'SMALL', act: 'small', num: numberAmount.small })}>
		                                    SMALL<br />{numberAmount.small}
		                                </button>
		                            </div>
		                        }
		                        {(luckyNumber.length === 4 || luckyNumber.length === 0) &&
		                            <div className="h4 col px-1">
		                                <button
		                                    className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
		                                    data-bs-toggle="modal"
		                                    data-bs-target="#addnumber"
		                                    onClick={() => showModalEvent({ show: 'show d- block pr-3', titel: '4A', act: 'four' })}>
		                                    4A<br />{numberAmount.foura}
		                                </button>
		                            </div>
		                        }
		                    </div>
		                    <div className="tabs redtabs">
	                            {betType.map((item, key) => (
	                                <React.Fragment key={key}>
	                                    {luckyNumber.length > 4 ?
	                                        ((Object.keys(item)[0] === 'REV') || (Object.keys(item)[0] === 'STD')) &&
	                                        <a className={activeBetType === Object.values(item)[0] ? 'active btn' : 'btn'}
	                                            onClick={() => activeBetType != Object.values(item)[0] && clickBetType(Object.values(item)[0])}>
	                                            <span />{Object.keys(item)}
	                                        </a>
	                                        :
	                                        <a className={activeBetType === Object.values(item)[0] ? 'active btn' : (activeBetType === 'H' || activeBetType === 'T' ? 'btn disabled' : 'btn')}
	                                            onClick={() => activeBetType != Object.values(item)[0] && clickBetType(Object.values(item)[0])}>
	                                            <span />{Object.keys(item)}
	                                        </a>
	                                    }
	                                </React.Fragment>
	                            ))}
	                        </div>
		    			</div>
		    		</div>
		    	</div>
		    </div>
		    <div className="col-12 my-3">
	            <UiBetList
	                uiBetList={uiBetList}
	            	activeBetRow={activeBetRow}
	            	removeBetRow={removeBetRow}
	            	index={betRow}
	            />
	        </div>
	            <div className="row justify-content-center mb-3">
                <div className="col-md-4 col-6 my-md-3">
                    <button type="button"
                        className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
                        data-bs-toggle="modal"
                        data-bs-target="#addnumber"
                        onClick={() => addNumberEvent()}>+ Add Number</button>
                </div>
                <div className="col-md-4 col-6 my-md-3">
                    <button type="button" className="btn btn-primary w-100 py-md-3 py-2 px-2 fw-bold"
                        onClick={() => onBetEvent('eve')} disabled={loading || isTransactionClosed}>
                        { loading ? <i className="spinner-border" style={{width:'20px', height:'20px'}}></i> : 'Bet'}
                    </button>
                </div>
            </div>
            
		    {<UiCalculator
		    	modalNumber={modalNumber}
		    	setModalNumber={setModalNumber}
		    	luckyNumberCount={luckyNumberCount}
	    		setLuckyNumberCount={setLuckyNumberCount}
		    	showModal={showModal}
		    	setActiveBetCounter={setActiveBetCounter}
		    	onKeyChange={onKeyChange}
		    	onSaveEvent={onSaveEvent}
	            />}
        </div>
    )
}

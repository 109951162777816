import React, { useState, useEffect } from 'react'
import moment from "moment"
import dataHandeler from '../services/server'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { WhatsappShareButton, WhatsappIcon } from 'react-share'
import { useTranslation } from 'react-i18next'

export function SalesOrder(props) {
	let { id } = useParams()
	const [ticketStatement, setTicketStatement] = useState([])
	const [data, setData] = useState({fromDate: moment(new Date()).format('YYYY-MM-DD'), toDate: moment(new Date()).format('YYYY-MM-DD'), id:id})
	
	useEffect(() => {
        fetch()
    }, [])
	
	const onDateSelectEvent = (e) => {
    	setData({ ...data, [e.target.name]: e.target.value })
    }
	
	const fetch = (value) => {
		setTicketStatement([])
		
		if (value && value === 'btn') data.id = undefined
    	
        dataHandeler.getMemberTicket(data).then(result => {
        	setTicketStatement(result.data)
        }).catch(error => console.log('error came after it ', error))
    }
	
	const voidTicket = (value) => {
		let confirmVoidTicket = window.confirm('Are you confirm to void the ticket?')
		let voided = false;
		
		if (confirmVoidTicket) {
			dataHandeler.postVoidTicket(value).then(result => {
				if (result.data.status === 0) {
					voided = true;
					toast.success('Ticket void successfully')
				} else toast.error(result.data.message)
			})
			if (voided) fetch()
		}
	}
	
	const getMessage = (msg) => {
		let returnMsg = ''
		msg.split('\n').map(item => (
			returnMsg += item.replace('#', '@').split(' ').join('%20') + '%0a'
		))
		return returnMsg
	}

    const copyToClipboard = async (msg) => {
        await navigator.clipboard.writeText(msg)
        toast.warning('Copy to clipboard')
    }

    const { t } = useTranslation()
	
	return (
	    <div className="container">
	      <div className="row">
	        <div className="col-12 web-nav headingSection mobileNav d-flex align-items-center">
	          <h1 className="flex-grow-1">
	            <span className="pageheading">{t('ticket.title')}</span>
	          </h1>
	        </div>
	        <div className="col-12 my-2 mobileContent">
	          <div className="card shadow mb-3">
	            <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
	              <form className="mt-md-4 mt-0 col-md-8 col-12 mx-auto">
	                <div className="row px-md-5 px-2">
	                  <div className="col-md col-5 px-md-3 px-0">
	                    <div className="form-group text-align-end mb-0 form-ani">
		                    <input type="date"
		                        className="form-control text-uppercase"
		                        name="fromDate"
		                        defaultValue={moment(new Date()).format('YYYY-MM-DD')}
		                        onChange={(e) => onDateSelectEvent(e)} />
	                    </div>
	                  </div>
	                  <div className=" col-md-1 col-md col-2 text-dark text-center fw-bold px-md-3 px-0">{t('label.to')}
	                    </div>
	                  <div className="col-md col-5 px-md-3 px-0">
	                    <div className="form-group text-align-end mb-0 form-ani">
		                    <input type="date"
		                        className="form-control text-uppercase"
		                        name="toDate"
		                        defaultValue={moment(new Date()).format('YYYY-MM-DD')}
		                    	onChange={(e) => onDateSelectEvent(e)} />
	                    </div>
	                  </div>
	                  <div className="col-md-3 col-12 px-md-3 px-2">
	                    <div className="form-group text-align-end mb-0 pb-0">
	                    	<a className="btn btn-primary w-100 px-4" onClick={() => { fetch('btn') }}>{t('button.search')}</a>
	                    </div>
	                  </div>
	                </div>
	              </form>
	            </div>
	          </div>
	          { ticketStatement.ticketList ?
	          <div>
	          	{ ticketStatement.ticketList.length > 0 ? ticketStatement.ticketList.map((element, key) => (
		          <div className="card shadow mt-3" key={key}>
		            <div className="card-body">
		              <div className="d-flex flex-wrap">
		                <div className="px-3 text-start">
		                  <div className="img-receipt">
		                    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="#ffffff" className="bi bi-receipt" viewBox="0 0 16 16">
		                      <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z">
		                      </path>
		                      <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z">
		                      </path>
		                    </svg>
		                  </div>
		                </div>
		                <div className="col flex-grow-1 d-flex flex-wrap">
		                  <div className="col-md-6">
		                    <p className="text-danger mb-2 fw-bold">{element.statusCode === 'A' ? element.createdDate : element.updatedDate}</p>
		                    <p className="text-success mb-2 fw-bold">Ref: {element.idRef}</p>
		                    <p className="fw-bold mb-2">{element.returnMsg.split('\n').map(item => (<span>{item}<br/></span>))}Total: {element.invoicePoint} pts</p>
		                  </div>
		                  <div className="col-md-6 col-12 text-md-end text-start">
		                  	{ element.statusCode === 'A' ? <span className="badge bg-success fw-bold">Confirmed</span>
		                  		: <span className="badge bg-danger fw-bold">Voided</span>
		                  	}
		                    <div className="mt-3">
		                      <WhatsappShareButton url={element.returnMsg}>
		                      	<WhatsappIcon size={30} round={true}/>
		                      </WhatsappShareButton>
		                      {/*<a href="#" className="pr-2 text-decoration-none">
		                        <img src="assets/images/whatsapp.svg" height={25} />
		                      </a>*/}
		                      <a href={'sms:?&body=' + getMessage(element.returnMsg)} className="px-2 text-decoration-none">
		                        <img src="assets/images/mail.svg" height={25} />
		                      </a>
                              <a className="px-2 text-decoration-none" onClick={() => { copyToClipboard(element.returnMsg) }}>
		                        <img src="assets/images/copy.svg" height={25} />
		                      </a>
		                      <Link to={{pathname:'/game/' + element.gameCode + '-' + element.idPackage, orderMsg:element.orderMsg}} className="pl-2 text-decoration-none">
		                        <img src="assets/images/refresh.svg" height={25} />
		                      </Link>
		                      { element.statusCode === 'A' ?
		                      <a className="px-2 text-decoration-none" onClick={() => { voidTicket(element.id) }}>
		                        <img src="assets/images/close.svg" height={20} />
		                      </a> : ''}
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>
	          	)) : <div className="card shadow mt-3"><div className="card-body">{t('msg.no.record.found.on.date')}</div></div>}
		      </div> : <div className="loader"><div className="triple-spinner"></div></div> }
	        </div>
	      </div>
	    </div>
    )
}

import React from 'react'

export function NotFound(props) {

    return (
        <>
            <div className="container-fluid login-bg">
                <div className="col-12 mx-auto">
                    <div className="shadow p-4 bg-white p-5 text-center">
                        <h3 className="display-6 mb-5"> Ohpss.. your session expired Please <b className="pointer" onClick={() => props.history.push('/login')} > sign in </b> to continue </h3>
                        <button className="btn btn-primary w-50 fw-bold py-3" onClick={() => props.history.push('/login')}>Click here to sign in</button>
                    </div>

                </div>
            </div>
        </>
    )
}


import React, { useState, useEffect } from 'react'
import dataHandeler from '../../services/server'
import moment from "moment";

const { REACT_APP_API_IMAGE_URL } = process.env
const alfha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']
export function Result(props) {
    const [activeCounter, setActiveCounter] = useState('M');
    const [counterResult, setCounterResult] = useState([]);
    const [selectedDate, setSelectDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getResultData();
    }, [selectedDate]);

    const getResultData = () => {
    	setLoading(true);
        let data = { date: selectedDate };
        dataHandeler.resultPage(data).then((result) => {
        	setCounterResult(result.data.counterResultList);
        	setLoading(false);
        	if (result.data.counterResultList.length > 0) {
        		setActiveCounter(result.data.counterResultList[0].code);
        	}
        }).catch(error => {
        	console.log(error);
        	setLoading(false);
        });
    }

    const changeClass = (i) => {
        setActiveCounter(i);
    }

    const onSelectDate = (e) => {
        setSelectDate(e.target.value);
    }

    const showAlfa = (key) => {
        return alfha[key]
    }
    
    const onPrevDate = () => {
    	setSelectDate(moment(selectedDate).subtract(1, "days").format('YYYY-MM-DD'))
    }
    
    const onNextDate = () => {
    	setSelectDate(moment(selectedDate).add(1, "days").format('YYYY-MM-DD'))
    }

    const tableSpecialRow = (rowData) => {
        let speArry = []
        let num = 0
        for (const key in rowData) {
            if (key.search('special') >= 0) {
                if (JSON.stringify(rowData).search(/[**]/) >= 0) {
                    switch (speArry.length) {
                        case 10:
                            speArry.push(<td key={key + 1}></td>)
                            speArry.push(<td key={key}><sup className="text-danger">{showAlfa(num)}</sup>{rowData[key] ? rowData[key] : '---'}</td>)
                            break;
                        case 13:
                            speArry.push(<td key={key}><sup className="text-danger">{showAlfa(num)}</sup>{rowData[key] ? rowData[key] : '---'}</td>)
                            speArry.push(<td key={key + 1}></td>)
                            break;

                        default:
                            speArry.push(<td key={key}>
                                {<sup className="text-danger">{showAlfa(num)}</sup>}
                                {rowData[key] ? rowData[key] : '---'}</td>)
                            break;
                    }
                } else {
                    if (rowData[key]) {
                        speArry.push(<td key={key}>{rowData[key] ? rowData[key] : '---'}</td>)
                    }
                }
                num = num + 1
            }
        }
        return speArry
    }

    const tableConsolationRow = (rowData) => {
        let speArry = []
        for (const key in rowData) {
            if (key.search('consol') >= 0) {
                if (rowData[key]) {
                    speArry.push(<td key={key}>{rowData[key] ? rowData[key] : '---'}</td>)
                }
            }
        }
        return speArry
    }

    return (
        <div className="container">
	        <div className="row">
		        <div className="col-12 web-nav headingSection mobileNav d-flex align-items-center">
		            <h1 className="flex-grow-1">
		                <span className="pageheading">Result {loading}</span>
		            </h1>
		        </div>
		        { !loading ?
                <div className="col-12 mobileContent">
                    <div className="card shadow mt-3">
                        <div className="card-body mx-auto col-md-6">
                            <div className="input-group  mx-auto ">
                                <button className="btn btn-primary px-md-4" type="button" id="button-addon1" onClick={(e) => onPrevDate()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>&nbsp;
                                Prev</button>
                                <input
                                    type="date"
                                    className="form-control text-center border bg-white"
                                    placeholder
                                    aria-label="Example text with button addon"
                                    aria-describedby="button-addon1"
                                    value={selectedDate}
                                    onChange={(e) => onSelectDate(e)}
                                />
                                <button className="btn btn-primary px-md-4" type="button" id="button-addon1" onClick={(e) => onNextDate()}>
                                	Next &nbsp;<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg></button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center overflow-auto my-md-4 my-3">
                        {counterResult.map((element, i) => (
                            <div className={element.code === activeCounter ? 'logos active' : 'logos'} onClick={() => changeClass(element.code)} key={i} id={i}>
                                <img src={REACT_APP_API_IMAGE_URL + element.image} />
                                <h4>{element.code}</h4>
                            </div>
                        ))}
                    </div>
                    {counterResult.map((element, i) => (
                    	<div>
                    		{element.code === activeCounter && element.result4D &&
                    			<table className="table custom_table text-center whitespace w-100">
		                            <tbody>
		                                <tr>
		                                    <td className="text-start fw-bold w-50 border" colspan="2">
		                                        {moment(element.result4D.drawDate).format("YYYY-MM-DD")}
		                                    </td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border" width="50%">1ST Prize</td>
		                                    <td className="text-center h3 fw-bold border bg-white">
		                                        {element.result4D.firstAlp && <sup className="text-danger">{element.result4D.firstAlp}</sup>}
		                                        &nbsp;{element.result4D.first}
		                                    </td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border">2ND Prize</td>
		                                    <td className="text-center h3 fw-bold border bg-white">
		                                        {element.result4D.secondAlp && <sup className="text-danger">{element.result4D.secondAlp}</sup>}
		                                        &nbsp;{element.result4D.second}
		                                    </td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border">3RD Prize</td>
		                                    <td className="text-center h3 fw-bold border bg-white">
		                                        {element.result4D.thirdAlp && <sup className="text-danger">{element.result4D.thirdAlp}</sup>}
		                                        &nbsp;{element.result4D.third}
		                                    </td>
		                                </tr>
		                                <tr>
		    	                            <td className="text-center" colspan="2" style={{padding:0, border:0}}>
		    		                            <table className="table custom_table text-center w-100">
		    		                            	<tbody>
		    		                            		<tr><td className="text-center h5 fw-bold w-50 border" colspan="5">Special</td></tr>
		    		                            		<tr>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special1}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special2}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special3}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special4}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special5}</td>
		    		                            		</tr>
		    		                            		<tr>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special6}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special7}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special8}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special9}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.special10}</td>
		    		                            		</tr>
		    		                            		<tr><td className="text-center h5 fw-bold w-50 border" colspan="5">Consolation</td></tr>
		    		                            		<tr>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol1}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol2}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol3}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol4}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol5}</td>
		    		                            		</tr>
		    		                            		<tr>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol6}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol7}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol8}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol9}</td>
		    		                            			<td className="text-center h5 fw-bold border bg-white">{element.result4D.consol10}</td>
		    		                            		</tr>
		    		                            	</tbody>
		    		                            </table>
		    	                            </td>
		    	                        </tr>
		    	                        <tr><td>&nbsp;</td></tr>
		                            </tbody>
		                        </table>
                    		}
                    		{element.code === activeCounter && element.result5D &&
                    			<table className="table custom_table text-center whitespace w-100">
		                            <tbody>
		                                <tr>
		                                    <td className="text-center h4 fw-bold border" colspan="4">5D</td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border" width="25%">1ST</td>
		                                    <td className="text-center h5 fw-bold border bg-white" width="25%">
		                                        {element.result5D.first}
		                                    </td>
		                                    <td className="text-center h5 fw-bold border" width="25%">4TH</td>
		                                    <td className="text-center h5 fw-bold border bg-white" width="25%">
		                                        _{element.result5D.first.substring(1)}
		                                    </td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border">2ND</td>
		                                    <td className="text-center h5 fw-bold border bg-white">
		                                        {element.result5D.second}
		                                    </td>
		                                    <td className="text-center h5 fw-bold border">5TH</td>
		                                    <td className="text-center h5 fw-bold border bg-white">
		                                        __{element.result5D.first.substring(2)}
		                                    </td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border">3RD</td>
		                                    <td className="text-center h5 fw-bold border bg-white">
		                                        {element.result5D.third}
		                                    </td>
		                                    <td className="text-center h5 fw-bold border">6TH</td>
		                                    <td className="text-center h5 fw-bold border bg-white">
		                                        ___{element.result5D.first.substring(3)}
		                                    </td>
		                                </tr>
		    	                        <tr><td>&nbsp;</td></tr>
		                            </tbody>
		                        </table>
                    		}
                    		{element.code === activeCounter && element.result6D &&
                    			<table className="table custom_table text-center whitespace w-100">
		                            <tbody>
		                                <tr>
		                                    <td className="text-center h4 fw-bold border" colspan="3">6D</td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border w-20">1ST</td>
		                                    <td className="text-center h5 fw-bold border bg-white w-80" colspan="2">
		                                        {element.result6D.first}
		                                    </td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border">2ND</td>
		                                    <td className="text-center h5 fw-bold border bg-white w-40">
		                                        {element.result6D.first.substring(0, 5)}_
		                                    </td>
		                                    <td className="text-center h5 fw-bold border bg-white w-40">
		                                        _{element.result5D.first.substring(1)}
		                                    </td>
		                                </tr>
		                                <tr>
		                                    <td className="text-center h5 fw-bold border">3RD</td>
		                                    <td className="text-center h5 fw-bold border bg-white">
		                                        {element.result6D.first.substring(0, 4)}__
		                                    </td>
		                                    <td className="text-center h5 fw-bold border bg-white">
		                                        __{element.result6D.first.substring(2)}
		                                    </td>
		                                </tr>
		                                <tr>
		    	                            <td className="text-center h5 fw-bold border">4TH</td>
		    	                            <td className="text-center h5 fw-bold border bg-white">
		    	                                {element.result6D.first.substring(0, 3)}___
		    	                            </td>
		    	                            <td className="text-center h5 fw-bold border bg-white">
		    	                                ___{element.result6D.first.substring(3)}
		    	                            </td>
		    	                        </tr>
		    	                        <tr>
		    		                        <td className="text-center h5 fw-bold border">5TH</td>
		    		                        <td className="text-center h5 fw-bold border bg-white">
		    		                            {element.result6D.first.substring(0, 2)}____
		    		                        </td>
		    		                        <td className="text-center h5 fw-bold border bg-white">
		    		                            ____{element.result6D.first.substring(4)}
		    		                        </td>
		    		                    </tr>
		    	                        <tr><td>&nbsp;</td></tr>
		                            </tbody>
		                        </table>
                    		}
                    	</div>
                    ))}
                </div> : <div class="loader"><div class="triple-spinner"></div></div> }
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import moment from "moment"
import dataHandeler from '../../services/server'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function CashStatements(props) {
    const [pointsStatement, setPointsStatement] = useState([]);
    const [date, setDate] = useState({typeCode:'CASH', fromDate: moment(new Date()).format('YYYY-MM-DD'), toDate: moment(new Date()).format('YYYY-MM-DD')});
    const [loading, setLoading] = useState(false);
    const [listing, setListing] = useState(true);
    const [isBankClosed, setIsBankClosed] = useState(true);
    
    useEffect(() => {
        fetch()
    }, [date.toDate])

    const fetch = () => {
    	setLoading(true)
    	setPointsStatement([])
    	
        dataHandeler.getPointsStatement(date).then(result => {
            setPointsStatement(result.data)
            setIsBankClosed(result.data.isBankClosed)
            setLoading(false)
        }).catch(error => console.log('error came after it ', error))
    }

    const onDateSelectEvent = (e) => {
    	setDate({ ...date, [e.target.name]: e.target.value })
    }

    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="row ">
                <div className="col-12 web-nav headingSection d-flex align-items-center">
	                <h1 className="flex-grow-1">
		                <span className="pageheading">{t('cash.statement.title')}</span>
		            </h1>
                    <a className="d-inline-block righticon openkeyboard btn" onClick={() => setListing(!listing)}>
                        { listing ?
                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" class="bi bi-view-stacked" viewBox="0 0 16 16">
                                <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z"/>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                            </svg>
                        }
                    </a>
		            <Link className="btn btn-primary respBtn" to="/cash-transfer">{t('button.transfer')}</Link> &nbsp;&nbsp;
                    <Link className="btn btn-danger respBtn" to="/">{t('button.back')}</Link>
                </div>
                <div className="col-md-12 mx-auto my-2 mobileContent ">
                    <div className="card shadow">
                        <div className="card-body text-white d-flex align-items-center flex-column justify-content-center">
                            <form className="mt-md-4 mt-0 col-md-8 col-12 mx-auto">
                                <div className="row px-md-5 px-2">
                                    <div className="col-md col-5 px-md-3 px-0">
                                        <div className="form-group text-align-end mb-0 form-ani">
                                            <input type="date"
                                                className="form-control text-uppercase"
                                                name="fromDate"
                                                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                                                onChange={(e) => onDateSelectEvent(e)} />
                                        </div>
                                    </div>
                                    <div className=" col-md-1 col-md col-2 text-dark text-center fw-bold px-md-3 px-0">{t('label.to')}
                                </div>
                                    <div className="col-md col-5 px-md-3 px-0">
                                        <div className="form-group text-align-end mb-0 form-ani">
                                            <input type="date"
                                                className="form-control text-uppercase"
                                                name="toDate"
                                                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                                            	onChange={(e) => onDateSelectEvent(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 px-md-3 px-2">
                                        <div className="form-group text-align-end mb-0 pb-0">
                                            <a className="btn btn-primary w-100 px-4" onClick={() => { fetch() }}>{t('button.search')}</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            { !loading ?
                <div>
                    <div className="row px-2">
                        <div className={ !isBankClosed ? 'col-7 my-2 text-align-start px-2' : 'col-12 my-2 text-align-start px-2' }>
                            <div className="card shadow bg-primary h-100">
                                <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
                                    <small>{t('cash.statement.curr.bal')}</small>
                                    <h1 className>{pointsStatement.cash} <span style={{fontSize:'15px'}}>{t('label.currency')}</span></h1>
                                </div>
                            </div>
                        </div>
                        { !isBankClosed ?
                        <div className="col-5 my-2 text-center px-2">
                            <div className="card shadow mb-4 h-100 bg-danger">
                                <div className="card-body p-md-3 p-1 text-white d-flex align-items-center flex-column justify-content-center">
                                    <Link to="/cash-reload" style={{textDecoration:'none', color:'#ffffff'}}>
                                        <h5 className="text-center sm-h6 fw-bold">Top Up 24/7</h5>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        : '' }
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 mb-3">
                            <div className="card shadow">
                                <div className="card-body">
                                    <div className="table-responsive">
                                    { listing ?
                                        <table className="table custom_table">
                                            <tbody>
                                            	{
                                                    pointsStatement.transList && pointsStatement.transList.length > 0 ? pointsStatement.transList.map(element => (
                                                        <tr className="fw-bold">
                                                            <td>
                                                                <p className="mb-0 text-muted small">{element.formatDate}</p>
                                                                { element.typeCode == 'CASH_RELOAD' ?
                                                                    <p className="mb-0 text-warning">Cash Reload - {element.description}</p>
                                                                : element.actionCode === 'PAYOUT' ?
                                                                	<p className="mb-0"><Link className="text-danger" to={'/cash-payout-details?date=' + moment(element.drawDate).format('YYYY-MM-DD')}>{element.description}</Link></p>
                                                                : <p className="mb-0">{element.description}</p>
                                                                	
                                                                }
                                                            </td>
                                                            {/* {element.cash > 0 ? <td className="text-success fw-bold h4 align-middle" style={{textAlign:'right'}}> {element.cash} </td> : <td className="text-danger fw-bold h4 align-middle" style={{textAlign:'right'}}> {element.cash} </td>} */}
                                                            <td style={{textAlign:'right'}}>
                                                                <p className={ element.cash > 0 ? 'mb-0 text-success small' : 'mb-0 text-danger small'}>{element.cash}</p>
                                                                {/* <p className="mb-0 text-primary">{element.amountBalance}</p>  */}
                                                                <p className="mb-0 text-primary">
                                                                    { element.typeCode == 'CASH_RELOAD' ?
                                                                        <span className="badge bg-warning fw-bold">Pending</span>
                                                                        :
                                                                        element.amountBalance
                                                                    }
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )) : <tr className="fw-bold"><td>{t('msg.no.record.found.on.date')}</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    :
                                        <table className="table custom_table text-center whitespace w-100">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                    <th>Amount</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    pointsStatement.transList && pointsStatement.transList.length > 0 ? pointsStatement.transList.map(element => (
                                                        <tr>
                                                            <td>{element.formatDate}</td>
                                                            { element.typeCode == 'CASH_RELOAD' ?
                                                            <td className="mb-0 text-warning" style={{textAlign: 'left'}}>Cash Reload - {element.description}</td>
                                                            :
                                                            <td style={{textAlign: 'left'}}>{element.description}</td>
                                                            }
                                                            <td className={ element.cash > 0 ? 'text-success' : 'text-danger'} style={{textAlign:'right'}}> {element.cash} </td>
                                                            <td className="text-primary" style={{textAlign:'right'}}>
                                                                { element.typeCode == 'CASH_RELOAD' ?
                                                                    <span className="badge bg-warning fw-bold">Pending</span>
                                                                    :
                                                                    element.amountBalance
                                                                }
                                                            </td>
                                                        </tr>
                                                    )): <tr className="fw-bold"><td colSpan={4}>{t('msg.no.record.found.on.date')}</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <div className="loader">
                    <div className="triple-spinner"></div>
                </div>
            }
        </div>
    )
}

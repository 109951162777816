import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Storage } from '../Storage/Storage';

let lang = 'en'
if (Storage.get('localization')) {
    lang = Storage.get('localization')
}

i18n.use(initReactI18next).init({
  fallbackLng: lang,
  lng: lang,
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    ms: {
        translations: require('./locales/ms/translations.json')
    },
    bn: {
        translations: require('./locales/bn/translations.json')
    },
    id: {
        translations: require('./locales/id/translations.json')
    },
    th: {
        translations: require('./locales/th/translations.json')
    },
    vi: {
        translations: require('./locales/vi/translations.json')
    },
    cn: {
      translations: require('./locales/cn/translations.json')
    },
    tw: {
      translations: require('./locales/tw/translations.json')
    },
    tm: {
      translations: require('./locales/tm/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'ms', 'bn', 'id', 'th', 'vi', 'tw', 'cn', 'tm'];

export default i18n;
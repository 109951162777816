import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/app';
import reportWebVitals from './reportWebVitals';
import './i18n/config';
const root = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
 root
);
reportWebVitals();
